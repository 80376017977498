import React, { FC } from "react";
import styled, { css } from "styled-components";
import Logo from "../../atoms/Logo/Logo";
import Button from "../../atoms/Button/Button";
import { Icon } from "@iconify/react";
import { questionMarkIcon } from "../../../assets/icons";
import { Link } from "gatsby";
import { Availability, HouseId } from "../../../types/types";
import { formatPrice } from "../../../utils/formatPrice";
import { getColor } from "../../../utils/getColor";
import { useWindowSize } from "react-use";

const StyledWrapper = styled.div`
  max-width: 1552px;
  width: 90%;
  margin: 10px auto 0;
  padding: 64px 0;
  display: flex;
  align-items: center;
  position: relative;

  @media (max-width: 1024px) {
    padding: 10px 0 0;
  }
`;

const StyledBackButton = styled(Button)`
  width: 160px;
  display: flex;

  @media (max-width: 574px) {
    font-size: 1.4rem;
    width: 120px;

    span {
      width: 30px;
    }

    div:last-of-type {
      left: 35%;
    }
  }
`;

const InformationWrapper = styled.div`
  margin-left: 90px;
  display: flex;

  @media (max-width: 1024px) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 0;
    margin-top: 40px;
  }
`;

const StyledInformation = styled.div<{ $availability?: Availability }>`
  display: flex;
  flex-direction: column;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1.5;
  margin-right: 60px;

  span:last-of-type {
    font-weight: 600;
    color: #7bb933;
  }

  :last-of-type {
    margin-bottom: 0;
    margin-right: 0;
  }

  @media (max-width: 1024px) {
    margin-right: 0;
    margin-bottom: 20px;
    flex-direction: row;

    span:first-of-type {
      margin-right: 5px;
    }
  }

  ${({ $availability }) =>
    $availability &&
    css`
      span:last-of-type {
        color: ${getColor($availability)};
      }
    `}
`;

const StyledLogo = styled(Logo)`
  font-size: 12rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-40%, -50%);

  @media (max-width: 1630px) {
    display: none;
  }

  @media (max-width: 1024px) {
    display: block;
    position: static;
    transform: none;
    margin-left: auto;
  }

  @media (max-width: 574px) {
    font-size: 9rem;
  }
`;

const StyledAskButton = styled.a`
  text-transform: uppercase;
  font-weight: 300;
  background: transparent;
  border: 0;
  align-items: center;
  display: flex;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 999px;
  transition: box-shadow 0.6s cubic-bezier(0.33, 1, 0.68, 1);
  margin-left: auto;
  color: #000;
  text-decoration: none;

  svg {
    color: #7bb933;
    font-size: 3.9rem;
    margin-right: 15px;
  }

  :hover {
    box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.25);
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

const Information: FC<{
  number: HouseId;
  price: number;
  availability: Availability;
}> = ({ number, price, availability }) => (
  <InformationWrapper>
    <StyledInformation>
      <span>Lokal:</span>
      <span>{number}</span>
    </StyledInformation>
    <StyledInformation $availability={availability}>
      <span>Status:</span>
      <span>{availability}</span>
    </StyledInformation>
    <StyledInformation>
      <span>Cena:</span>
      <span>{formatPrice(price)}</span>
    </StyledInformation>
  </InformationWrapper>
);

const SecondaryNavigation: FC<{
  number: HouseId;
  price: number;
  availability: Availability;
}> = ({ price, number, availability }) => {
  const { width } = useWindowSize();
  return (
    <>
      <StyledWrapper>
        <nav>
          <StyledBackButton forwardedAs={Link} to="/#interactive_vis">
            Powrót
          </StyledBackButton>
        </nav>
        {width >= 1025 ? (
          <Information
            price={price}
            number={number}
            availability={availability}
          />
        ) : (
          <></>
        )}
        <StyledLogo />
        <StyledAskButton data-scroll="" href="#contact">
          <Icon icon={questionMarkIcon} />
          Zapytaj o mieszkanie
        </StyledAskButton>
      </StyledWrapper>
      {width <= 1024 ? (
        <Information
          price={price}
          number={number}
          availability={availability}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default SecondaryNavigation;
