import React, { FC, useState } from "react";
import BasicTemplate from "./BasicTemplate";
import SecondaryNavigation from "../components/molecules/SecondaryNavigation/SecondaryNavigation";
import Table from "../components/atoms/Table/Table";
import styled from "styled-components";
import InteractiveVisualizationSection from "../components/organisms/InteractiveVisualizationSection/InteractiveVisualizationSection";
import Contact from "../components/molecules/Contact/Contact";
import Switch from "../components/atoms/Switch/Switch";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { graphql } from "gatsby";
import { ChildImageSharp } from "../types/childImageSharp";
import { Icon } from "@iconify/react";
import { arrowLeftIcon, arrowRightIcon } from "../assets/icons";
import { downloadIcon } from "../assets/icons/download.icon";
import { House } from "../types/types";
import { formatNumber } from "../utils/formatNumber";
import { transformFloor } from "../utils/transformFloor";
import { sumFloor } from "../utils/sumFloor";
import Floor from "../components/atoms/Floor/Floor";
import { getSvgId } from "../utils/getSvgId";
import Floor2D from "../components/atoms/Floor2D/Floor2D";

SwiperCore.use([Navigation]);

const StyledWrapper = styled.div`
  margin-top: 70px;
`;

const StyledInnerWrapper = styled.div`
  max-width: 1530px;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  position: relative;

  @media (max-width: 1088px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledTablesWrapper = styled.div`
  @media (max-width: 1088px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const StyledTable = styled(Table)`
  margin-bottom: 50px;
  box-shadow: 20px 20px 30px rgba(0, 0, 0, 0.05);

  :first-of-type {
    box-shadow: none;
  }

  :last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 1088px) {
    :first-of-type {
      margin-bottom: 0;
    }
  }
`;

const StyledGalleryWrapper = styled.div`
  padding-top: 150px;
  position: relative;

  @media (max-width: 1088px) {
    padding-top: 0;
    margin: 70px 0;
    width: 100%;
  }
`;

const StyledSwiperWrapper = styled.div`
  width: calc(35vw + 200px);
  max-width: 880px;
  position: relative;
  padding: 0 100px;

  .swiper {
    overflow: hidden;
  }

  .swiper-wrapper {
    display: inline-flex;
    align-items: center;
  }

  @media (max-width: 1225px) {
    width: calc(29vw + 200px);
    max-width: 750px;
  }

  @media (max-width: 1088px) {
    width: 100%;
    max-width: 600px;
    margin: auto;
    padding: 0;
    display: flex;
    justify-content: center;
  }
`;

const StyledSwitch = styled(Switch)`
  :last-of-type {
    margin-top: 50px;
  }

  :first-of-type {
    margin-bottom: 50px;
  }
`;

const NavigationButton = styled.button`
  font-size: 4rem;
  background: transparent;
  padding: 10px;
  border: 0;
  cursor: pointer;
  color: #7bb933;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  :first-of-type {
    left: 0;
  }

  :last-of-type {
    right: 0;
  }

  :active {
    transform: translateY(-50%) scale(0.8);
  }

  @media (max-width: 1088px) {
    font-size: 2rem;
    background: #fff;
    z-index: 999;
    box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.25);
    width: 45px;
    height: 45px;
    border-radius: 10px;
    padding: 0;
    align-items: center;
    justify-content: center;
    display: flex;
  }
`;

const DownloadButton = styled.a`
  background: #fff;
  border: 1px solid #7bb933;
  outline: none;
  position: absolute;
  right: 0;
  top: 0;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  font-weight: 400;
  color: #000;
  text-decoration: none;
  z-index: 3;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;

  svg {
    color: #7bb933;
    margin-right: 7px;
    transition: color 0.2s ease-in-out;
  }

  :hover {
    background: #7bb933;
    color: #fff;

    svg {
      color: #fff;
    }
  }

  @media (max-width: 1088px) {
    position: static;
    margin-top: 60px;
  }
`;

interface Data {
  firstFloorVis: { nodes: ChildImageSharp[] };
  firstFloorVis2d: { nodes: ChildImageSharp[] };
  secondFloorVisFirstType: { nodes: ChildImageSharp[] };
  secondFloorVisSecondType: { nodes: ChildImageSharp[] };
  secondFloorVis2d: { nodes: ChildImageSharp[] };
  datoCmsHouse: House;
}

const Gallery: FC<{
  data: Data;
  nextButtonId: string;
  prevButtonId: string;
  currentFloor: 0 | 1;
  currentValue: 0 | 1;
  setCurrentFloor: React.Dispatch<React.SetStateAction<0 | 1>>;
  setCurrentValue: React.Dispatch<React.SetStateAction<0 | 1>>;
  setCurrentRoom: React.Dispatch<React.SetStateAction<number>>;
  currentRoom: number;
  isRowActive: boolean;
  setRowActive: React.Dispatch<React.SetStateAction<boolean>>;
  isSecondType: boolean;
}> = ({
  data,
  currentFloor,
  nextButtonId,
  prevButtonId,
  setCurrentFloor,
  currentValue,
  setCurrentValue,
  setCurrentRoom,
  currentRoom,
  isRowActive,
  setRowActive,
  isSecondType,
}) => {
  return (
    <StyledGalleryWrapper>
      <StyledSwitch
        firstText="Parter"
        secondText="Piętro"
        currentValue={currentFloor}
        setCurrentValue={setCurrentFloor}
      />
      <StyledSwiperWrapper>
        {currentValue === 1 && (
          <>
            <NavigationButton id={prevButtonId}>
              <Icon icon={arrowLeftIcon} />
            </NavigationButton>
            <NavigationButton id={nextButtonId}>
              <Icon icon={arrowRightIcon} />
            </NavigationButton>
          </>
        )}

        {currentValue === 1 ? (
          <Swiper
            updateOnWindowResize
            slidesPerView={1}
            spaceBetween={0}
            navigation={{
              nextEl: `#${nextButtonId}`,
              prevEl: `#${prevButtonId}`,
            }}
            loop
          >
            {(currentFloor === 0
              ? data.firstFloorVis.nodes
              : isSecondType
              ? data.secondFloorVisSecondType.nodes
              : data.secondFloorVisFirstType.nodes
            ).map((node, index) => (
              <SwiperSlide key={index}>
                <Floor
                  image={node.childImageSharp}
                  svgId={getSvgId(currentFloor, index, isSecondType)}
                  setCurrentRoom={setCurrentRoom}
                  currentRoom={currentRoom}
                  isRowActive={isRowActive}
                  setRowActive={setRowActive}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <Floor2D
            level={currentFloor}
            houseType={!isSecondType ? 1 : 0}
            setCurrentRoom={setCurrentRoom}
            currentRoom={currentRoom}
            isRowActive={isRowActive}
            setRowActive={setRowActive}
          />
        )}
      </StyledSwiperWrapper>
      <StyledSwitch
        firstText="Rzut 2D"
        secondText="Wizualizacja"
        currentValue={currentValue}
        setCurrentValue={setCurrentValue}
      />
    </StyledGalleryWrapper>
  );
};

const HouseTemplate: FC<{
  data: Data;
}> = ({ data }) => {
  const [width, setWidth] = useState<number>(null);
  const [height, setHeight] = useState<number>(null);
  const [currentValue, setCurrentValue] = useState<0 | 1>(1);
  const [currentFloor, setCurrentFloor] = useState<0 | 1>(0);
  const [currentRoom, setCurrentRoom] = useState<number>(0);
  const [isRowActive, setRowActive] = useState<boolean>(false);
  const nextButtonId = "next-button";
  const prevButtonId = "prev-button";

  const {
    number,
    availability,
    deliveryDate,
    firstFloor,
    secondFloor,
    garage,
    parcelArea,
    area,
    roomsCount,
    price,
  } = data.datoCmsHouse;

  return (
    <BasicTemplate
      setWidth={setWidth}
      setHeight={setHeight}
      header={() => (
        <SecondaryNavigation
          number={number}
          availability={availability}
          price={price}
        />
      )}
      title={`Lokal ${number}`}
      isAnotherThanIndex
    >
      <StyledWrapper>
        <StyledInnerWrapper>
          <StyledTablesWrapper>
            <StyledTable
              title="Parametry domu"
              values={[
                {
                  name: "Powierzchnia mieszkalna",
                  value: `${formatNumber(area)} m<sup>2</sup>`,
                },
                {
                  name: "Powierzchnia działki",
                  value: `${formatNumber(parcelArea)} m<sup>2</sup>`,
                },
                { name: "Ilość pokoi", value: roomsCount },
                { name: "Miejsca postojowe", value: garage },
                { name: "Termin oddania", value: deliveryDate },
              ]}
            />

            {width <= 1088 && (
              <Gallery
                data={data}
                nextButtonId={nextButtonId}
                prevButtonId={prevButtonId}
                currentFloor={currentFloor}
                currentValue={currentValue}
                setCurrentFloor={setCurrentFloor}
                setCurrentValue={setCurrentValue}
                setCurrentRoom={setCurrentRoom}
                currentRoom={currentRoom}
                isRowActive={isRowActive}
                setRowActive={setRowActive}
                isSecondType={secondFloor.length === 6}
              />
            )}

            <StyledTable
              title="Parter"
              values={transformFloor(firstFloor)}
              summary={{
                name: "Całkowita powierzchnia parteru",
                value: `${formatNumber(sumFloor(firstFloor))} m<sup>2</sup>`,
              }}
              withCount
              {...(currentFloor === 0
                ? { setCurrentRoom, currentRoom, isRowActive, setRowActive }
                : {})}
            />

            <StyledTable
              title="Piętro"
              values={transformFloor(secondFloor)}
              summary={{
                name: "Całkowita powierzchnia piętra",
                value: `${formatNumber(sumFloor(secondFloor))} m<sup>2</sup>`,
              }}
              withCount
              {...(currentFloor === 1
                ? { setCurrentRoom, currentRoom, isRowActive, setRowActive }
                : {})}
            />
          </StyledTablesWrapper>
          {width > 1088 && (
            <Gallery
              data={data}
              nextButtonId={nextButtonId}
              prevButtonId={prevButtonId}
              currentFloor={currentFloor}
              currentValue={currentValue}
              setCurrentFloor={setCurrentFloor}
              setCurrentValue={setCurrentValue}
              setCurrentRoom={setCurrentRoom}
              currentRoom={currentRoom}
              isRowActive={isRowActive}
              setRowActive={setRowActive}
              isSecondType={secondFloor.length === 6}
            />
          )}

          <DownloadButton href={`/${number}.pdf`} target="_blank">
            <Icon icon={downloadIcon} /> Pobierz kartę PDF
          </DownloadButton>
        </StyledInnerWrapper>
        <InteractiveVisualizationSection />
        <Contact />
      </StyledWrapper>
    </BasicTemplate>
  );
};

export const query = graphql`
  query ($houseNumber: String!) {
    datoCmsHouse(number: { eq: $houseNumber }) {
      number
      area
      parcelArea
      availability
      deliveryDate(formatString: "DD MMMM YYYY", locale: "PL")
      parcelArea
      garage
      price
      roomsCount
      firstFloor {
        name
        area
      }
      secondFloor {
        name
        area
      }
    }

    firstFloorVis: allFile(
      filter: { name: { regex: "/first_floor_3d/" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 80)
        }
      }
    }

    secondFloorVisFirstType: allFile(
      filter: { name: { regex: "/second_floor_3d_1|second_floor_3d_2/" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 80)
        }
      }
    }

    secondFloorVisSecondType: allFile(
      filter: { name: { regex: "/second_floor_3d_3|second_floor_3d_4/" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 80)
        }
      }
    }

    firstFloorVis2d: allFile(
      filter: { name: { regex: "/first_floor_2d/" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 80)
        }
      }
    }

    secondFloorVis2d: allFile(
      filter: { name: { regex: "/second_floor_2d/" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 80)
        }
      }
    }
  }
`;

export default HouseTemplate;
