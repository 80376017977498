import React, { FC } from "react";
import styled from "styled-components";
import { pathStyles } from "../styles/pathStyles";

const StyledSvg = styled.svg`
  flex: 1;
`;

const StyledPath = styled.path`
  ${pathStyles}
`;

const SecondFloor: FC<{
  currentRoom: number;
  isRowActive: boolean;
  handlePathEnter: (num: number) => void;
  handlePathLeave: () => void;
}> = ({ isRowActive, currentRoom, handlePathLeave, handlePathEnter }) => (
  <StyledSvg
    data-name="Warstwa 1"
    version="1.1"
    viewBox="0 0 1367.4 1921.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style
        dangerouslySetInnerHTML={{
          __html: `.cls-1,.cls-10,.cls-11,.cls-12,.cls-13,.cls-14,.cls-15,.cls-16,.cls-17,.cls-18,.cls-19,.cls-2,.cls-20,.cls-21,.cls-22,.cls-23,.cls-3,.cls-4,.cls-5,.cls-6,.cls-7,.cls-8,.cls-9{fill:none;stroke-linecap:round;stroke-linejoin:round;}.cls-1,.cls-10,.cls-11,.cls-12,.cls-13,.cls-14,.cls-16,.cls-17,.cls-18,.cls-19,.cls-2,.cls-20,.cls-21,.cls-22,.cls-24,.cls-3,.cls-4,.cls-5,.cls-6,.cls-7,.cls-8,.cls-9{stroke:#000;}.cls-1{stroke-width:6.87px;}.cls-2{stroke-width:2.36px;}.cls-15,.cls-3{stroke-width:0.79px;}.cls-4{stroke-width:7.08px;}.cls-5{stroke-width:6.87px;}.cls-6{stroke-width:6.97px;}.cls-7{stroke-width:0.77px;}.cls-8{stroke-width:3.15px;}.cls-9{stroke-width:0.8px;}.cls-10{stroke-width:2.4px;}.cls-11{stroke-width:6.81px;}.cls-12{stroke-width:0.8px;}.cls-13{stroke-width:2.4px;}.cls-14{stroke-width:6.87px;}.cls-15,.cls-23{stroke:#f29999;}.cls-16{stroke-width:7.02px;}.cls-17{stroke-width:7.04px;}.cls-18{stroke-width:7.08px;}.cls-19{stroke-width:0.76px;}.cls-20{stroke-width:6.8px;}.cls-21{stroke-width:0.76px;}.cls-22{stroke-width:0.79px;}.cls-23{stroke-width:0.12px;}.cls-24{fill:#fff;stroke-miterlimit:10;stroke-width:2.41px;}`,
        }}
      />
    </defs>
    <StyledPath
      d="m716.29 50.01-80.27-0.01v-31.43h-185.7l1.18 290.38-314.37 1.51v461.11l580.25 0.02-3.05-718.39"
      className={isRowActive && currentRoom === 5 ? "is-active" : ""}
      onMouseEnter={() => handlePathEnter(5)}
      onMouseLeave={handlePathLeave}
    />
    <StyledPath
      d="m738.32 50h80.26l0.01-31.43h185.71v287.81l313.18 3.33v461.86l-579.16 0.02 0.79004-718.39"
      className={isRowActive && currentRoom === 6 ? "is-active" : ""}
      onMouseEnter={() => handlePathEnter(6)}
      onMouseLeave={handlePathLeave}
    />
    <StyledPath
      d="m450.35 793.63-0.0147 159.55 21.985-1.3661 1.66 166.83h-23.66-345.45l0.78-176.27 31.48-148.74 313.19-1.76"
      className={isRowActive && currentRoom === 4 ? "is-active" : ""}
      onMouseEnter={() => handlePathEnter(4)}
      onMouseLeave={handlePathLeave}
    />
    <StyledPath
      d="m905.94 771.59-357.26-0.02-76.33 20.3-1e-5 159.94 1.63 166.83h432.76z"
      className={isRowActive && currentRoom === 1 ? "is-active" : ""}
      onMouseEnter={() => handlePathEnter(1)}
      onMouseLeave={handlePathLeave}
    />
    <StyledPath
      d="m639.97 1118.6 11.77 47.21-1.71 754.65h-193.42l1e-5 -155.42-319.48-13.77v-585.46l344.66-47.21z"
      className={isRowActive && currentRoom === 3 ? "is-active" : ""}
      onMouseEnter={() => handlePathEnter(3)}
      onMouseLeave={handlePathLeave}
    />
    <StyledPath
      d="m686.39 1118.6-11.81 47.21 3.47 755.12 191.69-0.47v-158.05l456.4-8.78-8.66-587.82-410.74-47.21h-220.35"
      className={isRowActive && currentRoom === 2 ? "is-active" : ""}
      onMouseEnter={() => handlePathEnter(2)}
      onMouseLeave={handlePathLeave}
    />
    <polygon
      className="cls-1"
      points="1363.9 310.46 1363.9 1118.6 1363.9 310.46 1363.9 1118.6"
    />
    <polygon
      className="cls-1"
      points="1363.9 418.91 1363.9 1227.1 1363.9 418.91 1363.9 1227.1"
    />
    <path
      className="cls-2"
      transform="translate(-148.26 -251.52)"
      d="m1456.3 1054.6v306.87m-391.88-148.73v-167.62"
    />
    <line className="cls-3" x1="312.6" x2="450.32" y1="793.63" y2="914.83" />
    <polyline
      className="cls-3"
      points="450.32 793.63 312.6 914.83 137.13 793.63"
    />
    <polyline
      className="cls-3"
      points="137.13 914.83 312.6 793.63 312.6 914.83"
    />
    <path
      className="cls-2"
      transform="translate(-148.26 -251.52)"
      d="m1154.9 1045.2v158.18m50.36-158.18v158.18m-99.94 0v-158.18m-50.34 167.62v-167.62m200.66 79.49v-79.49m52.72 158.18 95.22-158.18m-118 158.18 40.91-158.18"
    />
    <path
      className="cls-4"
      transform="translate(-148.26 -251.52)"
      d="m620.61 1203.3v-158.18m-22 158.18v-158.18"
    />
    <path
      className="cls-4"
      transform="translate(-148.26 -251.52)"
      d="M285.39,562v631.91M239,562v631.91"
    />
    <polygon
      className="cls-5"
      points="1317.5 310.46 1317.5 1118.6 1317.5 310.46 1317.5 1118.6"
    />
    <polyline
      className="cls-4"
      points="1363.9 1388.6 1363.9 1317.7 1363.9 1388.6 1363.9 1317.7"
    />
    <polyline
      className="cls-6"
      points="1317.5 1751.4 1363.9 1751.4 1318.3 1751.4 1363.9 1751.4 1318.3 1751.4"
    />
    <polygon
      className="cls-6"
      points="1363.1 1751.4 1317.5 1751.4 1363.1 1751.4 1317.5 1751.4"
    />
    <polyline
      className="cls-6"
      points="1363.9 1751.4 1363.9 1139.2 1363.9 1706.4 1363.9 1139.2 1363.9 1706.4"
    />
    <rect
      className="cls-4"
      x="639.96"
      y="1118.6"
      width="46.43"
      height="47.21"
    />
    <polygon
      className="cls-6"
      points="1363.1 1751.4 1317.5 1751.4 1363.1 1751.4 1317.5 1751.4"
    />
    <polygon
      className="cls-6"
      points="1318.3 1751.4 1363.9 1751.4 1318.3 1751.4 1363.9 1751.4"
    />
    <polyline
      className="cls-7"
      points="1361.6 1753.7 1360.8 1752.1 1359.2 1751.4 1357.6 1752.1 1357.6 1753.7"
    />
    <polyline
      className="cls-7"
      points="1357.6 1753.7 1356.8 1752.1 1355.3 1751.4 1353.7 1752.1 1352.9 1753.7"
    />
    <polyline
      className="cls-7"
      points="1352.9 1753.7 1352.1 1752.1 1350.5 1751.4 1349 1752.1 1348.2 1753.7"
    />
    <polyline
      className="cls-7"
      points="1348.2 1753.7 1347.4 1752.1 1345.8 1751.4 1344.2 1752.1 1344.2 1753.7"
    />
    <polyline
      className="cls-7"
      points="1344.2 1753.7 1343.4 1752.1 1341.9 1751.4 1340.3 1752.1 1339.5 1753.7"
    />
    <polyline
      className="cls-7"
      points="1339.5 1753.7 1338.7 1752.1 1337.2 1751.4 1335.6 1752.1 1334.8 1753.7"
    />
    <polyline
      className="cls-7"
      points="1334.8 1753.7 1334 1752.1 1332.4 1751.4 1330.9 1752.1 1330.9 1753.7"
    />
    <polyline
      className="cls-7"
      points="1330.9 1753.7 1330.1 1752.1 1328.5 1751.4 1326.9 1752.1 1326.1 1753.7"
    />
    <polyline
      className="cls-7"
      points="1326.1 1753.7 1325.4 1752.1 1323.8 1751.4 1322.2 1752.1 1321.4 1753.7"
    />
    <polyline
      className="cls-7"
      points="1321.4 1753.7 1320.6 1752.1 1319.1 1751.4 1317.5 1752.1 1317.5 1753.7"
    />
    <path
      className="cls-3"
      transform="translate(-148.26 -251.52)"
      d="m1461 2003.6 1.58-0.79m-3.94 1.57 2.36 1.6"
    />
    <line className="cls-3" x1="1312.8" x2="1312.8" y1="1752.1" y2="1751.3" />
    <line className="cls-8" x1="869.74" x2="869.74" y1="1844.2" y2="1815.8" />
    <line className="cls-4" x1="869.74" x2="869.74" y1="1890.6" y2="1844.2" />
    <line className="cls-4" x1="869.74" x2="869.74" y1="1917.5" y2="1871" />
    <line className="cls-4" x1="869.74" x2="869.74" y1="1855.3" y2="1808.9" />
    <line className="cls-4" x1="869.74" x2="869.74" y1="1808.9" y2="1762.4" />
    <line className="cls-4" x1="869.74" x2="869.74" y1="1797.7" y2="1751.3" />
    <polyline
      className="cls-9"
      points="869.74 1890.6 678.05 1890.6 869.74 1890.6 678.05 1890.6"
    />
    <path
      className="cls-10"
      transform="translate(-148.26 -251.52)"
      d="M826.31,2162.56H1018m-191.69-9.43H1018"
    />
    <line className="cls-9" x1="678.05" x2="869.74" y1="1920.5" y2="1920.5" />
    <line className="cls-11" x1="456.61" x2="90.7" y1="1751.3" y2="1751.3" />
    <polyline
      className="cls-2"
      points="906.72 970.71 1178.2 970.71 1178.2 942.37"
    />
    <path
      className="cls-2"
      transform="translate(-148.26 -251.52)"
      d="M1064.42,1212.77H1317m-8.66,0,95.22,157.39M1317,1212.77,1465.74,1293m-180.19-80.26,40.91,157.39M1055,1361.5H1456.3"
    />
    <line className="cls-8" x1="456.61" x2="456.61" y1="1844.2" y2="1815.8" />
    <line className="cls-3" x1="137.13" x2="450.32" y1="914.83" y2="914.83" />
    <rect className="cls-2" x="472.35" y="1118.6" width="9.44" height="18.89" />
    <rect className="cls-2" x="630.52" y="1118.6" width="9.45" height="18.89" />
    <path
      className="cls-4"
      transform="translate(-148.26 -251.52)"
      d="m285.39 2002.8v-585.46m732.61 585.46h447.74"
    />
    <rect className="cls-2" x="686.39" y="1147" width="9.44" height="18.89" />
    <path
      className="cls-2"
      transform="translate(-148.26 -251.52)"
      d="m1317 1208h148.72m-148.72 4.72v-9.44"
    />
    <line className="cls-2" x1="1168.8" x2="1317.5" y1="951.81" y2="871.55" />
    <line className="cls-2" x1="916.16" x2="1057" y1="951.81" y2="951.81" />
    <path
      className="cls-2"
      transform="translate(-148.26 -251.52)"
      d="m1255.6 1124.6v78.69m-191.22-9.45h262"
    />
    <path
      className="cls-2"
      transform="translate(-148.26 -251.52)"
      d="m1205.3 1203.3h111.72m-262 166.83v-157.39m50.36 157.39v-157.39m49.58 157.39v-157.39m50.36 157.39v-157.39m50.36 157.39v-157.39"
    />
    <line className="cls-3" x1="630.52" x2="639.96" y1="1118.6" y2="1118.6" />
    <path
      className="cls-8"
      transform="translate(-148.26 -251.52)"
      d="M244.47,1208.83V1356m3.93-147.15V1356m5.51-147.15V1356M239,1208.83V1356"
    />
    <polygon
      className="cls-8"
      points="105.65 1118.6 105.65 1104.5 90.7 1104.5 90.7 1118.6 105.65 1118.6 105.65 1104.5 90.7 1104.5 90.7 1118.6"
    />
    <polyline
      className="cls-4"
      points="137.13 942.37 90.7 942.37 137.13 942.37 90.7 942.37"
    />
    <polygon
      className="cls-8"
      points="105.65 957.32 105.65 942.37 90.7 942.37 90.7 957.32 105.65 957.32 105.65 942.37 90.7 942.37 90.7 957.32"
    />
    <line className="cls-4" x1="137.13" x2="90.7" y1="1118.6" y2="1118.6" />
    <path
      className="cls-4"
      transform="translate(-148.26 -251.52)"
      d="M620.61,1417.37v-47.21M285.39,1193.88H239"
    />
    <polyline
      className="cls-4"
      points="639.96 1165.8 639.96 1118.6 639.96 1141.5"
    />
    <line className="cls-4" x1="450.32" x2="451.1" y1="1118.6" y2="1118.6" />
    <line className="cls-4" x1="472.35" x2="164.67" y1="1118.6" y2="1118.6" />
    <line className="cls-4" x1="417.52" x2="109.84" y1="1118.6" y2="1118.6" />
    <line className="cls-4" x1="472.35" x2="164.67" y1="1165.8" y2="1165.8" />
    <line className="cls-4" x1="400.82" x2="93.14" y1="1165.8" y2="1165.8" />
    <rect className="cls-2" x="844.56" y="1147" width="9.45" height="18.89" />
    <polyline
      className="cls-4"
      points="686.39 1118.6 686.39 1165.8 686.39 1147"
    />
    <line className="cls-4" x1="854" x2="854" y1="1165.8" y2="1118.6" />
    <line className="cls-3" x1="695.83" x2="686.39" y1="1165.8" y2="1165.8" />
    <path
      className="cls-4"
      transform="translate(-148.26 -251.52)"
      d="m598.58 1370.2h22m-22-166.83h22"
    />
    <rect
      className="cls-2"
      x="456.61"
      y="877.85"
      width="9.44"
      height="174.69"
    />
    <path
      className="cls-4"
      transform="translate(-148.26 -251.52)"
      d="m1002.3 1370.2h463.48m0 47.21h-463.48"
    />
    <line className="cls-12" x1="650.03" x2="456.61" y1="1920.5" y2="1920.5" />
    <path
      className="cls-13"
      transform="translate(-148.26 -251.52)"
      d="M798.29,2153.13H604.87m193.42,9.43H604.87"
    />
    <polyline
      className="cls-12"
      points="650.03 1890.6 456.61 1890.6 650.03 1890.6 456.61 1890.6"
    />
    <path
      className="cls-4"
      transform="translate(-148.26 -251.52)"
      d="m604.87 2142.1v-46.43"
    />
    <path
      className="cls-14"
      transform="translate(-148.26 -251.52)"
      d="m604.87 2169.2v-43.63"
    />
    <path
      className="cls-4"
      transform="translate(-148.26 -251.52)"
      d="m604.87 2106.5v-46.43"
    />
    <path
      className="cls-4"
      transform="translate(-148.26 -251.52)"
      d="m604.87 2063v-46.4"
    />
    <path
      className="cls-4"
      transform="translate(-148.26 -251.52)"
      d="m604.87 2049.3v-46.43"
    />
    <path
      className="cls-4"
      transform="translate(-148.26 -251.52)"
      d="m822.84 2002.8v-585.46m-22.84 585.46v-585.46m-561-47.21v632.67"
    />
    <polyline
      className="cls-6"
      points="1317.5 1751.4 1317.5 1139.2 1317.5 1706.4 1317.5 1139.2 1317.5 1706.4"
    />
    <line className="cls-3" x1="799.7" x2="738.32" y1="346.69" y2="198.77" />
    <polyline
      className="cls-3"
      points="738.32 346.69 799.7 198.77 799.7 346.69"
    />
    <polyline
      className="cls-3"
      points="738.32 198.77 799.7 198.77 799.7 50.01"
    />
    <path
      className="cls-3"
      transform="translate(-148.26 -251.52)"
      d="m803.17 301.52 61.38 148.77m83.45-148.77-61.42 148.77m61.38 0-61.38-148.77"
    />
    <polyline
      className="cls-3"
      points="716.29 50.01 654.91 198.77 654.91 50.01"
    />
    <polyline
      className="cls-3"
      points="716.29 198.77 654.91 198.77 654.91 346.69"
    />
    <path
      className="cls-3"
      transform="translate(-148.26 -251.52)"
      d="m803.17 598.2 61.38-147.91m-61.38 0 61.38 147.91"
    />
    <line className="cls-15" x1="742.26" x2="738.32" y1="490.66" y2="490.66" />
    <path
      className="cls-3"
      transform="translate(-148.26 -251.52)"
      d="M733.13,759.55H752m-18.89-2.35H752m-18.89,2.35v7m-3.93,3.94H763m92.08,1.6L831.5,761.89m-114.89,56.7V682.39m104.66,69.27L801.59,807.5M804,745.37l-19.68,55.84m47.22-39.32-15.74,43.27m25.19,7,14.16-40.07"
    />
    <polyline
      className="cls-3"
      points="584.87 515.06 603.76 515.06 603.76 508.03"
    />
    <path
      className="cls-3"
      transform="translate(-148.26 -251.52)"
      d="M853.53,679.3h3.93m-11.79,0h4.72m-11.81,0h3.93m-11.79,0h4.72m-11.81,0h3.93m-11.8,0h4.73m-11.81,0h4.72m-12.59,0h4.72m-11.8,0h4.72m-11.8,0h3.93m-11.8,0h4.72m-11,0h3.15M752,691.88H733.13m29.9-4H729.2m43.28-12.57H716.61m55.87,7H716.61m104.66,69.27L804,745.37"
    />
    <line className="cls-3" x1="712.36" x2="716.29" y1="427.79" y2="427.79" />
    <path
      className="cls-3"
      transform="translate(-148.26 -251.52)"
      d="m763 770.52v-82.59m-33.83 0v82.59m3.93-78.64v65.32m18.89 0v-65.32m79.48 70-10.23-10.23"
    />
    <polyline
      className="cls-3"
      points="636.02 549.7 653.33 555.99 667.5 553.64 692.69 560.68"
    />
    <path
      className="cls-3"
      transform="translate(-148.26 -251.52)"
      d="m772.48 602.15h-55.87m147.94 220.27h-92.07m0-3.83h-55.87"
    />
    <polyline
      className="cls-3"
      points="604.54 379.72 597.47 389.21 586.45 382.92"
    />
    <path
      className="cls-3"
      transform="translate(-148.26 -251.52)"
      d="m741 637.53 7.07-9.38m4.72 3.09-10.22-7.89"
    />
    <polyline
      className="cls-3"
      points="586.45 382.92 584.87 380.47 584.08 378.98 584.87 376.63 585.66 374.18 587.24 371.84 589.6 371.09 591.96 371.09 594.33 371.84"
    />
    <path
      className="cls-3"
      transform="translate(-148.26 -251.52)"
      d="m750.45 634.44-4.72-3.2m2.35 6.29-4.72-3.09"
    />
    <polyline
      className="cls-3"
      points="624.22 347.44 716.29 347.44 716.29 570.91"
    />
    <path
      className="cls-3"
      transform="translate(-148.26 -251.52)"
      d="M716.61,675.36V602.15m55.87,220.27V599"
    />
    <polyline
      className="cls-3"
      points="610.05 401.78 614 400.19 615.56 396.24"
    />
    <line className="cls-3" x1="610.05" x2="581.73" y1="401.78" y2="401.78" />
    <polyline
      className="cls-3"
      points="576.22 396.24 577.8 400.19 581.73 401.78"
    />
    <line className="cls-3" x1="576.22" x2="576.22" y1="396.24" y2="360.86" />
    <polyline
      className="cls-3"
      points="581.73 355.32 577.8 356.92 576.22 360.86"
    />
    <line className="cls-3" x1="581.73" x2="610.05" y1="355.32" y2="355.32" />
    <polyline
      className="cls-3"
      points="615.56 360.86 614 356.92 610.05 355.32"
    />
    <line className="cls-3" x1="615.56" x2="615.56" y1="360.86" y2="396.24" />
    <polyline
      className="cls-3"
      points="600.61 384.41 607.7 389.95 615.56 393.15 624.22 394.64"
    />
    <polyline
      className="cls-3"
      points="525.08 412.76 525.08 404.13 469.2 407.32"
    />
    <path
      className="cls-3"
      transform="translate(-148.26 -251.52)"
      d="m609.59 701.25h-6.29m6.29 11.09h-6.29"
    />
    <polyline
      className="cls-3"
      points="434.58 437.17 435.37 429.28 436.94 423.84 440.09 419.05 442.44 418.3"
    />
    <polyline
      className="cls-3"
      points="434.58 474.14 435.37 482.03 436.94 487.57 440.09 491.51 442.44 493.01"
    />
    <polyline
      className="cls-3"
      points="447.95 437.17 448.74 429.28 450.32 423.84 452.68 419.05 455.04 418.3"
    />
    <polyline
      className="cls-3"
      points="447.95 474.14 448.74 482.03 450.32 487.57 452.68 491.51 455.04 493.01"
    />
    <path
      className="cls-3"
      transform="translate(-148.26 -251.52)"
      d="M673.34,758.69v-8.63m-70-5.54v-74.7m-7.09,55.84v-37m-13.37,37v-37m20.46,55.84H590.7m12.6-74.7H590.7m5.51,55.84H582.84m13.37-37H582.84"
    />
    <polyline
      className="cls-3"
      points="469.99 495.46 469.2 504.09 525.08 507.18"
    />
    <polyline
      className="cls-3"
      points="461.33 485.97 462.12 490.66 465.26 493.86 469.99 495.46"
    />
    <line className="cls-3" x1="461.33" x2="461.33" y1="485.97" y2="424.59" />
    <polyline
      className="cls-3"
      points="529.01 498.55 533.73 497.8 537.66 493.86 539.24 489.06"
    />
    <path
      className="cls-3"
      transform="translate(-148.26 -251.52)"
      d="m687.5 740.58v-67.67m-10.23 77.15-59-3.09"
    />
    <polyline
      className="cls-3"
      points="469.2 407.32 469.99 415.96 465.26 416.7 462.12 420.65 461.33 424.59"
    />
    <polyline
      className="cls-3"
      points="539.24 421.39 537.66 416.7 533.73 413.61 529.01 412.01 469.99 415.96"
    />
    <polyline
      className="cls-3"
      points="716.29 570.91 716.29 347.44 642.32 347.44"
    />
    <polyline
      className="cls-3"
      points="810.72 1.2 811.51 2.8 814.65 2.8 815.44 1.2"
    />
    <polyline
      className="cls-3"
      points="806 1.2 806.79 2.8 809.93 2.8 810.72 1.2"
    />
    <polyline
      className="cls-3"
      points="802.07 1.2 802.86 2.8 806 2.8 806 1.2"
    />
    <polyline
      className="cls-3"
      points="797.34 1.2 798.13 2.8 801.28 2.8 802.07 1.2"
    />
    <polyline
      className="cls-3"
      points="792.62 1.2 793.4 2.8 796.56 2.8 797.34 1.2"
    />
    <polyline
      className="cls-3"
      points="788.68 1.2 789.47 2.8 792.62 2.8 792.62 1.2"
    />
    <polyline
      className="cls-3"
      points="783.96 1.2 784.75 2.8 787.89 2.8 788.68 1.2"
    />
    <polyline
      className="cls-3"
      points="779.24 1.2 780.03 2.8 783.17 2.8 783.96 1.2"
    />
    <polyline
      className="cls-3"
      points="775.31 1.2 776.1 2.8 779.24 2.8 779.24 1.2"
    />
    <polyline
      className="cls-3"
      points="770.59 1.2 771.38 2.8 774.52 2.8 775.31 1.2"
    />
    <polyline
      className="cls-3"
      points="765.87 1.2 766.65 2.8 769.8 2.8 770.59 1.2"
    />
    <polyline
      className="cls-3"
      points="761.93 1.2 762.72 2.8 765.87 2.8 765.87 1.2"
    />
    <polyline
      className="cls-3"
      points="757.21 1.2 758 2.8 761.14 2.8 761.93 1.2"
    />
    <polyline
      className="cls-3"
      points="752.48 1.2 753.28 2.8 756.42 2.8 757.21 1.2"
    />
    <polyline
      className="cls-3"
      points="748.55 1.2 749.34 2.8 752.48 2.8 752.48 1.2"
    />
    <polyline
      className="cls-3"
      points="743.83 1.2 744.62 2.8 747.76 2.8 748.55 1.2"
    />
    <polyline
      className="cls-3"
      points="739.11 1.2 739.9 2.8 743.04 2.8 743.83 1.2"
    />
    <polyline
      className="cls-3"
      points="735.17 1.2 735.96 2.8 739.11 2.8 739.11 1.2"
    />
    <polyline
      className="cls-3"
      points="730.45 1.2 731.24 2.8 734.39 2.8 735.17 1.2"
    />
    <polyline
      className="cls-3"
      points="725.73 1.2 726.52 2.8 729.66 2.8 730.45 1.2"
    />
    <polyline
      className="cls-3"
      points="721.8 1.2 722.58 2.8 725.73 2.8 725.73 1.2"
    />
    <polyline
      className="cls-3"
      points="717.08 1.2 717.87 2.8 721.01 2.8 721.8 1.2"
    />
    <polyline
      className="cls-3"
      points="712.36 1.2 713.15 2.8 716.29 2.8 717.08 1.2"
    />
    <polyline
      className="cls-3"
      points="708.43 1.2 709.21 2.8 712.36 2.8 712.36 1.2"
    />
    <polyline
      className="cls-3"
      points="703.7 1.2 704.48 2.8 707.64 2.8 708.43 1.2"
    />
    <polyline
      className="cls-3"
      points="698.97 1.2 699.76 2.8 702.91 2.8 703.7 1.2"
    />
    <polyline
      className="cls-3"
      points="695.04 1.2 695.83 2.8 698.97 2.8 698.97 1.2"
    />
    <polyline
      className="cls-3"
      points="690.32 1.2 691.11 2.8 694.25 2.8 695.04 1.2"
    />
    <polyline
      className="cls-3"
      points="685.6 1.2 686.39 2.8 689.53 2.8 690.32 1.2"
    />
    <polyline
      className="cls-3"
      points="681.67 1.2 682.46 2.8 685.6 2.8 685.6 1.2"
    />
    <polyline
      className="cls-3"
      points="676.95 1.2 677.74 2.8 680.88 2.8 681.67 1.2"
    />
    <polyline
      className="cls-3"
      points="672.23 1.2 673.01 2.8 676.16 2.8 676.95 1.2"
    />
    <polyline
      className="cls-3"
      points="668.29 1.2 668.29 2.8 672.23 2.8 672.23 1.2"
    />
    <polyline
      className="cls-3"
      points="663.57 1.2 664.35 2.8 667.5 2.8 668.29 1.2"
    />
    <polyline
      className="cls-3"
      points="658.85 1.2 659.63 2.8 662.78 2.8 663.57 1.2"
    />
    <polyline
      className="cls-3"
      points="654.91 1.2 654.91 2.8 658.06 2.8 658.85 1.2"
    />
    <polyline
      className="cls-3"
      points="650.19 1.2 650.98 2.8 654.12 2.8 654.91 1.2"
    />
    <polyline
      className="cls-3"
      points="645.47 1.2 646.25 2.8 649.4 2.8 650.19 1.2"
    />
    <polyline
      className="cls-3"
      points="641.53 1.2 641.53 2.8 644.68 2.8 645.47 1.2"
    />
    <path
      className="cls-8"
      transform="translate(-148.26 -251.52)"
      d="M1137.61,264.54H981m156.6-3.94H981"
    />
    <polygon
      className="cls-8"
      points="818.59 3.54 832.75 3.54 832.75 18.57 818.59 18.57 818.59 3.54 832.75 3.54 832.75 18.57 818.59 18.57"
    />
    <line className="cls-8" x1="989.35" x2="832.75" y1="18.57" y2="18.57" />
    <polyline
      className="cls-8"
      points="832.75 3.54 1004.3 3.54 1004.3 18.57 989.35 18.57 989.35 3.54 1004.3 3.54 1004.3 18.57 989.35 18.57 989.35 3.54"
    />
    <line className="cls-4" x1="818.59" x2="818.59" y1="50.01" y2="3.54" />
    <line className="cls-16" x1="1050.7" x2="1050.7" y1="3.54" y2="305.33" />
    <polyline className="cls-4" points="1004.3 50.01 1004.3 3.54 1050.7 3.54" />
    <line className="cls-17" x1="1004.3" x2="1004.3" y1="3.54" y2="306.38" />
    <path
      className="cls-4"
      transform="translate(-148.26 -251.52)"
      d="m598.58 301.52v-46.46m185.7 46.46v-46.46"
    />
    <path
      className="cls-18"
      transform="translate(-148.26 -251.52)"
      d="M598.58,255.06V562M552.15,255.06V562"
    />
    <line className="cls-4" x1="403.89" x2="450.32" y1="3.54" y2="3.54" />
    <polygon
      className="cls-8"
      points="465.26 3.54 450.32 3.54 450.32 18.57 465.26 18.57 465.26 3.54 450.32 3.54 450.32 18.57 465.26 18.57"
    />
    <polygon
      className="cls-8"
      points="636.02 3.54 621.07 3.54 621.07 18.57 636.02 18.57 636.02 3.54 621.07 3.54 621.07 18.57 636.02 18.57"
    />
    <path
      className="cls-8"
      transform="translate(-148.26 -251.52)"
      d="m613.52 255.06h155.81m-155.81 15h155.81m-155.81-5.54h155.81m-155.81-3.92h155.81"
    />
    <path
      className="cls-4"
      transform="translate(-148.26 -251.52)"
      d="M966.85,255.06H784.28m182.57,46.46H784.28"
    />
    <line className="cls-3" x1="1312.8" x2="1312.8" y1="309.71" y2="310.46" />
    <rect
      className="cls-19"
      x="1161.4"
      y="319.49"
      width="149.51"
      height="327.82"
    />
    <polyline
      className="cls-3"
      points="1310.9 545.01 1282.6 572.5 1282.6 545.01"
    />
    <rect
      className="cls-3"
      x="1189.8"
      y="572.48"
      width="92.85"
      height="55.87"
    />
    <line className="cls-3" x1="1282.6" x2="1310.9" y1="545.01" y2="545.01" />
    <line className="cls-15" x1="738.32" x2="738.32" y1="570.91" y2="347.43" />
    <line className="cls-4" x1="716.29" x2="738.32" y1="793.63" y2="793.63" />
    <polyline
      className="cls-3"
      points="706.85 622.91 685.6 624.51 665.14 629.2 644.68 636.23 626.58 646.46 609.27 659.04 594.33 674.06 581.73 691.33 571.5 709.44 563.63 729.9 559.69 750.37 558.12 771.57 548.68 771.57"
    />
    <line className="cls-3" x1="706.85" x2="716.29" y1="771.57" y2="771.57" />
    <line className="cls-4" x1="548.68" x2="548.68" y1="771.57" y2="793.63" />
    <path
      className="cls-2"
      transform="translate(-148.26 -251.52)"
      d="m855.11 874.43v148.66m-8.66-148.66v148.66"
    />
    <path
      className="cls-4"
      transform="translate(-148.26 -251.52)"
      d="m696.94 1045.2h0.79m166.82 0v-22.06"
    />
    <rect className="cls-2" x="706.85" y="771.59" width="9.44" height="18.1" />
    <rect className="cls-2" x="548.68" y="771.59" width="9.44" height="18.1" />
    <rect
      className="cls-3"
      x="143.69"
      y="418.26"
      width="149.51"
      height="353.32"
    />
    <polyline
      className="cls-3"
      points="143.69 669.27 171.22 696.87 171.22 669.27"
    />
    <rect
      className="cls-3"
      x="171.22"
      y="696.84"
      width="92.86"
      height="55.87"
    />
    <line className="cls-3" x1="171.22" x2="143.69" y1="669.27" y2="669.27" />
    <path
      className="cls-4"
      transform="translate(-148.26 -251.52)"
      d="m864.55 301.52v721.57m22-721.57v721.57"
    />
    <path
      className="cls-4"
      transform="translate(-148.26 -251.52)"
      d="m696.94 1045.2h-76.33m433.59-22.06h411.54m-411.54 22.06h411.54"
    />
    <rect className="cls-2" x="896.5" y="771.59" width="9.44" height="18.1" />
    <rect className="cls-2" x="738.32" y="771.59" width="9.44" height="18.1" />
    <polyline
      className="cls-3"
      points="896.5 771.57 894.92 750.37 890.2 729.9 883.11 709.44 872.88 691.33 860.3 674.06 845.35 659.04 828.03 646.46 809.14 636.23 789.47 629.2 769.01 624.51 747.76 622.91"
    />
    <line className="cls-3" x1="747.76" x2="738.32" y1="771.57" y2="771.57" />
    <line className="cls-4" x1="905.94" x2="905.94" y1="771.57" y2="793.63" />
    <path
      className="cls-2"
      transform="translate(-148.26 -251.52)"
      d="m896 874.43v148.66m7.88-148.66v148.66"
    />
    <path
      className="cls-20"
      transform="translate(-148.26 -251.52)"
      d="M598.58,562H239m815.27,483.18h.75"
    />
    <path
      className="cls-21"
      transform="translate(-148.26 -251.52)"
      d="m1511.6 562h-358.47m241.8 436.71v24.41"
    />
    <line className="cls-2" x1="916.16" x2="1308" y1="803.12" y2="803.12" />
    <path
      className="cls-21"
      transform="translate(-148.26 -251.52)"
      d="m1394.9 998.68z"
    />
    <rect
      className="cls-21"
      x="1246.7"
      y="647.23"
      width="70.82"
      height="99.94"
    />
    <path
      className="cls-4"
      transform="translate(-148.26 -251.52)"
      d="M598.58,1045.15H285.39m411.55-22.06H285.39"
    />
    <rect
      className="cls-22"
      x="945.28"
      y="1297.3"
      width="372.2"
      height="299.81"
    />
    <polygon
      className="cls-3"
      points="1215.2 1325.6 1215.2 1297.3 1242.7 1325.6"
    />
    <polygon
      className="cls-3"
      points="1215.2 1568.8 1215.2 1597.1 1242.7 1568.8"
    />
    <rect
      className="cls-3"
      x="1242.7"
      y="1475.9"
      width="55.87"
      height="92.86"
    />
    <rect
      className="cls-3"
      x="1242.7"
      y="1325.6"
      width="55.87"
      height="94.43"
    />
    <path
      className="cls-3"
      transform="translate(-148.26 -251.52)"
      d="M299.27,1444.9v100.73m133,0V1444.9"
    />
    <polyline
      className="cls-3"
      points="140.78 1226.3 140.78 1169.8 293.43 1169.8 293.43 1318.5 140.78 1318.5 140.78 1224.9 140.78 1318.5"
    />
    <polyline
      className="cls-3"
      points="151.01 1294.1 154.94 1302 162.03 1306.7 170.68 1308.3 269.83 1308.3 275.34 1307.5 280.06 1304.4 283.2 1299.6 283.99 1294.1"
    />
    <polyline
      className="cls-3"
      points="154.15 1318.5 293.43 1318.5 140.78 1318.5"
    />
    <polyline
      className="cls-3"
      points="170.68 1179.2 162.03 1180.8 154.94 1185.5 151.01 1193.4"
    />
    <polygon
      className="cls-3"
      points="189.56 1207.6 187.99 1204.4 184.84 1202.8 181.7 1204.4 180.12 1207.6 181.7 1210.7 184.84 1212.3 187.99 1210.7"
    />
    <line className="cls-3" x1="269.83" x2="170.68" y1="1179.2" y2="1179.2" />
    <polyline
      className="cls-3"
      points="283.99 1193.4 283.2 1187.9 280.06 1183.2 275.34 1180 269.83 1179.2"
    />
    <polyline
      className="cls-3"
      points="181.19 1368.1 169.39 1364.9 157.59 1364.2"
    />
    <polyline
      className="cls-3"
      points="215.03 1376.8 219.75 1376 231.56 1374.4"
    />
    <line className="cls-3" x1="219.75" x2="215.03" y1="1427.1" y2="1425.5" />
    <polyline
      className="cls-3"
      points="231.56 1374.4 244.93 1376 256.74 1381.5 264.61 1390.9 267.75 1401.1 264.61 1411.4 256.74 1420.8 244.93 1426.3 231.56 1427.9 219.75 1427.1"
    />
    <path
      className="cls-3"
      transform="translate(-148.26 -251.52)"
      d="m349.13 1646.4v12.58m-15.74-30.68 7.87-0.78"
    />
    <polygon
      className="cls-3"
      points="172.54 1401.1 170.96 1398.8 168.61 1397.2 166.24 1398.8 164.67 1401.1 166.24 1403.5 168.61 1405.1 170.96 1403.5"
    />
    <polyline
      className="cls-3"
      points="181.19 1434.2 183.56 1432.6 185.13 1428.7"
    />
    <line className="cls-3" x1="193" x2="185.13" y1="1426.3" y2="1425.5" />
    <polyline
      className="cls-3"
      points="152.08 1432.6 153.65 1437.3 157.59 1438.9 169.39 1437.3 181.19 1434.2"
    />
    <path
      className="cls-3"
      transform="translate(-148.26 -251.52)"
      d="m333.39 1680.2v-55.08m-33.05-3.93v63"
    />
    <polyline
      className="cls-3"
      points="215.03 1376.8 207.95 1380.7 202.44 1387 200.87 1394.8"
    />
    <line className="cls-3" x1="218.96" x2="193" y1="1432.6" y2="1426.3" />
    <polyline
      className="cls-3"
      points="200.87 1407.4 202.44 1415.3 207.95 1422.4 215.03 1425.5"
    />
    <polyline
      className="cls-3"
      points="201.66 1388.6 210.31 1389.3 217.4 1392.5 223.68 1397.2 224.47 1401.1 223.68 1405.9 217.4 1410.6 210.31 1412.9 201.66 1413.7"
    />
    <line className="cls-3" x1="218.96" x2="193" y1="1370.4" y2="1376" />
    <polyline
      className="cls-3"
      points="231.56 1368.9 246.51 1371.2 259.88 1377.5 269.33 1387.8"
    />
    <polyline
      className="cls-3"
      points="273.26 1401.1 272.47 1394 269.33 1387.8"
    />
    <polyline
      className="cls-3"
      points="269.33 1414.5 272.47 1408.2 273.26 1401.1"
    />
    <polyline
      className="cls-3"
      points="269.33 1414.5 259.88 1425.5 246.51 1431.8 231.56 1433.4 218.96 1432.6"
    />
    <polyline
      className="cls-3"
      points="157.59 1364.2 153.65 1365.7 152.08 1369.7"
    />
    <polyline
      className="cls-3"
      points="185.13 1373.6 183.56 1370.4 181.19 1368.1"
    />
    <path
      className="cls-3"
      transform="translate(-148.26 -251.52)"
      d="m796.09 1926.5h-12.59m-66.89-279.34h79.8m-416.59-26.76-12.6 1.57"
    />
    <polygon
      className="cls-3"
      points="607.7 1551.4 614 1552.2 620.28 1554.6 625.79 1558.5 630.51 1564 634.46 1571.1 638.39 1578.2 639.96 1586.8 641.53 1595.5 641.53 1604.9 639.96 1613.6 637.6 1622.3 634.46 1629.3 629.73 1635.6 625 1640.4 619.5 1644.3 613.21 1645.9 607.7 1645.9 601.4 1644.3 595.89 1641.1 590.38 1636.4 585.66 1630.9 581.73 1623 579.36 1615.2 577.8 1606.5 577.01 1597.1 577.8 1588.4 579.36 1579.8 582.52 1571.9 586.45 1564.8 591.17 1559.3 595.89 1555.4 602.19 1552.2"
    />
    <polygon
      className="cls-3"
      points="610.84 1547.5 617.93 1548.3 625 1551.4 631.3 1556.2 637.6 1562.4 641.53 1570.3 645.47 1579 647.04 1588.4 648.61 1598.6 647.83 1608.1 646.25 1617.5 643.11 1626.2 639.18 1634.1 633.66 1641.1 627.37 1645.9 620.28 1649.8 613.21 1650.6 606.12 1649.8 598.26 1646.6 591.96 1641.9 586.45 1635.6 581.73 1627.8 578.59 1619.1 576.22 1609.7 575.43 1600.2 576.22 1590 577.8 1580.6 580.94 1571.9 584.87 1564 590.38 1557.7 595.89 1552.2 602.98 1549.1"
    />
    <path
      className="cls-3"
      transform="translate(-148.26 -251.52)"
      d="m778.78 1865.1-22-30.69m22 4.72-9.45 13.37"
    />
    <polygon
      className="cls-3"
      points="631.3 1604.2 628.94 1604.2 628.94 1603.4 628.16 1602.6 628.16 1601.8 627.37 1601 627.37 1600.2 628.16 1599.4 628.16 1598.6 628.94 1597.9 629.73 1597.1 633.66 1597.1 634.46 1597.9 635.24 1598.6 635.24 1602.6 634.46 1602.6 634.46 1603.4 633.66 1604.2"
    />
    <path
      className="cls-3"
      transform="translate(-148.26 -251.52)"
      d="m792.94 1865.1h-14.16m-62.17 61.37h79.84"
    />
    <polygon
      className="cls-3"
      points="631.3 1474.3 630.51 1473.5 628.94 1473.5 628.94 1472.7 628.16 1472 628.16 1471.2 627.37 1471.2 627.37 1470.4 628.16 1469.6 628.16 1468 628.94 1467.2 629.73 1466.5 632.88 1466.5 633.66 1467.2 634.46 1467.2 635.24 1468 635.24 1472 634.46 1472.7 633.66 1473.5 632.88 1473.5 632.09 1474.3"
    />
    <line className="cls-3" x1="630.52" x2="608.48" y1="1483" y2="1452.3" />
    <polyline
      className="cls-3"
      points="645.47 1457 630.51 1457 621.07 1470.4"
    />
    <polygon
      className="cls-3"
      points="607.7 1517.6 614 1516.8 620.28 1514.4 625.79 1510.5 630.51 1505 634.46 1498.7 638.39 1490.8 639.96 1482.2 641.53 1473.5 641.53 1464.1 639.96 1455.4 637.6 1447.6 634.46 1439.7 629.73 1433.4 625 1428.7 619.5 1424.7 613.21 1423.2 607.7 1423.2 601.4 1424.7 595.89 1427.9 590.38 1432.6 585.66 1438.9 581.73 1446 579.36 1453.9 577.8 1462.5 577.01 1472 577.8 1480.6 579.36 1489.3 582.52 1497.1 586.45 1504.2 591.17 1509.7 595.89 1513.7 602.19 1516.8"
    />
    <path
      className="cls-3"
      transform="translate(-148.26 -251.52)"
      d="m793.73 1839.2h-15m14.16-104.66h-14.11"
    />
    <polygon
      className="cls-3"
      points="610.84 1416.9 617.93 1417.7 625 1420.8 631.3 1425.5 637.6 1431.8 641.53 1439.7 645.47 1448.4 647.04 1457.8 648.61 1468 647.83 1477.5 646.25 1486.9 643.11 1495.6 639.18 1504.2 633.66 1510.5 627.37 1515.2 620.28 1519.2 613.21 1520 606.12 1519.2 598.26 1516 591.96 1511.3 586.45 1505 581.73 1497.1 578.59 1488.5 576.22 1479 575.43 1469.6 576.22 1459.4 577.8 1449.9 580.94 1441.3 584.87 1433.4 590.38 1427.1 595.89 1421.6 602.98 1418.4"
    />
    <line className="cls-3" x1="568.35" x2="568.35" y1="1395.6" y2="1675" />
    <polyline
      className="cls-3"
      points="695.83 1314.6 717.08 1313 737.54 1308.3 757.21 1300.4 776.1 1291 793.4 1277.6 808.36 1262.6 820.95 1246.1 831.18 1227.2 838.26 1207.6 842.98 1186.3 844.56 1165.8"
    />
    <path
      className="cls-2"
      transform="translate(-148.26 -251.52)"
      d="m852 1565.3v-147.93m-7.87 148.72v-148.72"
    />
    <polyline
      className="cls-3"
      points="630.51 1118.6 628.94 1098.2 624.22 1076.9 617.14 1057.3 606.91 1038.4 594.33 1021.8 579.36 1006.9 562.06 994.31 543.96 984.08 523.5 976.2 503.04 971.48 481.79 970.71"
    />
    <path
      className="cls-2"
      transform="translate(-148.26 -251.52)"
      d="m630.05 1222.2v147.94m7.86-147.94v147.94"
    />
    <line className="cls-3" x1="205.59" x2="211.89" y1="1730.8" y2="1728.5" />
    <polyline
      className="cls-3"
      points="182.77 1728.5 194.57 1731.6 205.59 1730.8"
    />
    <polyline
      className="cls-3"
      points="166.24 1713.5 173.32 1723 182.77 1728.5"
    />
    <polyline
      className="cls-3"
      points="156.01 1678.9 159.17 1697 166.24 1713.5"
    />
    <polyline
      className="cls-3"
      points="157.59 1640.4 155.22 1660 156.01 1678.9"
    />
    <line className="cls-3" x1="159.95" x2="157.59" y1="1628.6" y2="1640.4" />
    <polygon
      className="cls-3"
      points="293.72 1713.5 300.81 1697 303.95 1678.9 304.74 1660 302.38 1640.4 296.08 1616.8 288.21 1593.9 277.2 1572.7 264.61 1552.2 252.01 1537.3 237.86 1523.9 222.9 1513.7 205.59 1507.4 194.57 1506.6 182.77 1509 173.32 1515.2 166.24 1524.7 159.17 1541.2 156.01 1558.5 155.22 1578.2 157.59 1597.1 163.89 1620.7 171.75 1643.5 182.77 1665.5 195.36 1686 207.95 1701 222.12 1714.3 237.07 1723.8 253.6 1730.8 265.39 1731.6 276.41 1728.5 286.64 1723"
    />
    <polyline
      className="cls-3"
      points="322.83 1713.5 321.27 1681.3 317.32 1649 310.25 1617.5 301.6 1591.6 290.58 1566.4 276.41 1542.8 260.67 1522.3 242.58 1504.2 221.33 1488.5 204.01 1479.8 184.34 1474.3 164.67 1472"
    />
    <polyline
      className="cls-3"
      points="248.08 1728.5 236.28 1726.1 223.68 1726.1 211.89 1728.5"
    />
    <line className="cls-3" x1="322.83" x2="322.83" y1="1748" y2="1713.5" />
    <polygon
      className="cls-3"
      points="273.26 1698.6 271.69 1694.6 267.75 1693.1 263.82 1694.6 262.25 1698.6 263.82 1702.5 267.75 1704.9 271.69 1702.5"
    />
    <polyline
      className="cls-3"
      points="159.17 1634.1 160.73 1623.8 160.73 1613.6 159.17 1603.4"
    />
    <line className="cls-3" x1="140.93" x2="164.67" y1="1472" y2="1472" />
    <line className="cls-3" x1="137.13" x2="137.13" y1="1176.1" y2="1182.4" />
    <line className="cls-3" x1="137.13" x2="137.13" y1="1204.4" y2="1209.9" />
    <line className="cls-3" x1="137.13" x2="137.13" y1="1176.1" y2="1182.4" />
    <line className="cls-3" x1="137.13" x2="137.13" y1="1232" y2="1238.2" />
    <rect className="cls-23" x=".06" y="1453.7" width="3.36" height=".36" />
    <rect className="cls-23" x=".06" y="1550.4" width="3.36" height=".36" />
    <polyline
      className="cls-23"
      points="3.18 1447.1 0.3 1447.1 0.06 1444.9 0.3 1447.1"
    />
    <rect x="90.7" y="1118.6" width="46.43" height="632.67" />
    <rect
      transform="translate(-1754.2 2194.6) rotate(-90)"
      x="396.91"
      y="1841.3"
      width="46.43"
      height="369.4"
    />
    <rect
      transform="translate(-907.51 3041.3) rotate(-90)"
      x="1243.6"
      y="1777.2"
      width="46.43"
      height="497.59"
    />
    <rect
      transform="translate(-267.92 703.32) rotate(-90)"
      x="394.37"
      y="355.08"
      width="46.43"
      height="364.34"
    />
    <rect
      transform="translate(648.72 1623) rotate(-90)"
      x="1312.5"
      y="358.86"
      width="46.43"
      height="359.79"
    />
    <rect x="413.23" y="1797.7" width="46.43" height="123.1" />
    <rect x="866.49" y="1797.7" width="46.43" height="123.1" />
    <rect x="120.6" y="1118.6" width="349.73" height="47.21" />
    <rect x="639.96" y="1118.6" width="46.43" height="46.43" />
    <rect x="648.61" y="1163.4" width="29.44" height="757.6" />
    <rect x="403.89" y="3.54" width="46.43" height="306.91" />
    <rect x="90.7" y="310.46" width="46.43" height="631.91" />
    <rect x="636.02" y="3.54" width="182.56" height="46.46" />
    <rect x="1000.8" y="3.54" width="53.08" height="307.29" />
    <rect x="714.33" y="53.2" width="24.78" height="740.43" />
    <rect x="735.3" y="770.89" width="12.46" height="18.8" />
    <rect x="706.85" y="771.59" width="10.53" height="18.1" />
    <rect x="137.13" y="771.57" width="411.95" height="22.06" />
    <rect x="549.86" y="771.59" width="8.26" height="18.1" />
    <rect x="450.32" y="791.87" width="22.03" height="159.94" />
    <rect x="90.7" y="943.43" width="15.94" height="13.89" />
    <rect x="90.7" y="1104.5" width="14.17" height="14.17" />
    <rect x="473.98" y="1118.6" width="7.81" height="18.89" />
    <rect x="630.52" y="1118.6" width="7.44" height="18.89" />
    <rect x="688.08" y="1147" width="7.75" height="18.89" />
    <rect x="854" y="1118.6" width="509.91" height="47.21" />
    <rect x="844.56" y="1147" width="10.01" height="18.34" />
    <rect x="1317.5" y="309.71" width="46.43" height="1441.7" />
    <rect x="905.94" y="770.89" width="411.55" height="22.74" />
    <rect x="896.5" y="771.57" width="9.47" height="18.12" />
    <rect x="452.14" y="3.54" width="14.05" height="14.05" />
    <rect x="621.07" y="3.54" width="12.3" height="14.1" />
    <rect x="819.64" y="3.54" width="13.11" height="15.03" />
    <rect x="989.35" y="3.54" width="13.99" height="13.99" />
    <path
      className="cls-3"
      transform="translate(-148.26 -251.52)"
      d="M1021.12,661.82h-18.88m18.88,2.35h-18.88m18.88-2.35v-7m3.94-3.94H991.22m-92.07-1.6,23.6,10.23m114.89-56.69V739M933,669.71l19.69-55.84M950.3,676,970,620.16m-47.22,39.32,15.74-43.27m-25.18-7-14.16,40.07"
    />
    <polyline
      className="cls-3"
      points="872.87 403.28 853.98 403.28 853.98 410.31"
    />
    <path
      className="cls-3"
      transform="translate(-148.26 -251.52)"
      d="M900.72,742.07h-3.93m11.8,0h-4.72m11.81,0h-3.94m11.8,0h-4.72m11.81,0H926.7m11.79,0h-4.72m11.81,0h-4.72m12.58,0h-4.72m11.81,0h-4.72m11.8,0h-3.94m11.81,0h-4.72m11,0h-3.15m23.61-12.58h18.88m-29.9,4h33.84M981.78,746h55.86m-55.86-7h55.86M933,669.71,950.3,676"
    />
    <path
      className="cls-3"
      transform="translate(-148.26 -251.52)"
      d="M991.22,650.85v82.59m33.84,0V650.85m-3.94,78.64V664.17m-18.88,0v65.32m-79.49-70L933,669.71"
    />
    <polyline
      className="cls-3"
      points="821.71 368.64 804.41 362.35 790.23 364.7 765.05 357.66"
    />
    <path
      className="cls-3"
      transform="translate(-148.26 -251.52)"
      d="M981.78,819.22h55.86M889.71,599h92.07M889.71,822.42h92.07m0-219.63h55.86"
    />
    <polyline
      className="cls-3"
      points="853.19 538.62 860.27 529.13 871.29 535.42"
    />
    <path
      className="cls-3"
      transform="translate(-148.26 -251.52)"
      d="m1013.2 783.84-7.08 9.38m-4.72-3.09 10.22 7.89"
    />
    <polyline
      className="cls-3"
      points="871.29 535.42 872.87 537.87 873.65 539.36 872.87 541.71 872.08 544.16 870.5 546.5 868.13 547.25 865.78 547.25 863.41 546.5"
    />
    <path
      className="cls-3"
      transform="translate(-148.26 -251.52)"
      d="m1003.8 786.94 4.72 3.19m-2.36-6.29 4.72 3.1"
    />
    <path
      className="cls-3"
      transform="translate(-148.26 -251.52)"
      d="M1037.64,746v73.21M981.78,599V822.42"
    />
    <polyline
      className="cls-3"
      points="847.68 516.56 843.74 518.15 842.17 522.1"
    />
    <line className="cls-3" x1="847.68" x2="876.01" y1="516.56" y2="516.56" />
    <polyline
      className="cls-3"
      points="881.52 522.1 879.94 518.15 876.01 516.56"
    />
    <line className="cls-3" x1="881.52" x2="881.52" y1="522.1" y2="557.48" />
    <polyline
      className="cls-3"
      points="876.01 563.02 879.94 561.42 881.52 557.48"
    />
    <line className="cls-3" x1="876.01" x2="847.68" y1="563.02" y2="563.02" />
    <polyline
      className="cls-3"
      points="842.17 557.48 843.74 561.42 847.68 563.02"
    />
    <line className="cls-3" x1="842.17" x2="842.17" y1="557.48" y2="522.1" />
    <polyline
      className="cls-3"
      points="857.13 533.93 850.04 528.38 842.17 525.19 833.52 523.7"
    />
    <polyline
      className="cls-3"
      points="932.66 505.58 932.66 514.21 988.53 511.02"
    />
    <path
      className="cls-3"
      transform="translate(-148.26 -251.52)"
      d="m1144.7 720.12h6.33m-6.33-11.12h6.33"
    />
    <polyline
      className="cls-3"
      points="1023.2 481.18 1022.4 489.06 1020.8 494.5 1017.6 499.29 1015.3 500.04"
    />
    <polyline
      className="cls-3"
      points="1023.2 444.2 1022.4 436.31 1020.8 430.77 1017.6 426.83 1015.3 425.33"
    />
    <polyline
      className="cls-3"
      points="1009.8 481.18 1009 489.06 1007.4 494.5 1005.1 499.29 1002.7 500.04"
    />
    <polyline
      className="cls-3"
      points="1009.8 444.2 1009 436.31 1007.4 430.77 1005.1 426.83 1002.7 425.33"
    />
    <path
      className="cls-3"
      transform="translate(-148.26 -251.52)"
      d="m1080.9 662.68v8.63m70 5.54v74.7m7.08-55.84v37m13.38-37v37m-20.38-55.86h12.59m-12.59 74.7h12.59m-5.59-55.84h13.38m-13.38 37h13.38"
    />
    <polyline
      className="cls-3"
      points="987.75 422.88 988.53 414.25 932.66 411.16"
    />
    <polyline
      className="cls-3"
      points="996.41 432.37 995.62 427.68 992.48 424.48 987.75 422.88"
    />
    <line className="cls-3" x1="996.41" x2="996.41" y1="432.37" y2="493.75" />
    <polyline
      className="cls-3"
      points="928.73 419.79 924.01 420.54 920.08 424.48 918.5 429.28"
    />
    <path
      className="cls-3"
      transform="translate(-148.26 -251.52)"
      d="m1066.8 680.79v67.67m10.24-77.15 59 3.09"
    />
    <polyline
      className="cls-3"
      points="988.53 511.02 987.75 502.38 992.48 501.64 995.62 497.69 996.41 493.75"
    />
    <polyline
      className="cls-3"
      points="918.5 496.95 920.08 501.64 924.01 504.73 928.73 506.33 987.75 502.38"
    />
    <circle className="cls-24" cx="702.91" cy="939.62" r="36.26" />
    <path
      transform="translate(-148.26 -251.52)"
      d="M843.32,1185.36v-4.07a25.71,25.71,0,0,0,4-.38,5.54,5.54,0,0,0,2.93-1.59,5.33,5.33,0,0,0,1.17-2.18,5.22,5.22,0,0,0,.23-1.22h5v30.43h-6.12v-21Z"
    />
    <circle className="cls-24" cx="812.77" cy="1488.5" r="36.26" />
    <path
      transform="translate(-148.26 -251.52)"
      d="m952.06 1749.3q1.28-3 6-6.41a45 45 0 0 0 5.32-4.22 6.23 6.23 0 0 0 1.86-4.35 4.75 4.75 0 0 0-1.07-3.19 4.34 4.34 0 0 0-6.74 0.75 10 10 0 0 0-0.67 3.72h-5.76a14.47 14.47 0 0 1 1.4-6.25q2.39-4.53 8.46-4.53a10.74 10.74 0 0 1 7.65 2.66 9.21 9.21 0 0 1 2.85 7 9.55 9.55 0 0 1-2 6 20.13 20.13 0 0 1-4.33 3.86l-2.39 1.69c-1.49 1.06-2.51 1.82-3.06 2.3a6.7 6.7 0 0 0-1.39 1.65h13.21v5.24h-20.74a14.38 14.38 0 0 1 1.4-5.92z"
    />
    <circle className="cls-24" cx="431.97" cy="1388.6" r="36.26" />
    <path
      transform="translate(-148.26 -251.52)"
      d="M575.72,1645.53a6.62,6.62,0,0,0,.57,2.93,3.91,3.91,0,0,0,3.85,2.14,4.24,4.24,0,0,0,3-1.17,4.35,4.35,0,0,0,1.27-3.37,3.81,3.81,0,0,0-2.35-3.9,12.12,12.12,0,0,0-4.23-.55v-4.15a11,11,0,0,0,3.94-.55,3.4,3.4,0,0,0,1.93-3.47,3.91,3.91,0,0,0-1-2.75,3.61,3.61,0,0,0-2.78-1.06,3.52,3.52,0,0,0-3,1.31,5.54,5.54,0,0,0-.92,3.48h-5.42a14.38,14.38,0,0,1,.75-4.18,9.51,9.51,0,0,1,2.24-3.2,7.86,7.86,0,0,1,2.71-1.59,11.82,11.82,0,0,1,3.84-.55,10.22,10.22,0,0,1,6.83,2.19,7.32,7.32,0,0,1,2.6,5.88,6.54,6.54,0,0,1-1.55,4.41,5.28,5.28,0,0,1-2,1.52,4.25,4.25,0,0,1,2.29,1.37,7.29,7.29,0,0,1,2.22,5.62,9.51,9.51,0,0,1-2.6,6.6q-2.6,2.84-7.68,2.84-6.27,0-8.72-4.1a12.13,12.13,0,0,1-1.42-5.7Z"
    />
    <circle className="cls-24" cx="318.51" cy="1021.8" r="36.26" />
    <path
      transform="translate(-148.26 -251.52)"
      d="M477.5,1281.94H474v6.59h-5.89v-6.59H456v-5.25l11.25-18.59H474v19.13h3.46Zm-9.35-4.71v-13.18l-7.64,13.18Z"
    />
    <circle className="cls-24" cx="451.5" cy="618.46" r="36.26" />
    <path
      transform="translate(-148.26 -251.52)"
      d="M595.26,876.36a5.7,5.7,0,0,0,1.35,3,3.78,3.78,0,0,0,2.89,1,3.93,3.93,0,0,0,3.34-1.54A6.38,6.38,0,0,0,604,875a6.81,6.81,0,0,0-1.07-3.88,3.76,3.76,0,0,0-3.35-1.59,6,6,0,0,0-1.86.27,3.7,3.7,0,0,0-2.08,1.85l-5.3-.25,2.11-16.59H609v5H596.69l-1.07,6.57a11.06,11.06,0,0,1,2.13-1.18,8.9,8.9,0,0,1,3.12-.48,9.38,9.38,0,0,1,6.49,2.51q2.77,2.5,2.77,7.29a11.48,11.48,0,0,1-2.66,7.43c-1.78,2.18-4.45,3.27-8,3.27a10.63,10.63,0,0,1-7-2.29,9,9,0,0,1-3.06-6.53Z"
    />
    <circle className="cls-24" cx="1002.7" cy="628.35" r="36.26" />
    <path
      transform="translate(-148.26 -251.52)"
      d="m1154.7 872.33a2.93 2.93 0 0 0-0.54-1.56 3.17 3.17 0 0 0-2.82-1.38c-1.88 0-3.21 1.05-4 3.16a19.64 19.64 0 0 0-0.89 5.14 6.38 6.38 0 0 1 2.49-1.86 9.1 9.1 0 0 1 9.65 2.07 9.48 9.48 0 0 1 2.5 6.78 11.41 11.41 0 0 1-2.45 7.25q-2.45 3.15-7.61 3.15a8.79 8.79 0 0 1-8.19-4.64 19 19 0 0 1-2-9.36 42.46 42.46 0 0 1 0.28-5.47 16.62 16.62 0 0 1 2-6.24 9.89 9.89 0 0 1 3.29-3.42 8.81 8.81 0 0 1 4.87-1.3 9.62 9.62 0 0 1 6.52 2.1 8 8 0 0 1 2.74 5.58zm-6.84 16.55a4.06 4.06 0 0 0 3.24 1.49 3.62 3.62 0 0 0 3-1.44 6.08 6.08 0 0 0 1.1-3.76 5.6 5.6 0 0 0-1.26-3.94 4 4 0 0 0-3.07-1.37 4.15 4.15 0 0 0-2.62 0.89 5.07 5.07 0 0 0-1.7 4.26 5.77 5.77 0 0 0 1.31 3.87z"
    />
  </StyledSvg>
);

export default SecondFloor;
