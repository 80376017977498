import React, { FC } from "react";
import styled from "styled-components";
import { pathStyles } from "../styles/pathStyles";

const StyledSvg = styled.svg`
  flex: 1;
`;

const StyledPath = styled.path`
  ${pathStyles}
`;

const SecondFloorSecondType: FC<{
  currentRoom: number;
  isRowActive: boolean;
  handlePathEnter: (num: number) => void;
  handlePathLeave: () => void;
}> = ({ currentRoom, isRowActive, handlePathEnter, handlePathLeave }) => (
  <StyledSvg
    version="1.1"
    viewBox="0 0 1280.3 1921.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style
        dangerouslySetInnerHTML={{
          __html: `.cls-1,.cls-10,.cls-11,.cls-12,.cls-13,.cls-14,.cls-15,.cls-16,.cls-17,.cls-18,.cls-19,.cls-2,.cls-20,.cls-21,.cls-3,.cls-4,.cls-5,.cls-6,.cls-7,.cls-8,.cls-9{fill:none;stroke-linecap:round;stroke-linejoin:round;}.cls-1,.cls-10,.cls-11,.cls-12,.cls-13,.cls-14,.cls-15,.cls-16,.cls-17,.cls-18,.cls-19,.cls-2,.cls-20,.cls-21,.cls-22,.cls-3,.cls-4,.cls-5,.cls-6,.cls-7,.cls-8,.cls-9{stroke:#000;}.cls-1{stroke-width:6.87px;}.cls-2{stroke-width:2.36px;}.cls-3{stroke-width:0.79px;}.cls-4{stroke-width:7.08px;}.cls-5{stroke-width:6.87px;}.cls-6{stroke-width:6.97px;}.cls-7{stroke-width:0.77px;}.cls-8{stroke-width:3.15px;}.cls-9{stroke-width:0.8px;}.cls-10{stroke-width:2.4px;}.cls-11{stroke-width:6.81px;}.cls-12{stroke-width:0.8px;}.cls-13{stroke-width:2.4px;}.cls-14{stroke-width:6.87px;}.cls-15{stroke-width:7.02px;}.cls-16{stroke-width:7.04px;}.cls-17{stroke-width:7.08px;}.cls-18{stroke-width:6.8px;}.cls-19{stroke-width:0.76px;}.cls-20{stroke-width:7.13px;}.cls-21{stroke-width:0.79px;}.cls-22{fill:#fff;stroke-miterlimit:10;stroke-width:2.41px;}`,
        }}
      />
    </defs>
    <StyledPath
      d="m364.98 17.59 183.89 0.98v31.43h182.56l1e-5 -31.43h185.71l1e-5 287.81 313.18 4.08v461.11l-1180.9 1e-5 0.54-461.11 313.19-0.01v-291.88"
      className={isRowActive && currentRoom === 5 ? "is-active" : ""}
      onMouseEnter={() => handlePathEnter(5)}
      onMouseLeave={handlePathLeave}
    />
    <StyledPath
      d="m49.97 793.63h313.22v158.18h21.97l0.03 166.83h-367.48l1.77-175.21 30.49-149.8"
      className={isRowActive && currentRoom === 4 ? "is-active" : ""}
      onMouseEnter={() => handlePathEnter(4)}
      onMouseLeave={handlePathLeave}
    />
    <StyledPath
      d="m385.19 793.63 275.41-22.04 158.21-0.02-0.03 22.06 0.8 325.01h-436.41l2.02-325.01"
      className={isRowActive && currentRoom === 1 ? "is-active" : ""}
      onMouseEnter={() => handlePathEnter(1)}
      onMouseLeave={handlePathLeave}
    />
    <StyledPath
      d="m550.8 1118.6 13.78 47.21-1.7 754.65-190.38 0.34-3.05-169.53-319.48-1e-4v-585.46h333.2l3.65-47.21z"
      className={isRowActive && currentRoom === 3 ? "is-active" : ""}
      onMouseEnter={() => handlePathEnter(3)}
      onMouseLeave={handlePathLeave}
    />
    <StyledPath
      d="m599.23 1118.6h167.61l0.57003 46.66 462.91 0.5501v587.82l-447.74-2.3599v169.19h-191.69l-3.47-754.65z"
      className={isRowActive && currentRoom === 2 ? "is-active" : ""}
      onMouseEnter={() => handlePathEnter(2)}
      onMouseLeave={handlePathLeave}
    />
    <polygon
      className="cls-1"
      points="1276.8 310.46 1276.8 1118.6 1276.8 310.46 1276.8 1118.6"
    />
    <polygon
      className="cls-1"
      points="1276.8 418.91 1276.8 1227.1 1276.8 418.91 1276.8 1227.1"
    />
    <path
      className="cls-2"
      transform="translate(-235.42 -251.52)"
      d="m1456.3 1054.6v306.87m-391.88-148.73v-167.62"
    />
    <line className="cls-3" x1="225.45" x2="363.16" y1="793.63" y2="914.83" />
    <polyline
      className="cls-3"
      points="363.16 793.63 225.45 914.83 49.97 793.63"
    />
    <polyline
      className="cls-3"
      points="49.97 914.83 225.45 793.63 225.45 914.83"
    />
    <path
      className="cls-2"
      transform="translate(-235.42 -251.52)"
      d="m1154.9 1045.2v158.18m50.36-158.18v158.18m-99.94 0v-158.18m-50.34 167.62v-167.62m200.66 79.49v-79.49m52.72 158.18 95.22-158.18m-118 158.18 40.91-158.18"
    />
    <path
      className="cls-4"
      transform="translate(-235.42 -251.52)"
      d="m620.61 1203.3v-158.18m-22 158.18v-158.18"
    />
    <path
      className="cls-4"
      transform="translate(-235.42 -251.52)"
      d="M285.39,562v631.91M239,562v631.91"
    />
    <polygon
      className="cls-5"
      points="1230.3 310.46 1230.3 1118.6 1230.3 310.46 1230.3 1118.6"
    />
    <polyline
      className="cls-4"
      points="1276.8 1388.6 1276.8 1317.7 1276.8 1388.6 1276.8 1317.7"
    />
    <polyline
      className="cls-6"
      points="1230.3 1751.4 1276.8 1751.4 1231.1 1751.4 1276.8 1751.4 1231.1 1751.4"
    />
    <polygon
      className="cls-6"
      points="1276 1751.4 1230.3 1751.4 1276 1751.4 1230.3 1751.4"
    />
    <polyline
      className="cls-6"
      points="1276.8 1751.4 1276.8 1139.2 1276.8 1706.4 1276.8 1139.2 1276.8 1706.4"
    />
    <rect className="cls-4" x="552.8" y="1118.6" width="46.43" height="47.21" />
    <polygon
      className="cls-6"
      points="1276 1751.4 1230.3 1751.4 1276 1751.4 1230.3 1751.4"
    />
    <polygon
      className="cls-6"
      points="1231.1 1751.4 1276.8 1751.4 1231.1 1751.4 1276.8 1751.4"
    />
    <polyline
      className="cls-7"
      points="1274.4 1753.7 1273.6 1752.1 1272 1751.4 1270.5 1752.1 1270.5 1753.7"
    />
    <polyline
      className="cls-7"
      points="1270.5 1753.7 1269.7 1752.1 1268.1 1751.4 1266.5 1752.1 1265.7 1753.7"
    />
    <polyline
      className="cls-7"
      points="1265.7 1753.7 1265 1752.1 1263.4 1751.4 1261.8 1752.1 1261 1753.7"
    />
    <polyline
      className="cls-7"
      points="1261 1753.7 1260.2 1752.1 1258.7 1751.4 1257.1 1752.1 1257.1 1753.7"
    />
    <polyline
      className="cls-7"
      points="1257.1 1753.7 1256.3 1752.1 1254.7 1751.4 1253.2 1752.1 1252.4 1753.7"
    />
    <polyline
      className="cls-7"
      points="1252.4 1753.7 1251.6 1752.1 1250 1751.4 1248.4 1752.1 1247.6 1753.7"
    />
    <polyline
      className="cls-7"
      points="1247.6 1753.7 1246.8 1752.1 1245.3 1751.4 1243.7 1752.1 1243.7 1753.7"
    />
    <polyline
      className="cls-7"
      points="1243.7 1753.7 1242.9 1752.1 1241.3 1751.4 1239.8 1752.1 1239 1753.7"
    />
    <polyline
      className="cls-7"
      points="1239 1753.7 1238.2 1752.1 1236.6 1751.4 1235 1752.1 1234.3 1753.7"
    />
    <polyline
      className="cls-7"
      points="1234.3 1753.7 1233.5 1752.1 1231.9 1751.4 1230.3 1752.1 1230.3 1753.7"
    />
    <path
      className="cls-3"
      transform="translate(-235.42 -251.52)"
      d="m1461 2003.6 1.58-0.79m-3.94 1.57 2.36 1.6"
    />
    <line className="cls-3" x1="1225.6" x2="1225.6" y1="1752.1" y2="1751.3" />
    <line className="cls-8" x1="782.58" x2="782.58" y1="1844.2" y2="1815.8" />
    <line className="cls-4" x1="782.58" x2="782.58" y1="1890.6" y2="1844.2" />
    <line className="cls-4" x1="782.58" x2="782.58" y1="1917.5" y2="1871" />
    <line className="cls-4" x1="782.58" x2="782.58" y1="1855.3" y2="1808.9" />
    <line className="cls-4" x1="782.58" x2="782.58" y1="1808.9" y2="1762.4" />
    <line className="cls-4" x1="782.58" x2="782.58" y1="1797.7" y2="1751.3" />
    <polyline
      className="cls-9"
      points="782.58 1890.6 590.89 1890.6 782.58 1890.6 590.89 1890.6"
    />
    <path
      className="cls-10"
      transform="translate(-235.42 -251.52)"
      d="M826.31,2162.56H1018m-191.69-9.43H1018"
    />
    <line className="cls-9" x1="590.89" x2="782.58" y1="1920.5" y2="1920.5" />
    <line className="cls-11" x1="369.45" x2="3.54" y1="1751.3" y2="1751.3" />
    <polyline
      className="cls-2"
      points="819.56 970.71 1091 970.71 1091 942.37"
    />
    <path
      className="cls-2"
      transform="translate(-235.42 -251.52)"
      d="M1064.42,1212.77H1317m-8.66,0,95.22,157.39M1317,1212.77,1465.74,1293m-180.19-80.26,40.91,157.39M1055,1361.5H1456.3"
    />
    <line className="cls-8" x1="369.45" x2="369.45" y1="1844.2" y2="1815.8" />
    <line className="cls-3" x1="49.97" x2="363.16" y1="914.83" y2="914.83" />
    <rect className="cls-2" x="385.19" y="1118.6" width="9.44" height="18.89" />
    <rect className="cls-2" x="543.36" y="1118.6" width="9.45" height="18.89" />
    <path
      className="cls-4"
      transform="translate(-235.42 -251.52)"
      d="m285.39 2002.8v-585.46m732.61 585.46h447.74"
    />
    <rect className="cls-2" x="599.23" y="1147" width="9.44" height="18.89" />
    <path
      className="cls-2"
      transform="translate(-235.42 -251.52)"
      d="m1317 1208h148.72m-148.72 4.72v-9.44"
    />
    <line className="cls-2" x1="1081.6" x2="1230.3" y1="951.81" y2="871.55" />
    <line className="cls-2" x1="829" x2="969.86" y1="951.81" y2="951.81" />
    <path
      className="cls-2"
      transform="translate(-235.42 -251.52)"
      d="m1255.6 1124.6v78.69m-191.22-9.45h262"
    />
    <path
      className="cls-2"
      transform="translate(-235.42 -251.52)"
      d="m1205.3 1203.3h111.72m-262 166.83v-157.39m50.36 157.39v-157.39m49.58 157.39v-157.39m50.36 157.39v-157.39m50.36 157.39v-157.39"
    />
    <line className="cls-3" x1="543.36" x2="552.8" y1="1118.6" y2="1118.6" />
    <path
      className="cls-8"
      transform="translate(-235.42 -251.52)"
      d="M244.47,1208.83V1356m3.93-147.15V1356m5.51-147.15V1356M239,1208.83V1356"
    />
    <polygon
      className="cls-8"
      points="18.49 1118.6 18.49 1104.5 3.54 1104.5 3.54 1118.6 18.49 1118.6 18.49 1104.5 3.54 1104.5 3.54 1118.6"
    />
    <polyline
      className="cls-4"
      points="49.97 942.37 3.54 942.37 49.97 942.37 3.54 942.37"
    />
    <polygon
      className="cls-8"
      points="18.49 957.32 18.49 942.37 3.54 942.37 3.54 957.32 18.49 957.32 18.49 942.37 3.54 942.37 3.54 957.32"
    />
    <line className="cls-4" x1="49.97" x2="3.54" y1="1118.6" y2="1118.6" />
    <path
      className="cls-4"
      transform="translate(-235.42 -251.52)"
      d="M620.61,1417.37v-47.21M285.39,1193.88H239"
    />
    <polyline
      className="cls-4"
      points="552.8 1165.8 552.8 1118.6 552.8 1141.5"
    />
    <line className="cls-4" x1="363.16" x2="363.94" y1="1118.6" y2="1118.6" />
    <line className="cls-4" x1="385.19" x2="77.51" y1="1118.6" y2="1118.6" />
    <line className="cls-4" x1="330.36" x2="22.68" y1="1118.6" y2="1118.6" />
    <line className="cls-4" x1="385.19" x2="77.51" y1="1165.8" y2="1165.8" />
    <line className="cls-4" x1="313.66" x2="5.98" y1="1165.8" y2="1165.8" />
    <rect className="cls-2" x="757.4" y="1147" width="9.45" height="18.89" />
    <polyline
      className="cls-4"
      points="599.23 1118.6 599.23 1165.8 599.23 1147"
    />
    <line className="cls-4" x1="766.84" x2="766.84" y1="1165.8" y2="1118.6" />
    <line className="cls-3" x1="608.67" x2="599.23" y1="1165.8" y2="1165.8" />
    <path
      className="cls-4"
      transform="translate(-235.42 -251.52)"
      d="m598.58 1370.2h22m-22-166.83h22"
    />
    <rect
      className="cls-2"
      x="369.45"
      y="877.85"
      width="9.44"
      height="174.69"
    />
    <path
      className="cls-4"
      transform="translate(-235.42 -251.52)"
      d="m1002.3 1370.2h463.48m0 47.21h-463.48"
    />
    <line className="cls-12" x1="562.88" x2="369.45" y1="1920.5" y2="1920.5" />
    <path
      className="cls-13"
      transform="translate(-235.42 -251.52)"
      d="M798.29,2153.13H604.87m193.42,9.43H604.87"
    />
    <polyline
      className="cls-12"
      points="562.88 1890.6 369.45 1890.6 562.88 1890.6 369.45 1890.6"
    />
    <path
      className="cls-4"
      transform="translate(-235.42 -251.52)"
      d="m604.87 2142.1v-46.43"
    />
    <path
      className="cls-14"
      transform="translate(-235.42 -251.52)"
      d="m604.87 2169.2v-43.63"
    />
    <path
      className="cls-4"
      transform="translate(-235.42 -251.52)"
      d="m604.87 2106.5v-46.43"
    />
    <path
      className="cls-4"
      transform="translate(-235.42 -251.52)"
      d="m604.87 2063v-46.4"
    />
    <path
      className="cls-4"
      transform="translate(-235.42 -251.52)"
      d="m604.87 2049.3v-46.43"
    />
    <path
      className="cls-4"
      transform="translate(-235.42 -251.52)"
      d="m822.84 2002.8v-585.46m-22.84 585.46v-585.46m-561-47.21v632.67"
    />
    <polyline
      className="cls-6"
      points="1230.3 1751.4 1230.3 1139.2 1230.3 1706.4 1230.3 1139.2 1230.3 1706.4"
    />
    <path
      className="cls-3"
      transform="translate(-235.42 -251.52)"
      d="M451.72,698.71V679.83m-2.35,18.88V679.83m2.35,18.88h7m3.94,3.93V668.81m1.6-92.08-10.23,23.61m56.69,114.89H374.56m69.27-104.66,55.84,19.68m-62.13-2.36,55.84,19.67m-39.32-47.22,43.27,15.74m7-25.18-40.07-14.17"
    />
    <polyline
      className="cls-3"
      points="223.33 447.2 223.33 428.31 216.3 428.31"
    />
    <path
      className="cls-3"
      transform="translate(-235.42 -251.52)"
      d="m371.47 578.31v-3.93m0 11.8v-4.73m0 11.81v-3.93m0 11.8v-4.72m0 11.8v-3.93m0 11.8v-4.72m0 11.81v-4.73m0 12.59v-4.72m0 11.81v-4.72m0 11.79v-3.93m0 11.81v-4.72m0 11v-3.16m12.58 23.62v18.88m-3.95-29.9v33.83m-12.57-43.27v55.86m7-55.86v55.86m69.27-104.66-6.29 17.32"
    />
    <line className="cls-3" x1="136.05" x2="136.05" y1="319.71" y2="315.78" />
    <path
      className="cls-3"
      transform="translate(-235.42 -251.52)"
      d="M462.69,668.81H380.1m0,33.83h82.59m-78.64-3.93h65.32m0-18.88H384.05m70-79.49-10.23,10.23"
    />
    <polyline
      className="cls-3"
      points="257.97 396.04 264.25 378.74 261.91 364.56 268.94 339.38"
    />
    <path
      className="cls-3"
      transform="translate(-235.42 -251.52)"
      d="m294.32 659.37v55.86m220.27-147.94v92.08m-3.84 0v55.86"
    />
    <polyline
      className="cls-3"
      points="87.99 427.52 97.48 434.6 91.19 445.62"
    />
    <path
      className="cls-3"
      transform="translate(-235.42 -251.52)"
      d="m329.7 690.84-9.38-7.08m3.09-4.72-7.89 10.22"
    />
    <polyline
      className="cls-3"
      points="91.19 445.62 88.74 447.2 87.25 447.98 84.9 447.2 82.45 446.41 80.11 444.83 79.36 442.46 79.36 440.11 80.11 437.74"
    />
    <path
      className="cls-3"
      transform="translate(-235.42 -251.52)"
      d="m326.61 681.39-3.2 4.72m6.29-2.35-3.09 4.72"
    />
    <polyline
      className="cls-3"
      points="55.7 407.85 55.7 315.78 279.17 315.78"
    />
    <path
      className="cls-3"
      transform="translate(-235.42 -251.52)"
      d="M367.53,715.23H294.32m220.27-55.86H291.12"
    />
    <polyline
      className="cls-3"
      points="110.05 422.01 108.45 418.07 104.51 416.5"
    />
    <line className="cls-3" x1="110.05" x2="110.05" y1="422.01" y2="450.34" />
    <polyline
      className="cls-3"
      points="104.51 455.85 108.45 454.27 110.05 450.34"
    />
    <line className="cls-3" x1="104.51" x2="69.13" y1="455.85" y2="455.85" />
    <polyline
      className="cls-3"
      points="63.59 450.34 65.19 454.27 69.13 455.85"
    />
    <line className="cls-3" x1="63.59" x2="63.59" y1="450.34" y2="422.01" />
    <polyline
      className="cls-3"
      points="69.13 416.5 65.19 418.07 63.59 422.01"
    />
    <line className="cls-3" x1="69.13" x2="104.51" y1="416.5" y2="416.5" />
    <polyline
      className="cls-3"
      points="92.68 431.46 98.22 424.37 101.42 416.5 102.91 407.85"
    />
    <polyline
      className="cls-3"
      points="121.03 506.99 112.4 506.99 115.59 562.86"
    />
    <path
      className="cls-3"
      transform="translate(-235.42 -251.52)"
      d="m393.42 822.25v6.29m11.09-6.29v6.29"
    />
    <polyline
      className="cls-3"
      points="145.43 597.49 137.54 596.7 132.11 595.13 127.32 591.98 126.57 589.62"
    />
    <polyline
      className="cls-3"
      points="182.41 597.49 190.3 596.7 195.84 595.13 199.78 591.98 201.27 589.62"
    />
    <polyline
      className="cls-3"
      points="145.43 584.11 137.54 583.33 132.11 581.75 127.32 579.39 126.57 577.03"
    />
    <polyline
      className="cls-3"
      points="182.41 584.11 190.3 583.33 195.84 581.75 199.78 579.39 201.27 577.03"
    />
    <path
      className="cls-3"
      transform="translate(-235.42 -251.52)"
      d="M450.86,758.51h-8.63m-5.54,70H362m55.84,7.09h-37m37,13.37h-37m55.84-20.46v12.6M362,828.54v12.6m55.84-5.51V849m-37-13.37V849"
    />
    <polyline
      className="cls-3"
      points="203.72 562.08 212.36 562.86 215.45 506.99"
    />
    <polyline
      className="cls-3"
      points="194.24 570.74 198.93 569.95 202.13 566.81 203.72 562.08"
    />
    <line className="cls-3" x1="194.24" x2="132.86" y1="570.74" y2="570.74" />
    <polyline
      className="cls-3"
      points="206.81 503.06 206.07 498.34 202.13 494.4 197.33 492.83"
    />
    <path
      className="cls-3"
      transform="translate(-235.42 -251.52)"
      d="m432.75 744.34h-67.67m77.15 10.23-3.09 59"
    />
    <polyline
      className="cls-3"
      points="115.59 562.86 124.22 562.08 124.97 566.81 128.91 569.95 132.86 570.74"
    />
    <polyline
      className="cls-3"
      points="129.66 492.83 124.97 494.4 121.88 498.34 120.28 503.06 124.22 562.08"
    />
    <polyline
      className="cls-3"
      points="279.17 315.78 55.7 315.78 55.7 389.75"
    />
    <polyline
      className="cls-3"
      points="723.56 1.2 724.35 2.8 727.5 2.8 728.28 1.2"
    />
    <polyline
      className="cls-3"
      points="718.84 1.2 719.63 2.8 722.78 2.8 723.56 1.2"
    />
    <polyline
      className="cls-3"
      points="714.91 1.2 715.7 2.8 718.84 2.8 718.84 1.2"
    />
    <polyline
      className="cls-3"
      points="710.18 1.2 710.97 2.8 714.12 2.8 714.91 1.2"
    />
    <polyline
      className="cls-3"
      points="705.46 1.2 706.25 2.8 709.4 2.8 710.18 1.2"
    />
    <polyline
      className="cls-3"
      points="701.53 1.2 702.31 2.8 705.46 2.8 705.46 1.2"
    />
    <polyline
      className="cls-3"
      points="696.8 1.2 697.59 2.8 700.74 2.8 701.53 1.2"
    />
    <polyline
      className="cls-3"
      points="692.08 1.2 692.87 2.8 696.02 2.8 696.8 1.2"
    />
    <polyline
      className="cls-3"
      points="688.15 1.2 688.94 2.8 692.08 2.8 692.08 1.2"
    />
    <polyline
      className="cls-3"
      points="683.43 1.2 684.22 2.8 687.36 2.8 688.15 1.2"
    />
    <polyline
      className="cls-3"
      points="678.71 1.2 679.5 2.8 682.64 2.8 683.43 1.2"
    />
    <polyline
      className="cls-3"
      points="674.78 1.2 675.57 2.8 678.71 2.8 678.71 1.2"
    />
    <polyline
      className="cls-3"
      points="670.06 1.2 670.85 2.8 673.99 2.8 674.78 1.2"
    />
    <polyline
      className="cls-3"
      points="665.33 1.2 666.12 2.8 669.27 2.8 670.06 1.2"
    />
    <polyline
      className="cls-3"
      points="661.39 1.2 662.18 2.8 665.33 2.8 665.33 1.2"
    />
    <polyline
      className="cls-3"
      points="656.67 1.2 657.46 2.8 660.6 2.8 661.39 1.2"
    />
    <polyline
      className="cls-3"
      points="651.95 1.2 652.74 2.8 655.88 2.8 656.67 1.2"
    />
    <polyline
      className="cls-3"
      points="648.02 1.2 648.81 2.8 651.95 2.8 651.95 1.2"
    />
    <polyline
      className="cls-3"
      points="643.3 1.2 644.09 2.8 647.23 2.8 648.02 1.2"
    />
    <polyline
      className="cls-3"
      points="638.58 1.2 639.37 2.8 642.51 2.8 643.3 1.2"
    />
    <polyline
      className="cls-3"
      points="634.64 1.2 635.42 2.8 638.58 2.8 638.58 1.2"
    />
    <polyline
      className="cls-3"
      points="629.92 1.2 630.71 2.8 633.86 2.8 634.64 1.2"
    />
    <polyline
      className="cls-3"
      points="625.2 1.2 625.99 2.8 629.13 2.8 629.92 1.2"
    />
    <polyline
      className="cls-3"
      points="621.27 1.2 622.05 2.8 625.2 2.8 625.2 1.2"
    />
    <polyline
      className="cls-3"
      points="616.54 1.2 617.33 2.8 620.48 2.8 621.27 1.2"
    />
    <polyline
      className="cls-3"
      points="611.82 1.2 612.61 2.8 615.75 2.8 616.54 1.2"
    />
    <polyline
      className="cls-3"
      points="607.88 1.2 608.67 2.8 611.82 2.8 611.82 1.2"
    />
    <polyline
      className="cls-3"
      points="603.16 1.2 603.95 2.8 607.1 2.8 607.88 1.2"
    />
    <polyline
      className="cls-3"
      points="598.44 1.2 599.23 2.8 602.38 2.8 603.16 1.2"
    />
    <polyline
      className="cls-3"
      points="594.51 1.2 595.3 2.8 598.44 2.8 598.44 1.2"
    />
    <polyline
      className="cls-3"
      points="589.79 1.2 590.58 2.8 593.72 2.8 594.51 1.2"
    />
    <polyline
      className="cls-3"
      points="585.07 1.2 585.86 2.8 589 2.8 589.79 1.2"
    />
    <polyline
      className="cls-3"
      points="581.14 1.2 581.14 2.8 585.07 2.8 585.07 1.2"
    />
    <polyline
      className="cls-3"
      points="576.41 1.2 577.19 2.8 580.35 2.8 581.14 1.2"
    />
    <polyline
      className="cls-3"
      points="571.7 1.2 572.47 2.8 575.63 2.8 576.41 1.2"
    />
    <polyline
      className="cls-3"
      points="567.75 1.2 567.75 2.8 570.91 2.8 571.7 1.2"
    />
    <polyline
      className="cls-3"
      points="563.03 1.2 563.82 2.8 566.96 2.8 567.75 1.2"
    />
    <polyline
      className="cls-3"
      points="558.31 1.2 559.1 2.8 562.24 2.8 563.03 1.2"
    />
    <polyline
      className="cls-3"
      points="554.38 1.2 554.38 2.8 557.52 2.8 558.31 1.2"
    />
    <path
      className="cls-8"
      transform="translate(-235.42 -251.52)"
      d="M1137.61,264.54H981m156.6-3.94H981"
    />
    <polygon
      className="cls-8"
      points="731.43 3.54 745.59 3.54 745.59 18.57 731.43 18.57 731.43 3.54 745.59 3.54 745.59 18.57 731.43 18.57"
    />
    <line className="cls-8" x1="902.19" x2="745.59" y1="18.57" y2="18.57" />
    <polyline
      className="cls-8"
      points="745.59 3.54 917.14 3.54 917.14 18.57 902.19 18.57 902.19 3.54 917.14 3.54 917.14 18.57 902.19 18.57 902.19 3.54"
    />
    <line className="cls-4" x1="731.43" x2="731.43" y1="50.01" y2="3.54" />
    <line className="cls-15" x1="963.56" x2="963.56" y1="3.54" y2="305.33" />
    <polyline className="cls-4" points="917.14 50.01 917.14 3.54 963.56 3.54" />
    <line className="cls-16" x1="917.14" x2="917.14" y1="3.54" y2="306.38" />
    <path
      className="cls-4"
      transform="translate(-235.42 -251.52)"
      d="m598.58 301.52v-46.46m185.7 46.46v-46.46"
    />
    <path
      className="cls-17"
      transform="translate(-235.42 -251.52)"
      d="M598.58,255.06V562M552.15,255.06V562"
    />
    <line className="cls-4" x1="316.73" x2="363.16" y1="3.54" y2="3.54" />
    <polygon
      className="cls-8"
      points="378.1 3.54 363.16 3.54 363.16 18.57 378.1 18.57 378.1 3.54 363.16 3.54 363.16 18.57 378.1 18.57"
    />
    <polygon
      className="cls-8"
      points="548.87 3.54 533.92 3.54 533.92 18.57 548.87 18.57 548.87 3.54 533.92 3.54 533.92 18.57 548.87 18.57"
    />
    <path
      className="cls-8"
      transform="translate(-235.42 -251.52)"
      d="m613.52 255.06h155.81m-155.81 15h155.81m-155.81-5.54h155.81m-155.81-3.92h155.81"
    />
    <path
      className="cls-4"
      transform="translate(-235.42 -251.52)"
      d="M966.85,255.06H784.28m182.57,46.46H784.28"
    />
    <line className="cls-3" x1="1225.6" x2="1225.6" y1="309.71" y2="310.46" />
    <line className="cls-3" x1="619.69" x2="629.13" y1="771.57" y2="771.57" />
    <path
      className="cls-4"
      transform="translate(-235.42 -251.52)"
      d="m696.94 1045.2h0.79m185.83 0v-22.06"
    />
    <path
      className="cls-4"
      transform="translate(-235.42 -251.52)"
      d="m696.94 1045.2h-76.33m433.59-22.06h411.54m-411.54 22.06h411.54"
    />
    <rect className="cls-2" x="809.34" y="771.59" width="9.44" height="18.1" />
    <rect className="cls-2" x="651.16" y="771.59" width="9.44" height="18.1" />
    <polyline
      className="cls-3"
      points="809.34 771.57 807.76 750.37 803.04 729.9 795.96 709.44 785.73 691.33 773.14 674.06 758.19 659.04 740.87 646.46 721.99 636.23 702.31 629.2 681.85 624.51 660.6 622.91"
    />
    <line className="cls-3" x1="660.6" x2="651.16" y1="771.57" y2="771.57" />
    <line className="cls-4" x1="818.78" x2="818.78" y1="771.57" y2="793.63" />
    <path
      className="cls-2"
      transform="translate(-235.42 -251.52)"
      d="m896 874.43v148.66m7.88-148.66v148.66"
    />
    <path
      className="cls-18"
      transform="translate(-235.42 -251.52)"
      d="M598.58,562H239m815.27,483.18h.75"
    />
    <path
      className="cls-19"
      transform="translate(-235.42 -251.52)"
      d="m1511.6 562h-358.47m241.8 436.71v24.41"
    />
    <line className="cls-2" x1="829" x2="1220.9" y1="803.12" y2="803.12" />
    <path
      className="cls-19"
      transform="translate(-235.42 -251.52)"
      d="m1394.9 998.68z"
    />
    <rect
      className="cls-19"
      x="1159.5"
      y="647.23"
      width="70.82"
      height="99.94"
    />
    <path
      className="cls-4"
      transform="translate(-235.42 -251.52)"
      d="M598.58,1045.15H285.39m411.55-22.06H285.39"
    />
    <path
      className="cls-20"
      transform="translate(-235.42 -251.52)"
      d="M766.19,1045.15H453m430.37,0H567.91m315.65-22.06h-414"
    />
    <rect
      className="cls-21"
      x="858.13"
      y="1297.3"
      width="372.2"
      height="299.81"
    />
    <polygon className="cls-3" points="1128 1325.6 1128 1297.3 1155.6 1325.6" />
    <polygon className="cls-3" points="1128 1568.8 1128 1597.1 1155.6 1568.8" />
    <rect
      className="cls-3"
      x="1155.6"
      y="1475.9"
      width="55.87"
      height="92.86"
    />
    <rect
      className="cls-3"
      x="1155.6"
      y="1325.6"
      width="55.87"
      height="94.43"
    />
    <rect
      className="cls-21"
      x="858.13"
      y="341.92"
      width="372.2"
      height="299.81"
    />
    <polygon className="cls-3" points="1128 370.26 1128 341.92 1155.6 370.26" />
    <polygon className="cls-3" points="1128 613.41 1128 641.74 1155.6 613.41" />
    <rect
      className="cls-3"
      x="1155.6"
      y="520.56"
      width="55.87"
      height="92.86"
    />
    <rect
      className="cls-3"
      x="1155.6"
      y="370.26"
      width="55.87"
      height="94.43"
    />
    <path
      className="cls-3"
      transform="translate(-235.42 -251.52)"
      d="M299.27,1444.9v100.73m133,0V1444.9"
    />
    <polyline
      className="cls-3"
      points="53.62 1226.3 53.62 1169.8 206.28 1169.8 206.28 1318.5 53.62 1318.5 53.62 1224.9 53.62 1318.5"
    />
    <polyline
      className="cls-3"
      points="63.85 1294.1 67.78 1302 74.87 1306.7 83.52 1308.3 182.67 1308.3 188.18 1307.5 192.9 1304.4 196.05 1299.6 196.84 1294.1"
    />
    <polyline
      className="cls-3"
      points="66.99 1318.5 206.28 1318.5 53.62 1318.5"
    />
    <polyline
      className="cls-3"
      points="83.52 1179.2 74.87 1180.8 67.78 1185.5 63.85 1193.4"
    />
    <polygon
      className="cls-3"
      points="102.41 1207.6 100.83 1204.4 97.69 1202.8 94.54 1204.4 92.96 1207.6 94.54 1210.7 97.69 1212.3 100.83 1210.7"
    />
    <line className="cls-3" x1="182.67" x2="83.52" y1="1179.2" y2="1179.2" />
    <polyline
      className="cls-3"
      points="196.84 1193.4 196.05 1187.9 192.9 1183.2 188.18 1180 182.67 1179.2"
    />
    <polyline
      className="cls-3"
      points="94.04 1368.1 82.23 1364.9 70.43 1364.2"
    />
    <polyline
      className="cls-3"
      points="127.87 1376.8 132.59 1376 144.4 1374.4"
    />
    <line className="cls-3" x1="132.59" x2="127.87" y1="1427.1" y2="1425.5" />
    <polyline
      className="cls-3"
      points="144.4 1374.4 157.78 1376 169.58 1381.5 177.45 1390.9 180.59 1401.1 177.45 1411.4 169.58 1420.8 157.78 1426.3 144.4 1427.9 132.59 1427.1"
    />
    <path
      className="cls-3"
      transform="translate(-235.42 -251.52)"
      d="m349.13 1646.4v12.58m-15.74-30.68 7.87-0.78"
    />
    <polygon
      className="cls-3"
      points="85.39 1401.1 83.81 1398.8 81.45 1397.2 79.09 1398.8 77.51 1401.1 79.09 1403.5 81.45 1405.1 83.81 1403.5"
    />
    <polyline
      className="cls-3"
      points="94.04 1434.2 96.4 1432.6 97.97 1428.7"
    />
    <line className="cls-3" x1="105.85" x2="97.97" y1="1426.3" y2="1425.5" />
    <polyline
      className="cls-3"
      points="64.92 1432.6 66.49 1437.3 70.43 1438.9 82.23 1437.3 94.04 1434.2"
    />
    <path
      className="cls-3"
      transform="translate(-235.42 -251.52)"
      d="m333.39 1680.2v-55.08m-33.05-3.93v63"
    />
    <polyline
      className="cls-3"
      points="127.87 1376.8 120.8 1380.7 115.29 1387 113.71 1394.8"
    />
    <line className="cls-3" x1="131.81" x2="105.85" y1="1432.6" y2="1426.3" />
    <polyline
      className="cls-3"
      points="113.71 1407.4 115.29 1415.3 120.8 1422.4 127.87 1425.5"
    />
    <polyline
      className="cls-3"
      points="114.5 1388.6 123.15 1389.3 130.24 1392.5 136.53 1397.2 137.31 1401.1 136.53 1405.9 130.24 1410.6 123.15 1412.9 114.5 1413.7"
    />
    <line className="cls-3" x1="131.81" x2="105.85" y1="1370.4" y2="1376" />
    <polyline
      className="cls-3"
      points="144.4 1368.9 159.35 1371.2 172.73 1377.5 182.17 1387.8"
    />
    <polyline
      className="cls-3"
      points="186.1 1401.1 185.31 1394 182.17 1387.8"
    />
    <polyline
      className="cls-3"
      points="182.17 1414.5 185.31 1408.2 186.1 1401.1"
    />
    <polyline
      className="cls-3"
      points="182.17 1414.5 172.73 1425.5 159.35 1431.8 144.4 1433.4 131.81 1432.6"
    />
    <polyline
      className="cls-3"
      points="70.43 1364.2 66.49 1365.7 64.92 1369.7"
    />
    <polyline
      className="cls-3"
      points="97.97 1373.6 96.4 1370.4 94.04 1368.1"
    />
    <path
      className="cls-3"
      transform="translate(-235.42 -251.52)"
      d="m796.09 1926.5h-12.59m-66.89-279.34h79.8m-416.59-26.76-12.6 1.57"
    />
    <polygon
      className="cls-3"
      points="520.54 1551.4 526.84 1552.2 533.13 1554.6 538.64 1558.5 543.36 1564 547.3 1571.1 551.23 1578.2 552.8 1586.8 554.38 1595.5 554.38 1604.9 552.8 1613.6 550.45 1622.3 547.3 1629.3 542.57 1635.6 537.85 1640.4 532.34 1644.3 526.05 1645.9 520.54 1645.9 514.24 1644.3 508.74 1641.1 503.23 1636.4 498.5 1630.9 494.57 1623 492.21 1615.2 490.64 1606.5 489.85 1597.1 490.64 1588.4 492.21 1579.8 495.36 1571.9 499.29 1564.8 504.01 1559.3 508.74 1555.4 515.03 1552.2"
    />
    <polygon
      className="cls-3"
      points="523.69 1547.5 530.77 1548.3 537.85 1551.4 544.15 1556.2 550.45 1562.4 554.38 1570.3 558.31 1579 559.89 1588.4 561.45 1598.6 560.68 1608.1 559.1 1617.5 555.96 1626.2 552.02 1634.1 546.5 1641.1 540.22 1645.9 533.13 1649.8 526.05 1650.6 518.97 1649.8 511.1 1646.6 504.8 1641.9 499.29 1635.6 494.57 1627.8 491.43 1619.1 489.06 1609.7 488.27 1600.2 489.06 1590 490.64 1580.6 493.78 1571.9 497.72 1564 503.23 1557.7 508.74 1552.2 515.82 1549.1"
    />
    <path
      className="cls-3"
      transform="translate(-235.42 -251.52)"
      d="m778.78 1865.1-22-30.69m22 4.72-9.45 13.37"
    />
    <polygon
      className="cls-3"
      points="544.15 1604.2 541.78 1604.2 541.78 1603.4 541 1602.6 541 1601.8 540.22 1601 540.22 1600.2 541 1599.4 541 1598.6 541.78 1597.9 542.57 1597.1 546.5 1597.1 547.3 1597.9 548.08 1598.6 548.08 1602.6 547.3 1602.6 547.3 1603.4 546.5 1604.2"
    />
    <path
      className="cls-3"
      transform="translate(-235.42 -251.52)"
      d="m792.94 1865.1h-14.16m-62.17 61.37h79.84"
    />
    <polygon
      className="cls-3"
      points="544.15 1474.3 543.36 1473.5 541.78 1473.5 541.78 1472.7 541 1472 541 1471.2 540.22 1471.2 540.22 1470.4 541 1469.6 541 1468 541.78 1467.2 542.57 1466.5 545.72 1466.5 546.5 1467.2 547.3 1467.2 548.08 1468 548.08 1472 547.3 1472.7 546.5 1473.5 545.72 1473.5 544.93 1474.3"
    />
    <line className="cls-3" x1="543.36" x2="521.32" y1="1483" y2="1452.3" />
    <polyline
      className="cls-3"
      points="558.31 1457 543.36 1457 533.92 1470.4"
    />
    <polygon
      className="cls-3"
      points="520.54 1517.6 526.84 1516.8 533.13 1514.4 538.64 1510.5 543.36 1505 547.3 1498.7 551.23 1490.8 552.8 1482.2 554.38 1473.5 554.38 1464.1 552.8 1455.4 550.45 1447.6 547.3 1439.7 542.57 1433.4 537.85 1428.7 532.34 1424.7 526.05 1423.2 520.54 1423.2 514.24 1424.7 508.74 1427.9 503.23 1432.6 498.5 1438.9 494.57 1446 492.21 1453.9 490.64 1462.5 489.85 1472 490.64 1480.6 492.21 1489.3 495.36 1497.1 499.29 1504.2 504.01 1509.7 508.74 1513.7 515.03 1516.8"
    />
    <path
      className="cls-3"
      transform="translate(-235.42 -251.52)"
      d="m793.73 1839.2h-15m14.16-104.66h-14.11"
    />
    <polygon
      className="cls-3"
      points="523.69 1416.9 530.77 1417.7 537.85 1420.8 544.15 1425.5 550.45 1431.8 554.38 1439.7 558.31 1448.4 559.89 1457.8 561.45 1468 560.68 1477.5 559.1 1486.9 555.96 1495.6 552.02 1504.2 546.5 1510.5 540.22 1515.2 533.13 1519.2 526.05 1520 518.97 1519.2 511.1 1516 504.8 1511.3 499.29 1505 494.57 1497.1 491.43 1488.5 489.06 1479 488.27 1469.6 489.06 1459.4 490.64 1449.9 493.78 1441.3 497.72 1433.4 503.23 1427.1 508.74 1421.6 515.82 1418.4"
    />
    <line className="cls-3" x1="481.2" x2="481.2" y1="1395.6" y2="1675" />
    <polyline
      className="cls-3"
      points="608.67 1314.6 629.92 1313 650.38 1308.3 670.06 1300.4 688.94 1291 706.25 1277.6 721.2 1262.6 733.79 1246.1 744.02 1227.2 751.1 1207.6 755.82 1186.3 757.4 1165.8"
    />
    <path
      className="cls-2"
      transform="translate(-235.42 -251.52)"
      d="m852 1565.3v-147.93m-7.87 148.72v-148.72"
    />
    <polyline
      className="cls-3"
      points="543.36 1118.6 541.78 1098.2 537.06 1076.9 529.98 1057.3 519.75 1038.4 507.17 1021.8 492.21 1006.9 474.9 994.31 456.81 984.08 436.34 976.2 415.88 971.48 394.63 970.71"
    />
    <path
      className="cls-2"
      transform="translate(-235.42 -251.52)"
      d="m630.05 1222.2v147.94m7.86-147.94v147.94"
    />
    <line className="cls-3" x1="118.43" x2="124.73" y1="1730.8" y2="1728.5" />
    <polyline
      className="cls-3"
      points="95.61 1728.5 107.41 1731.6 118.43 1730.8"
    />
    <polyline className="cls-3" points="79.09 1713.5 86.16 1723 95.61 1728.5" />
    <polyline className="cls-3" points="68.86 1678.9 72.01 1697 79.09 1713.5" />
    <polyline className="cls-3" points="70.43 1640.4 68.07 1660 68.86 1678.9" />
    <line className="cls-3" x1="72.79" x2="70.43" y1="1628.6" y2="1640.4" />
    <polygon
      className="cls-3"
      points="206.56 1713.5 213.65 1697 216.79 1678.9 217.58 1660 215.23 1640.4 208.93 1616.8 201.05 1593.9 190.04 1572.7 177.45 1552.2 164.85 1537.3 150.7 1523.9 135.74 1513.7 118.43 1507.4 107.41 1506.6 95.61 1509 86.16 1515.2 79.09 1524.7 72.01 1541.2 68.86 1558.5 68.07 1578.2 70.43 1597.1 76.73 1620.7 84.6 1643.5 95.61 1665.5 108.2 1686 120.8 1701 134.96 1714.3 149.91 1723.8 166.44 1730.8 178.24 1731.6 189.26 1728.5 199.49 1723"
    />
    <polyline
      className="cls-3"
      points="235.68 1713.5 234.11 1681.3 230.17 1649 223.09 1617.5 214.44 1591.6 203.42 1566.4 189.26 1542.8 173.52 1522.3 155.42 1504.2 134.17 1488.5 116.85 1479.8 97.18 1474.3 77.51 1472"
    />
    <polyline
      className="cls-3"
      points="160.92 1728.5 149.12 1726.1 136.53 1726.1 124.73 1728.5"
    />
    <line className="cls-3" x1="235.68" x2="235.68" y1="1748" y2="1713.5" />
    <polygon
      className="cls-3"
      points="186.1 1698.6 184.53 1694.6 180.59 1693.1 176.66 1694.6 175.09 1698.6 176.66 1702.5 180.59 1704.9 184.53 1702.5"
    />
    <polyline
      className="cls-3"
      points="72.01 1634.1 73.58 1623.8 73.58 1613.6 72.01 1603.4"
    />
    <line className="cls-3" x1="53.77" x2="77.51" y1="1472" y2="1472" />
    <line className="cls-3" x1="49.97" x2="49.97" y1="1176.1" y2="1182.4" />
    <line className="cls-3" x1="49.97" x2="49.97" y1="1204.4" y2="1209.9" />
    <line className="cls-3" x1="49.97" x2="49.97" y1="1176.1" y2="1182.4" />
    <line className="cls-3" x1="49.97" x2="49.97" y1="1232" y2="1238.2" />
    <rect x="3.54" y="1118.6" width="46.43" height="632.67" />
    <rect
      transform="translate(-1841.3 2194.6) rotate(-90)"
      x="396.91"
      y="1841.3"
      width="46.43"
      height="369.4"
    />
    <rect
      transform="translate(-994.67 3041.3) rotate(-90)"
      x="1243.6"
      y="1777.2"
      width="46.43"
      height="497.59"
    />
    <rect
      transform="translate(-355.07 703.32) rotate(-90)"
      x="394.37"
      y="355.08"
      width="46.43"
      height="364.34"
    />
    <rect
      transform="translate(561.56 1623) rotate(-90)"
      x="1312.5"
      y="358.86"
      width="46.43"
      height="359.79"
    />
    <rect x="326.07" y="1797.7" width="46.43" height="123.1" />
    <rect x="779.33" y="1797.7" width="46.43" height="123.1" />
    <rect x="33.44" y="1118.6" width="349.73" height="47.21" />
    <rect x="552.8" y="1118.6" width="46.43" height="46.43" />
    <rect x="561.45" y="1163.4" width="29.44" height="757.6" />
    <rect x="316.73" y="3.54" width="46.43" height="306.91" />
    <rect x="3.54" y="310.46" width="46.43" height="631.91" />
    <rect x="548.87" y="3.54" width="182.56" height="46.46" />
    <rect x="913.69" y="3.54" width="53.08" height="307.29" />
    <rect x="648.14" y="770.89" width="12.46" height="18.8" />
    <rect x="49.43" y="771.57" width="598.52" height="22.06" />
    <rect x="363.16" y="791.87" width="22.03" height="159.94" />
    <rect x="3.54" y="943.43" width="15.94" height="13.89" />
    <rect x="3.54" y="1104.5" width="14.17" height="14.17" />
    <rect x="386.82" y="1118.6" width="7.81" height="18.89" />
    <rect x="543.36" y="1118.6" width="7.44" height="18.89" />
    <rect x="600.92" y="1147" width="7.75" height="18.89" />
    <rect x="766.84" y="1118.6" width="509.91" height="47.21" />
    <rect x="757.4" y="1147" width="10.01" height="18.34" />
    <rect x="1230.3" y="309.71" width="46.43" height="1441.7" />
    <rect x="818.78" y="770.89" width="411.55" height="22.74" />
    <rect x="809.34" y="771.57" width="9.47" height="18.12" />
    <rect x="364.98" y="3.54" width="14.05" height="14.05" />
    <rect x="533.92" y="3.54" width="12.3" height="14.1" />
    <rect x="732.48" y="3.54" width="13.11" height="15.03" />
    <rect x="902.19" y="3.54" width="13.99" height="13.99" />
    <circle className="cls-22" cx="615.75" cy="939.62" r="36.26" />
    <path
      transform="translate(-235.42 -251.52)"
      d="M843.32,1185.36v-4.07a25.71,25.71,0,0,0,4-.38,5.54,5.54,0,0,0,2.93-1.59,5.33,5.33,0,0,0,1.17-2.18,5.22,5.22,0,0,0,.23-1.22h5v30.43h-6.12v-21Z"
    />
    <circle className="cls-22" cx="725.61" cy="1488.5" r="36.26" />
    <path
      transform="translate(-235.42 -251.52)"
      d="m952.06 1749.3q1.28-3 6-6.41a45 45 0 0 0 5.32-4.22 6.23 6.23 0 0 0 1.86-4.35 4.75 4.75 0 0 0-1.07-3.19 4.34 4.34 0 0 0-6.74 0.75 10 10 0 0 0-0.67 3.72h-5.76a14.47 14.47 0 0 1 1.4-6.25q2.39-4.53 8.46-4.53a10.74 10.74 0 0 1 7.65 2.66 9.21 9.21 0 0 1 2.85 7 9.55 9.55 0 0 1-2 6 20.13 20.13 0 0 1-4.33 3.86l-2.39 1.69c-1.49 1.06-2.51 1.82-3.06 2.3a6.7 6.7 0 0 0-1.39 1.65h13.21v5.24h-20.74a14.38 14.38 0 0 1 1.4-5.92z"
    />
    <circle className="cls-22" cx="344.81" cy="1388.6" r="36.26" />
    <path
      transform="translate(-235.42 -251.52)"
      d="M575.72,1645.53a6.62,6.62,0,0,0,.57,2.93,3.91,3.91,0,0,0,3.85,2.14,4.24,4.24,0,0,0,3-1.17,4.35,4.35,0,0,0,1.27-3.37,3.81,3.81,0,0,0-2.35-3.9,12.12,12.12,0,0,0-4.23-.55v-4.15a11,11,0,0,0,3.94-.55,3.4,3.4,0,0,0,1.93-3.47,3.91,3.91,0,0,0-1-2.75,3.61,3.61,0,0,0-2.78-1.06,3.52,3.52,0,0,0-3,1.31,5.54,5.54,0,0,0-.92,3.48h-5.42a14.38,14.38,0,0,1,.75-4.18,9.51,9.51,0,0,1,2.24-3.2,7.86,7.86,0,0,1,2.71-1.59,11.82,11.82,0,0,1,3.84-.55,10.22,10.22,0,0,1,6.83,2.19,7.32,7.32,0,0,1,2.6,5.88,6.54,6.54,0,0,1-1.55,4.41,5.28,5.28,0,0,1-2,1.52,4.25,4.25,0,0,1,2.29,1.37,7.29,7.29,0,0,1,2.22,5.62,9.51,9.51,0,0,1-2.6,6.6q-2.6,2.84-7.68,2.84-6.27,0-8.72-4.1a12.13,12.13,0,0,1-1.42-5.7Z"
    />
    <circle className="cls-22" cx="231.35" cy="1021.8" r="36.26" />
    <path
      transform="translate(-235.42 -251.52)"
      d="M477.5,1281.94H474v6.59h-5.89v-6.59H456v-5.25l11.25-18.59H474v19.13h3.46Zm-9.35-4.71v-13.18l-7.64,13.18Z"
    />
    <circle className="cls-22" cx="611.82" cy="389.75" r="36.26" />
    <path
      transform="translate(-235.42 -251.52)"
      d="M842.74,647.65a5.62,5.62,0,0,0,1.34,3,3.8,3.8,0,0,0,2.9,1,3.9,3.9,0,0,0,3.33-1.54,6.32,6.32,0,0,0,1.15-3.89,6.79,6.79,0,0,0-1.07-3.88,3.77,3.77,0,0,0-3.35-1.59,5.7,5.7,0,0,0-1.86.27,3.72,3.72,0,0,0-2.09,1.85l-5.29-.25,2.11-16.59h16.52v5H844.17l-1.08,6.57a11.68,11.68,0,0,1,2.13-1.18,9,9,0,0,1,3.13-.48,9.37,9.37,0,0,1,6.49,2.5q2.77,2.52,2.77,7.3a11.43,11.43,0,0,1-2.67,7.43q-2.67,3.27-8,3.27a10.61,10.61,0,0,1-7-2.29,9,9,0,0,1-3.06-6.53Z"
    />
  </StyledSvg>
);

export default SecondFloorSecondType;
