import React, { FC } from "react";
import styled, { css } from "styled-components";
import { number } from "prop-types";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 520px;

  @media (max-width: 1630px) {
    width: 420px;
  }

  @media (max-width: 1088px) {
    width: 100%;
    max-width: 600px;
  }
`;

const StyledHeadline = styled.h3`
  font-size: 2.4rem;
  text-transform: uppercase;
  color: #7bb933;
  margin: 0 0 25px;
`;

const StyledInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledItem = styled.div<{ $summary?: boolean; $isActive?: boolean }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 12px;
  transition: background 0.2s ease-in-out;

  :nth-of-type(odd) {
    background: transparent;
  }

  :nth-of-type(even) {
    background: #eeeeee;
  }

  span {
    margin-right: 20px;
  }

  div:first-of-type {
    text-transform: uppercase;
    margin-right: auto;
  }

  ${({ $summary }) =>
    $summary &&
    css`
      div {
        font-weight: 400;
      }
    `}

  ${({ $isActive }) =>
    $isActive &&
    css`
      background: rgba(123, 185, 51, 0.4) !important;
    `}
`;

const Table: FC<{
  title: string;
  values: { name: string; value: string | number }[];
  summary?: { name: string; value: string | number };
  withCount?: boolean;
  className?: string;
  setCurrentRoom?: React.Dispatch<React.SetStateAction<number>>;
  currentRoom?: number;
  isRowActive?: boolean;
  setRowActive?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  title,
  values,
  summary,
  withCount,
  className,
  setCurrentRoom,
  currentRoom,
  setRowActive,
  isRowActive,
}) => {
  const handleItemEnter = (num: number) => {
    if (!setCurrentRoom || !setRowActive) return;

    setCurrentRoom(num);
    setRowActive(true);
  };

  const handleItemLeave = () => {
    if (!setRowActive) return;
    setRowActive(false);
  };

  return (
    <StyledWrapper className={className}>
      <StyledHeadline>{title}</StyledHeadline>
      <StyledInnerWrapper>
        {values.map(({ name, value }, index) => (
          <StyledItem
            key={index}
            onMouseEnter={() => handleItemEnter(index + 1)}
            onMouseLeave={handleItemLeave}
            $isActive={isRowActive && currentRoom === index + 1}
          >
            {withCount && <span>0{index + 1}</span>}
            <div>{name}</div>
            <div
              dangerouslySetInnerHTML={{
                __html: typeof value === "number" ? value.toString() : value,
              }}
            />
          </StyledItem>
        ))}
        {summary && (
          <StyledItem $summary>
            <div>{summary.name}</div>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  typeof summary.value === "number"
                    ? summary.value.toString()
                    : summary.value,
              }}
            />
          </StyledItem>
        )}
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};

export default Table;
