import React, { FC } from "react";
import styled from "styled-components";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";

const StyledWrapper = styled.div`
  width: 35vw;
  max-width: 680px;
  position: relative;

  @media (max-width: 1225px) {
    width: 29vw;
    max-width: 550px;
  }

  @media (max-width: 1088px) {
    width: 100%;
    max-width: unset;
  }
`;

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`;

const StyledSvg = styled.svg`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  path {
    fill: #7bb933;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    &.is-active {
      opacity: 0.4;
    }
  }
`;

const Svg: FC<{
  svgId: number;
  setCurrentRoom: React.Dispatch<React.SetStateAction<number>>;
  currentRoom: number;
  isRowActive: boolean;
  setRowActive: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ svgId, setCurrentRoom, setRowActive, isRowActive, currentRoom }) => {
  const handlePathEnter = (num: number) => {
    setCurrentRoom(num);
    setRowActive(true);
  };

  const handlePathLeave = () => setRowActive(false);

  switch (svgId) {
    case 0:
      return (
        <StyledSvg
          width="3635"
          height="2347"
          version="1.1"
          viewBox="0 0 3635 2347"
        >
          <path
            d="m1587 1281.1-150.65 103.23 26.919 21.733-28.648 20.498 6.1702 3.715 1.7463-1.397 3.1433 2.8814-5.2388 5.0642 15.193 10.827-1.4843 1.659 94.474 70.55 3.5799-0.087 15.454 11.525 10.216-7.509 8.7314 6.8978 23.4-16.852 22.003 16.59 152.71-107.57-59.024-42.784 0.8731-1.9209-84.695-60.421-2.6194 0.6985-15.716-10.827-3.8418-0.8732-10.128-6.9851 1.2224-0.8731z"
            onMouseEnter={() => handlePathEnter(1)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 1 && isRowActive ? "is-active" : ""}
          />
          <path
            d="m1605.6 1270 10.375-6.5 0.375-3.25 15.125-9.875-0.25-2.5 83-55.875 3.224 0.03 13.877-9.8111 4.1542-0.442 108.63-74.776h1.9445l8.9273-6.0988 0.6187-3.0052 13.523-8.3085 0.7071-3.624 78.4-52.768 4.5078-0.3536 12.463-8.662 3.8007-1.4142 44.813-30.759-3.182 148.67 49.498 31.113 71.064-48.79 36.77 24.749-370.52 260.22-35.59-28.337-5.125 4-3-3-6.5 4.75-14-9.625-4.25-0.125-83.5-59.75 0.125-3-12.875-9.5 5.875-4.25-2.75-2.125 4.625-3.5-10.25-7.875-2.125 1.25z"
            onMouseEnter={() => handlePathEnter(2)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 2 && isRowActive ? "is-active" : ""}
          />
          <path
            d="m1863.2 1083.6 3.8891 0.3535 78.842-53.387 0.1767-3.1819 12.021-7.4247-6.0105-3.889 2.4749-2.6517-3.5355-2.6516 8.662-7.0711-293.45-195.87-176.42 112.96 72.125 50.028-0.7071-22.804s1.0606-8.3085 13.258-8.3085 13.612 7.4246 13.612 7.4246l1.591 43.31 194.28 133.29 51.972-36.062 4.4194 3.8891 3.0052-2.8284 6.3639 4.4194 13.435-9.5459"
            onMouseEnter={() => handlePathEnter(5)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 5 && isRowActive ? "is-active" : ""}
          />
          <path
            d="m1766.9 1134.6-131.38-90.875s-3.75-12.375-29.5-21.75c-15.125-3.5-15-3.25-15-3.25l-2.8382-71.645s-2.4118-7.9804-13.162-7.3554-11.541 3.1035-13.708 8.2392c0.8332 22.011 0.7071 22.804 0.7071 22.804l0.8839 23.158-28.991-20.153s-8.1317-17.678-31.466-21.567l-12.905-9.5459-3.1819-65.584s-3.5356-7.955-14.672-7.0711-11.667 8.4853-11.667 8.4853l2.8285 59.574-339.41 216.73 218.67 162.28 80.787-53.563 73.539 54.094 90.51-60.458 0.1768-1.591 9.8995-6.5408 4.7729 3.3588 3.0052-2.4749 5.6569 4.9498 14.496-9.3692 3.8891 0.5303 82.201-56.568v-3.0052l6.364-4.2426-0.5303-13.435z"
            onMouseEnter={() => handlePathEnter(6)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 6 && isRowActive ? "is-active" : ""}
          />
          <path
            d="m1703.8 784.29 640.29-409.06 17.941-304.23 976 541-53 311-275 208-20 147-300 251 13-107-10-6-17 12-28-18-30 23-159-106.5s-6-6-14.5 0.5-74.5 54.5-74.5 54.5l-54.5-35.5 8.5-151.5-115.5-75-40.511 28.247-36.77-24.749-71.064 48.79-49.498-31.113 120.34-81.176v-32.5l-37.5-25 1-25.5-38-25.5-1-27.5-37-24.5-0.5-27-38.5-25 1-24.5-27-18.5-138.5 55.5z"
            onMouseEnter={() => handlePathEnter(4)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 4 && isRowActive ? "is-active" : ""}
          />
          <path
            d="m2189.9 1156.2 100.41 66.468-10.607 148.49 35.788-25.193 54.5 35.5 76.413-55.739s5.3033-4.2426 11.402-0.1767c6.0988 4.0658 160.19 107.42 160.19 107.42l-551.13 419.03-325.98-242.54 169-121.62-91.217-66.468z"
            onMouseEnter={() => handlePathEnter(3)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 3 && isRowActive ? "is-active" : ""}
          />
        </StyledSvg>
      );
    case 1:
      return (
        <StyledSvg
          width="1169.9"
          height="742.4"
          version="1.1"
          viewBox="0 0 1169.9 742.4"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m652.29 300.76s4.4568-0.26185 4.0428 4.0374c0.0955 4.5222-0.35031 13.312-0.35031 13.312l8.9171-6.3056s2.0382-4.8407 10.732-7.261l3.8534-2.8025 0.92356-20.254s0.54139-3.312 4.5222-2.8662c3.9808 0.44585 3.8534 2.898 3.8534 2.898l-0.76432 18.598 108.72 69.362-94.011 69.489-57.324-39.299-1.7667 1.2108-0.9458-0.85572-2.0267 1.5763-4.2786-3.0176-1.171 0.0901-26.617-17.97 0.0901-0.85572-4.2336-2.8374 1.8466-1.2611-0.58549-0.72061 1.0359-0.90076-16.079-11.034 41.674-28.71s3.7241-5.4292 11.065-7.6361c3.6481-2.5672 3.8733-2.7023 3.8733-2.7023l0.67557-20.943s0.76565-2.5672 4.3236-2.342"
            onMouseEnter={() => handlePathEnter(6)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 6 && isRowActive ? "is-active" : ""}
          />
          <path
            d="m585.15 455.33 9.7769-7.07 0.4777 0.25478 3.4394-2.3885-0.28662-0.70063 5.1273-3.5031 0.0318-1.0509 26.847-19.076h1.0191l5.3184-3.8534h0.57324l10.764-7.8661 48.769 32.998-9.0977 6.9809 9.0977 6.2603-1.8916 1.3061-0.39719-0.34801-1.3216 0.84394 2.07 1.5923-4.8248 3.4394-0.0319 0.93948-30.129 22.312-1.0172-0.099-5.0477 3.6783-3.2165-2.4363-2.7866 2.4204-7.4044-5.4776-7.0986 4.8594z"
            onMouseEnter={() => handlePathEnter(1)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 1 && isRowActive ? "is-active" : ""}
          />
          <path
            d="m585.21 359.54-16.56-11.528-1.4457 1.1399-0.99084-0.72061-2.0492 1.3737-4.3236-2.905-1.0134 0.0901-25.379-17.182 0.0901-0.72061-3.6931-2.6798 1.6664-1.3962-0.78816-0.65305 1.2836-0.90076-2.9725-2.1618 94.197-62.693 55.847 36.211-22.069 15.313v-6.7557s-0.62252-3.3836-4.6989-3.1288c-4.0764 0.25478-4.4585 1.9745-4.4585 1.9745l-0.41401 0.79617-0.4777 14.076z"
            onMouseEnter={() => handlePathEnter(5)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 5 && isRowActive ? "is-active" : ""}
          />
          <path
            d="m642.29 406.37-3.2878-2.2969v-0.72061l-4.8191-3.3778 0.045-0.85572-26.482-17.655-1.4863 0.045-4.0985-2.9275-0.72061-0.63053-0.76564 0.3603-37.922-25.942-0.27023-1.0359-4.4588-2.6572-0.13511-1.0359-25.041-16.844-1.5313-0.22519-4.3236-3.1076-1.0809 0.045-14.232-10.089 0.99084 47.695-14.968 10.664-23.439-16.688-11.974 8.4075 118.21 82.929 11.847-8.9171 1.9108 1.656 0.50954-1.1465 2.5477 1.5286 4.4585-3.4394h0.8917l26.687-18.949 0.31851-1.4331 4.0764-2.6751-2.0382-1.2739 0.63693-1.0191-1.1465-0.89171 3.312-2.8025 0.50955 0.50955z"
            onMouseEnter={() => handlePathEnter(2)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 2 && isRowActive ? "is-active" : ""}
          />
          <path
            d="m599.64 515.23-54.496-39.183 29.635-21.168-119.8-84.401-31.256 20.627-11.169-8.1068 2.7924 47.47-17.745 11.98-23.97-17.749s-1.656-2.1656-5.0955-0.25477c-3.6942 2.8025-50.063 33.503-50.063 33.503l176.05 134.52z"
            onMouseEnter={() => handlePathEnter(3)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 3 && isRowActive ? "is-active" : ""}
          />
          <path
            d="m610.95 251.2-205.6-131.21-6.1146-97.323-310.06 172.74 16.815 100.13 87.133 66.496 6.3693 48.152-5.605 2.5477 1.0191 3.0573 100.38 79.489-3.3121-39.235 3.0573-2.5477 4.3312 4.3312 9.9362-6.8789 9.1718 7.0063 50.063-33.503s2.6751-1.0191 5.0955 0.25477 23.97 17.749 23.97 17.749l17.745-11.98-2.7924-47.47 37.127-24.54 12.611 9.0444 11.974-8.4075 23.439 16.688 14.968-10.664-38.534-26.278 0.12738-10.064 11.974-7.898-0.12739-8.4075 12.102-8.1528 0.12738-8.2801 11.974-7.7706 0.12739-8.6623 11.847-8.0254 0.12738-8.7897 9.0445-5.4776 44.076 18.089z"
            onMouseEnter={() => handlePathEnter(4)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 4 && isRowActive ? "is-active" : ""}
          />
        </StyledSvg>
      );
    case 2:
      return (
        <StyledSvg
          width="3792"
          height="3413.3"
          version="1.1"
          viewBox="0 0 3792 3413.3"
        >
          <path
            d="m2721.5 1202.3 906.16 588.7-524.19 424.63-34.032 257.52 20.318 14.73-2.0317 17.27-155.94 134.1 25.905-208.76-17.27-13.206-33.016 24.889-957.46-692.32 15.746-12.19-24.426-17.962 8.4404-5.5671-3.2325-3.2325 26.399-17.599s1.7079-1.6079-0.9587-4.9095c9.2698-5.5873 131.81-92.698 131.81-92.698l9.1428-1.3968 20.444-14.857 3.4286-2.0317 3.1746 1.6508 7.619-5.2064 25.524 17.016z"
            onMouseEnter={() => handlePathEnter(2)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 2 && isRowActive ? "is-active" : ""}
          />
          <path
            d="m2879.1 2465.1-28.733 27.656-26.578 224.84-162.7 139.36 19.395-200.77-16.522-12.93-34.839 24.423-45.614-34.121-432.08 353.78-724.08-583.28 47.41-39.508-181.02-147.26 395.08-283.02-24.064-18.856 8.9791-7.0037-8.0812-6.465 4.4895-3.2325-10.416-7.9016 23.091-16.546-1.5238-3.5556 137.14-97.016h5.5873l23.365-15.238 10.159 7.6191 4.5714-3.0476 9.1429 6.0952 8.6349-5.5873 25.397 16.254 14.73-9.6508z"
            onMouseEnter={() => handlePathEnter(3)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 3 && isRowActive ? "is-active" : ""}
          />
          <path
            d="m1198.2 1609.6 191.54 141.25 10.159-7.1111 97.524 73.143-0.7619 3.5556 23.111 18.794 4.3175 124.19-388.83 273.78-32-22.603-17.778 12.444 16.254 207.75-142.48-116.57-16.762-232.64-28.444-25.651 43.936-28.698-179.05-143.49z"
            onMouseEnter={() => handlePathEnter(4)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 4 && isRowActive ? "is-active" : ""}
          />
          <path
            d="m1700.6 437.84-363.68 219.43-15.238-379.94-293.59-183.87-718.22 405.33 285.46 209.27 39.619 377.9-418.54 253.97 15.238 108.7 519.11 414.48 776.13-513.52v-2.7937l14.476-11.429 2.7937 1.7778 27.175-18.54 5.5873 1.5238 131.81-87.365 0.2539-4.5715 23.111-14.476 0.2539-4.8254 15.492-10.413 2.4032 3.476 391.85-261.11-140.79-95.633 80.254-53.841-381.97-251.94z"
            onMouseEnter={() => handlePathEnter(5)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 5 && isRowActive ? "is-active" : ""}
          />
          <path
            d="m2012.4 1648.8-241.78-169.65 1.4552-252.74-8.9791 5.5671-7.3629-5.7467-5.9263 4.1304-10.236-8.0812-20.652 14.726-5.7466-0.8979-130.92 86.559 0.1796 4.4896-19.754 14.546 8.9791 7.722-4.1304 2.3346 7.3629 5.9262-345.73 230.16 188.44 142.73 1.5238 20.318 83.81 62.476h6.6032l20.825 16.254 4.5714 126.48 104.13-73.651 3.5555 2.0317 28.444-19.81 3.5555 1.0158 109.21-74.667v-3.0476l28.952-19.302 1.0158-5.5873 25.905-16.762-2.0318-2.5397 77.206-54.349 9.1428 6.6032 5.0794-2.5397 10.159 5.0793 20.825-13.206 7.1112-0.5079 48.254-33.016z"
            onMouseEnter={() => handlePathEnter(1)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 1 && isRowActive ? "is-active" : ""}
          />
        </StyledSvg>
      );
    case 3:
      return (
        <StyledSvg
          width="3786.7"
          height="3413.3"
          version="1.1"
          viewBox="0 0 3786.7 3413.3"
        >
          <path
            d="m2752.4 94.645 722.29 405.25-287.64 204.54-43.465 375.85 424.42 259.51-16.619 113.78-525.42 410.36-772.27-513.99 1.5819-1.017-1.13-4.0678-15.141-9.7175-3.1638 2.1469-25.763-18.87-5.4238 1.9209-131.53-87.571-0.113-4.7457-23.051-14.689-0.904-4.8588-15.028-9.3785-2.9379 2.5989-392.66-260.68 142.37-94.915-84.52-55.141 381.92-248.14 0.904-108.93 366.45 219.4 17.897-374.57z"
            onMouseEnter={() => handlePathEnter(5)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 5 && isRowActive ? "is-active" : ""}
          />
          <path
            d="m2582.1 1607.2 419.89 280.23-178.98 142.82-20.339 257.63 18.983 10.848-0.904 20.791-142.37 115.25 17.627-207.91-16.271-12.203-31.638 23.051-391.86-275.25 4.9718-123.84 23.051-17.627v-4.0678l96.723-71.412 3.1638 1.3559 7.2317 4.5198z"
            onMouseEnter={() => handlePathEnter(4)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 4 && isRowActive ? "is-active" : ""}
          />
          <path
            d="m1934.7 1758 22.373 16.045 5.4237-0.452 138.08 96.045-0.6779 4.9717 23.051 16.271-11.299 7.6836 4.2938 3.6158-8.1356 5.8757 9.7175 7.6837-24.859 17.401 396.84 282.49-186.21 149.15 52.429 38.87-724.97 579.44-432.99-348.93-46.102 32.542-28.927-24.407-21.695 15.367 18.983 193.45-164.52-132.88-25.311-226.89-28.023-25.311 959.1-696.95 14.463 11.751 25.311-18.983 9.9435 6.3276 6.3277-5.4237 3.6158 3.6158z"
            onMouseEnter={() => handlePathEnter(3)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 3 && isRowActive ? "is-active" : ""}
          />
          <path
            d="m1061.2 1202.3 548.7 390.51 24.407-19.887 9.4916 7.2317 2.2599-2.2599 24.407 18.531 6.3277-0.4519 132.88 92.655-0.904 4.5198 24.407 16.271-1.3559 5.4237 7.6836 4.5198-24.407 18.531 15.819 11.299-957.29 692.88-30.282-24.407-20.791 15.367 27.571 204.29-158.64-132.43-30.282-233.67-25.311-23.503 43.39-31.186-525.65-423.95z"
            onMouseEnter={() => handlePathEnter(2)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 2 && isRowActive ? "is-active" : ""}
          />
          <path
            d="m2245.4 1955.7 5.8757-126.1 20.791-15.819 7.2317-0.452 83.164-63.277v-18.983l191.19-143.28-346.21-229.6 7.2316-6.7797-4.9717-2.7119 9.4915-8.1356-21.695-14.463 2.2599-3.1639-131.07-86.78h-7.6836l-19.435-13.107-10.848 5.8757-4.5198-2.2599-9.4915 5.4238-7.2316-5.4238-0.452 255.37-242.26 170.4 46.554 32.09h4.9717l23.955 14.915 9.9436-8.1356 5.8757 3.6158 9.0395-6.7797 77.288 54.689-1.8079 3.1639 26.215 19.435 0.452 2.7118 30.734 20.339v4.0678l106.67 74.124 4.9718-0.452 26.667 18.983 2.7119-2.2599z"
            onMouseEnter={() => handlePathEnter(1)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 1 && isRowActive ? "is-active" : ""}
          />
        </StyledSvg>
      );
    case 4:
      return (
        <StyledSvg
          width="1882.7"
          height="1706.7"
          version="1.1"
          viewBox="0 0 1882.7 1706.7"
        >
          <path
            d="m1811.5 894.89-451.91-292.75-273.57 194.31-12.304-9.4281-3.5156 2.7166-2.8764-0.3196-11.985 9.1085-3.0362-0.4794-65.837 46.501 0.3196 2.0774-11.825 8.7889-0.3196 1.9176-3.5156 2.2372 11.346 9.1085v11.665l472.2 341.01 15.021-12.784 9.2683 6.7115-13.104 103.07 77.822-65.198 0.9588-10.067-11.186-7.3507 17.897-128.32z"
            onMouseEnter={() => handlePathEnter(2)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 2 && isRowActive ? "is-active" : ""}
          />
          <path
            d="m959.91 885.3 479.39 347.56-14.222 11.985-12.784 113.46-82.296 68.713 10.227-98.915-8.7889-6.3919-17.258 11.985-21.573-17.258-217.49 176.26-362.26-290.99 25.408-18.696-92.204-74.306 198.63-141.42-11.346-8.3095 4.1548-4.6342-4.3146-2.8764 2.2372-3.5156-5.2733-2.0774 11.665-8.6291-0.1598-2.5568 67.754-48.419 3.3558 0.3196 10.227-7.0311 4.9537 2.8764 3.196-1.4382 4.3146 3.6754 5.2733-3.6754 11.186 8.9487z"
            onMouseEnter={() => handlePathEnter(3)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 3 && isRowActive ? "is-active" : ""}
          />
          <path
            d="m665.2 853.79 29.04 22.147 5.8757-3.3898 47.91 36.045-0.45198 3.2768 12.655 7.7966 1.5819 62.486-194.58 136.05-15.819-11.073-8.3616 5.6497 8.1356 106.89-69.605-61.243-0.90396-8.3615 9.9435-7.0057-11.977-127.46-88.814-71.186 209.04-140.11z"
            onMouseEnter={() => handlePathEnter(4)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 4 && isRowActive ? "is-active" : ""}
          />
          <path
            d="m376.48 931.97 267.7-178.07 0.113-2.8249 7.2316-4.6328 2.4859 0.67797 12.881-8.8136 3.9548 0.22599 65.763-44.52 1.2429-2.8249 11.412-7.3446 0.33899-1.9209 3.6158-2.9378-274.31-196.21-18.537-243.69-130.56-88.209-176.26 97.956 143.18 103.07 20.091 190.93-209.27 126.55 8.1356 56.497z"
            onMouseEnter={() => handlePathEnter(5)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 5 && isRowActive ? "is-active" : ""}
          />
          <path
            d="m346.12 141.6 169.07-94.281 145.42 94.281 7.6703 186.96 182.17-111.54 0.31959 54.971 189.84 123.36-41.228 27.166 71.27 46.981-195.51 131.75-1.598-1.8377-8.3095 5.1934 0.47939 2.0774-11.665 7.111 0.39949 2.6367-65.397 44.024-3.6354-0.75904-12.065 8.5892-2.8764-0.47939-2.7965 2.0774-274.79-196.22-18.305-244.29z"
            onMouseEnter={() => handlePathEnter(6)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 6 && isRowActive ? "is-active" : ""}
          />
          <path
            d="m1007.4 825.86-120.81-84.054-1.2494-127.91-3.6158 2.5989-3.8418-3.0508-2.4859 1.5819-5.1977-3.7288-10.96 8.1356-2.5989-0.56497-65.311 43.729-0.67797 2.0339-9.6045 5.8757 4.7458 3.3898-1.5819 1.6949s-0.22599 2.0339 2.5989 1.9209l-0.22599 2.4859-21.469 13.559-3.6158-2.5989-2.1469 1.6949-5.5367-4.0678-11.073 7.6836-3.5028-0.33899-66.893 44.972-0.113 2.5989-10.734 6.2147 4.4068 3.0508-1.3559 2.5989 3.6158 2.7119-53.672 36.497 95.254 71.412-0.56497 9.0396 41.469 31.638 3.9548-0.113 11.412 9.0396 1.8079 62.486 51.525-36.497 3.0508 0.33899 12.203-9.0396 2.9379 0.33898 53.898-37.74 0.56498-2.2599 14.35-9.3785 0.22599-2.3729 12.316-7.5706-0.56497-2.5989 38.87-26.78 4.0678 2.8249 2.4859-1.2429 4.6328 2.5989 0.22599 3.1638 11.864-9.6045 2.3729 0.33899z"
            onMouseEnter={() => handlePathEnter(1)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 1 && isRowActive ? "is-active" : ""}
          />
        </StyledSvg>
      );
    case 5:
      return (
        <StyledSvg
          width="1882.7"
          height="1706.7"
          version="1.1"
          viewBox="0 0 1882.7 1706.7"
        >
          <path
            d="m1548.3 141.62-169.77-94.615-145.48 94.224-8.2135 186.83-181.39-110.3-1.4397 53.556-190.47 124.2 41.009 27.186-70.673 47.268 196.37 131.14 1.6959-1.543 7.6858 5.4122-0.7731 1.9105 12.01 6.94-0.1915 2.9358 65.882 43.139h3.2127l11.983 8.2013 6.3229 0.92997 274.05-195.41 18.378-243.18z"
            onMouseEnter={() => handlePathEnter(5)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 5 && isRowActive ? "is-active" : ""}
          />
          <path
            d="m1563.5 150.51 175.87 98.27-142.72 103.53-21.019 189.52 211.53 128.47-8.4302 53.889-260.4 208.57-269.42-178.73 0.5276-2.8365-7.2186-4.5864h-3.696l-11.764-8.2342-3.3871 0.409-66.309-44.502-0.326-2.4536-12.306-7.5567-0.4802-2.436-3.7374-2.536 274.3-197.06 18.759-243.43z"
            onMouseEnter={() => handlePathEnter(6)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 6 && isRowActive ? "is-active" : ""}
          />
          <path
            d="m1504.5 943.65-211.8-139.83-94.495 72.659-4.6731-4.1965-48.557 36.067 0.2442 2.6654-11.83 8.805-1.1301 62.33 193.89 136.31 15.332-11.912 9.5329 6.9675-7.269 102.9 68.151-57.082 0.3752-2.3542-9.8651-7.3927 11.982-135.01z"
            onMouseEnter={() => handlePathEnter(4)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 4 && isRowActive ? "is-active" : ""}
          />
          <path
            d="m1246.8 1108.4-198.16-141.27 11.638-8.9367-4.2666-2.5896 3.9934-4.0858-2.3147-1.413 4.9687-4.9194-10.339-6.4282 0.6333-3.3987-68.801-48.946-3.9053 0.1979-10.205-7.4663-4.8585 3.829-3.0749-1.4142-3.5001 3.0354-5.2846-3.397-12.718 8.8448-6.5688-5.1732-479.5 347.61 13.548 12.753 12.981 114.49 81.85 67.15-8.6791-98.572 8.9002-6.9501 15.975 12.636 21.283-16.94 217.24 173.3 362.47-289.47-24.464-17.314z"
            onMouseEnter={() => handlePathEnter(3)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 3 && isRowActive ? "is-active" : ""}
          />
          <path
            d="m426.63 1318.9-11.77-103.81 8.0614-6.7082 16.291 12.348 479.65-345.77-7.7649-6.0024 11.667-9.6978-16.61-12.187-0.15522-2.6244-64.313-45.734-3.901-0.40847-10.621-7.9683-3.4488-0.55911-3.8205-2.2847-13.356 8.5371-273.35-193.78-454.22 293.51 264.11 212.89-23.708 16.255 14.058 10.023 16.788 120.54z"
            onMouseEnter={() => handlePathEnter(2)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 2 && isRowActive ? "is-active" : ""}
          />
          <path
            d="m1006 741.83-120.12 84.071 23.541 16.371 3.1799-0.65757 9.9558 7.3367 5.4914-3.7935 3.9188 2.6478 3.3742-3.5005 38.912 27.516 0.20261 2.6123 12.364 6.8625 0.23828 3.2149 68.896 48.698 2.8077-0.46014 10.977 8.2273 3.2491 0.0818 53.962 36.681 1.1196-62.856 9.8551-8.1653 4.3223-0.22993 41.566-31.345-0.1757-9.6924 95.219-71.894-54.064-34.503 4.0302-3.4604-2.6597-2.3147 4.7292-3.9674-10.625-6.0541-0.062-3.0461-65.798-43.851-3.3898 0.16361-11.874-7.7673-4.2413 3.4527-2.8262-1.7147-3.2846 3.1258-22.449-13.681 2.5187-3.088-1.673-2.6811 4.2877-3.3115-9.1176-5.9475-0.2314-3.0016-64.731-43.601-3.8956 0.3278-10.275-7.0061-5.1055 2.5528-2.5697-0.74473-4.6386 2.8363-3.6034-2.2275z"
            onMouseEnter={() => handlePathEnter(1)}
            onMouseLeave={handlePathLeave}
            className={currentRoom === 1 && isRowActive ? "is-active" : ""}
          />
        </StyledSvg>
      );
    default:
      return <></>;
  }
};

const Floor: FC<{
  image: ImageDataLike;
  svgId: number;
  setCurrentRoom: React.Dispatch<React.SetStateAction<number>>;
  currentRoom: number;
  isRowActive: boolean;
  setRowActive: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  image,
  svgId,
  setRowActive,
  setCurrentRoom,
  currentRoom,
  isRowActive,
}) => (
  <StyledWrapper>
    <StyledImage image={getImage(image)} alt="" />
    <Svg
      svgId={svgId}
      setCurrentRoom={setCurrentRoom}
      setRowActive={setRowActive}
      isRowActive={isRowActive}
      currentRoom={currentRoom}
    />
  </StyledWrapper>
);

export default Floor;
