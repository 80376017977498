import { formatNumber } from "./formatNumber";
import { Floor } from "../types/types";

export const transformFloor = (
  floor: Floor[]
): { name: string; value: string }[] =>
  floor.map(({ area, name }) => ({
    name,
    value: `${formatNumber(area)} m<sup>2</sup>`,
  }));
