import React, { FC, useEffect, useState } from "react";
import styled, { css } from "styled-components";

interface StyledProps {
  $isActive?: boolean;
  $currentValue?: number;
}

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  flex-wrap: wrap;

  @media (max-width: 365px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const StyledValue = styled.span<StyledProps>`
  font-weight: 400;
  color: #c4c4c4;
  display: block;
  transition: color 0.2s ease-in-out;
  cursor: pointer;
  text-transform: uppercase;

  @media (max-width: 574px) {
    font-size: 1.4rem;
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: #000;
    `};
`;

const StyledButton = styled.button<StyledProps>`
  width: 110px;
  height: 50px;
  border-radius: 50px;
  background: transparent;
  border: 1px solid #7bb933;
  outline: 0;
  position: relative;
  margin: 0 30px;
  cursor: pointer;

  @media (max-width: 1024px) {
    width: 74px;
    height: 34px;
    margin: 0 20px;
  }

  @media (max-width: 574px) {
    margin: 0 15px;
  }

  @media (max-width: 365px) {
    margin: 15px 0;
  }

  span {
    position: absolute;
    display: block;
    top: 50%;
    left: 8px;
    transform: ${({ $currentValue }) =>
      `translate(${$currentValue === 0 ? "0" : "calc(92px - 100%)"}, -50%)`};
    transition: transform 0.2s ease-in-out;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #7bb933;

    @media (max-width: 1024px) {
      width: 26px;
      height: 26px;
      left: 4px;
      transform: ${({ $currentValue }) =>
        `translate(${$currentValue === 0 ? "0" : "calc(64px - 100%)"}, -50%)`};
    }
  }
`;

const Switch: FC<{
  firstText: string;
  secondText: string;
  currentValue: 0 | 1;
  setCurrentValue: (currentValue: 0 | 1) => void;
  id?: string;
  className?: string;
}> = ({
  firstText,
  secondText,
  currentValue,
  setCurrentValue,
  id,
  className,
}) => {
  const [initialValue, setInitialValue] = useState<0 | 1>();

  useEffect(() => {
    setInitialValue(currentValue);
  }, []);

  return (
    <StyledWrapper className={className}>
      <StyledValue
        $isActive={currentValue === 0}
        onClick={() => setCurrentValue(0)}
      >
        {firstText}
      </StyledValue>
      <StyledButton
        role="switch"
        aria-checked={currentValue !== initialValue}
        id={id}
        onClick={() => setCurrentValue(currentValue === 0 ? 1 : 0)}
        $currentValue={currentValue}
        aria-label={currentValue === 0 ? secondText : firstText}
      >
        <span />
      </StyledButton>
      <StyledValue
        $isActive={currentValue === 1}
        onClick={() => setCurrentValue(1)}
      >
        {secondText}
      </StyledValue>
    </StyledWrapper>
  );
};

export default Switch;
