import React, { FC } from "react";
import FirstFloor from "./vectors/FirstFloor";
import SecondFloor from "./vectors/SecondFloor";
import SecondFloorSecondType from "./vectors/SecondFloorSecondType";

const Floor2D: FC<{
  level: number;
  houseType: 0 | 1;
  setCurrentRoom: React.Dispatch<React.SetStateAction<number>>;
  currentRoom: number;
  isRowActive: boolean;
  setRowActive: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  level,
  houseType,
  setCurrentRoom,
  currentRoom,
  isRowActive,
  setRowActive,
}) => {
  const handlePathEnter = (num: number) => {
    setCurrentRoom(num);
    setRowActive(true);
  };

  const handlePathLeave = () => setRowActive(false);

  if (level === 0)
    return (
      <FirstFloor
        currentRoom={currentRoom}
        isRowActive={isRowActive}
        handlePathEnter={handlePathEnter}
        handlePathLeave={handlePathLeave}
      />
    );
  else if (level === 1 && houseType === 0)
    return (
      <SecondFloor
        currentRoom={currentRoom}
        isRowActive={isRowActive}
        handlePathEnter={handlePathEnter}
        handlePathLeave={handlePathLeave}
      />
    );
  else if (level === 1 && houseType === 1)
    return (
      <SecondFloorSecondType
        currentRoom={currentRoom}
        isRowActive={isRowActive}
        handlePathEnter={handlePathEnter}
        handlePathLeave={handlePathLeave}
      />
    );
};

export default Floor2D;
