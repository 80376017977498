export const getSvgId = (
  currentFloor: number,
  index: number,
  isSecondType: boolean
): number => {
  if (currentFloor === 0) {
    return index;
  } else if (currentFloor === 1) {
    if (isSecondType) {
      return index + 4;
    }

    return index + 2;
  }
};
