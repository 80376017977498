import React, { FC } from "react";
import styled from "styled-components";
import { pathStyles } from "../styles/pathStyles";

const StyledSvg = styled.svg`
  flex: 1;
`;

const StyledPath = styled.path`
  ${pathStyles}
`;

const FirstFloor: FC<{
  currentRoom: number;
  isRowActive: boolean;
  handlePathEnter: (num: number) => void;
  handlePathLeave: () => void;
}> = ({ isRowActive, handlePathEnter, handlePathLeave, currentRoom }) => (
  <StyledSvg
    version="1.1"
    viewBox="0 0 1415.8 2112"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style
        dangerouslySetInnerHTML={{
          __html: `.cls-1,.cls-2,.cls-3,.cls-4,.cls-5,.cls-6,.cls-7,.cls-8,.cls-9{fill:none;stroke-linecap:round;stroke-linejoin:round;}.cls-1,.cls-10,.cls-2,.cls-3,.cls-4,.cls-6,.cls-7,.cls-8{stroke:#000;}.cls-1,.cls-5,.cls-9{stroke-width:0.87px;}.cls-2{stroke-width:2.61px;}.cls-3{stroke-width:3.48px;}.cls-4{stroke-width:7.83px;}.cls-5{stroke:#f29999;}.cls-6{stroke-width:3.48px;}.cls-7{stroke-width:3.5px;}.cls-8{stroke-width:5.22px;}.cls-9{stroke:blue;}.cls-10{fill:#fff;stroke-miterlimit:10;stroke-width:2.41px;}`,
        }}
      />
    </defs>
    <StyledPath
      d="m930.67 1374.5h429.87v479.47l-137.95 3.7299-3.02 31.09-170.3-0.01 4.96-34.81-127.04 0.01v-230.61l-20.89-10v-174.47l24.37-64.4"
      className={isRowActive && currentRoom === 6 ? "is-active" : ""}
      onMouseEnter={() => handlePathEnter(6)}
      onMouseLeave={handlePathLeave}
    />
    <StyledPath
      d="m906.3 1113.4 20.89-2e-4 0.70996-8.1399 432.64-1.4v246.23l-429.87 0.01-3.48-72.23h-20.89z"
      className={isRowActive && currentRoom === 5 ? "is-active" : ""}
      onMouseEnter={() => handlePathEnter(5)}
      onMouseLeave={handlePathLeave}
    />
    <StyledPath
      d="m609.56 1448.5v164.43l20.94 0.03v8.71l3.43 1.73 1.02 4.35h53.8l0.33002 20.66 164.14-0.64-3e-5 -20.01h53.07l0.01-14.8v-561.26h-272.4l-4.32 396.8-19.35 1e-4"
      className={isRowActive && currentRoom === 2 ? "is-active" : ""}
      onMouseEnter={() => handlePathEnter(2)}
      onMouseLeave={handlePathLeave}
    />
    <StyledPath
      d="m853.22 1905.3v-20.88h9.57l2e-5 -30.47h43.51l-0.01-206.22-271.34 0.63-124.59-0.64v206.23h147.07v30.47h10.44v20.88z"
      className={isRowActive && currentRoom === 1 ? "is-active" : ""}
      onMouseEnter={() => handlePathEnter(1)}
      onMouseLeave={handlePathLeave}
    />
    <StyledPath
      d="m55.27 1051.7 430.73-0.01v52.21l123.56 0.03v519.47h-127.91l4.35 230.6h-429.88z"
      className={isRowActive && currentRoom === 3 ? "is-active" : ""}
      onMouseEnter={() => handlePathEnter(3)}
      onMouseLeave={handlePathLeave}
    />
    <StyledPath
      d="m20.45 1051.7v-554.33l34.82 0.01-0.03-442.01 344.61-0.03 0.05-34.77h398.5l2e-5 16.5h16.53l-0.03006-6.14h184.48l0.03 6.14 17.4-3.56v21.83l343.73-0.01-0.01 996.37z"
      className={isRowActive && currentRoom === 4 ? "is-active" : ""}
      onMouseEnter={() => handlePathEnter(4)}
      onMouseLeave={handlePathLeave}
    />
    <line className="cls-1" x1="1196.1" x2="1207.4" y1="964.64" y2="963.77" />
    <polyline
      className="cls-1"
      points="1215.2 962.02 1227.4 958.54 1230.9 956.8"
    />
    <polyline
      className="cls-1"
      points="1237.8 953.33 1241.3 950.71 1250.9 942.89"
    />
    <polyline
      className="cls-1"
      points="1257 937.67 1264.8 928.09 1266.6 924.61"
    />
    <polyline
      className="cls-1"
      points="1270 916.77 1271.8 914.17 1276.1 901.11"
    />
    <line className="cls-1" x1="1277" x2="1277.9" y1="893.29" y2="882.84" />
    <path
      className="cls-2"
      transform="translate(-134.1 -135.11)"
      d="m1331 1152 4.35 15.67m-10.44-40 4.35 15.65m-10.44-39.14 4.35 15.66m-10.43-40 4.34 16.53m-10.45-40 4.36 15.67m-10.44-40 4.35 16.53m-9.57-35.68 3.48 11.31"
    />
    <line className="cls-2" x1="1203.9" x2="1206.5" y1="1040.3" y2="1051.7" />
    <path
      className="cls-2"
      transform="translate(-134.1 -135.11)"
      d="m1407.6 1156.3 7.82 13.93m-20.87-34.81 8.7 13.93m-20.88-35.68 7.82 14.79m-20.88-35.68 8.7 13.92m-21.76-34.8 8.71 13.92m-20.88-35.67 8.7 14.79m-21.8-35.69 8.71 13.93m-18.28-30.46 5.22 9.58"
    />
    <line className="cls-2" x1="1285.7" x2="1291.8" y1="1042.1" y2="1051.7" />
    <path
      className="cls-2"
      transform="translate(-134.1 -135.11)"
      d="m1040.4 1046.7v-16.54m0 40.91v-16.53m0 40.9v-16.55m0 41.78v-16.53m0 40.89v-16.53m0 40.9v-15.66m0 33.07v-8.71"
    />
    <line className="cls-2" x1="906.3" x2="906.3" y1="886.32" y2="877.61" />
    <path
      className="cls-2"
      transform="translate(-134.1 -135.11)"
      d="m1096.1 1046.7v-16.54m0 40.91v-16.53m0 40.9v-16.55m0 41.78v-16.53m0 40.89v-16.53m0 40.9v-15.66m0 33.07v-8.71"
    />
    <line className="cls-2" x1="961.99" x2="961.99" y1="886.32" y2="877.61" />
    <path
      className="cls-2"
      transform="translate(-134.1 -135.11)"
      d="m1150.9 1046.7v-16.54m0 40.91v-16.53m0 40.9v-16.55m0 41.78v-16.53m0 40.89v-16.53m0 40.9v-15.66m0 33.07v-8.71"
    />
    <line className="cls-2" x1="1016.8" x2="1016.8" y1="886.32" y2="877.61" />
    <path
      className="cls-2"
      transform="translate(-134.1 -135.11)"
      d="m1206.6 1046.7v-16.54m0 40.91v-16.53m0 40.9v-16.55m0 41.78v-16.53m0 40.89v-16.53m0 40.9v-15.66m0 33.07v-8.71"
    />
    <line className="cls-2" x1="1072.5" x2="1072.5" y1="886.32" y2="877.61" />
    <path
      className="cls-2"
      transform="translate(-134.1 -135.11)"
      d="m1262.3 1046.7v-16.54m0 40.91v-16.53m0 40.9v-16.55m0 41.78v-16.53m0 40.89v-16.53m0 40.9v-15.66m0 33.07v-8.71"
    />
    <line className="cls-2" x1="1128.2" x2="1128.2" y1="886.32" y2="877.61" />
    <path
      className="cls-1"
      transform="translate(-134.1 -135.11)"
      d="m418.22 1176.3-7-83.54m-85.22 83.54 7-83.54m-123.57 83.54 7-83.54m85.27 83.54-7-83.54"
    />
    <path
      className="cls-3"
      transform="translate(-134.1 -135.11)"
      d="M154.55,1186.77V632.44M138,1186.77V632.44"
    />
    <line className="cls-4" x1="1360.5" x2="1360.5" y1="55.3" y2="1051.7" />
    <path
      className="cls-3"
      transform="translate(-134.1 -135.11)"
      d="m144.11 1171.1v-152.28m4.35 152.28v-152.28"
    />
    <line className="cls-4" x1="1360.5" x2="1360.5" y1="55.3" y2="1051.7" />
    <path
      className="cls-1"
      transform="translate(-134.1 -135.11)"
      d="m1093.5 2011.7-28.71 28.72m0-51.35h28.71"
    />
    <path
      className="cls-1"
      transform="translate(-134.1 -135.11)"
      d="M620.1,1239l123.56,205.37m-123.56,0L743.66,1239"
    />
    <line className="cls-5" x1="481.65" x2="486" y1="1695.6" y2="1695.6" />
    <line className="cls-5" x1="481.65" x2="486" y1="1623.4" y2="1623.4" />
    <line className="cls-5" x1="537.33" x2="537.33" y1="1621.6" y2="1623.4" />
    <rect
      className="cls-1"
      x="930.67"
      y="1876.6"
      width="28.72"
      height="28.71"
    />
    <path
      className="cls-4"
      transform="translate(-134.1 -135.11)"
      d="M1093.48,1989.07h.87m259.32,51.35H1546"
    />
    <line className="cls-5" x1="945.46" x2="945.46" y1="1346.6" y2="1350.1" />
    <line className="cls-5" x1="933.27" x2="930.67" y1="1298.8" y2="1298.8" />
    <line className="cls-6" x1="56.12" x2="327.67" y1="1854" y2="1854" />
    <polyline className="cls-7" points="45.68 1515.5 56.12 1515.5 56.12 1854" />
    <path
      className="cls-4"
      transform="translate(-134.1 -135.11)"
      d="m1494.6 1239z"
    />
    <path
      className="cls-1"
      transform="translate(-134.1 -135.11)"
      d="M620.1,1444.34H743.66m-86.14,67L646.21,1500m11.31,0-11.31,11.32m13.92-5.22H643.6m67.87,7.83v-16.53m-7.84,8.7h16.55m-14.8-6.1,12.18,11.32m-12.18,0L717.56,1500m-30.45,0-11.31,11.32m11.31,0L675.8,1500m6.09,13.93v-16.53m7.82,8.7H673.18M651.43,1514v-16.53m-66.14,491.65H462.6m0-123.56H565.28M189.36,1551.37H312.93"
    />
    <polyline className="cls-1" points="486 1103.9 486 1431.9 609.56 1431.9" />
    <polyline
      className="cls-1"
      points="178.82 1380.6 178.82 1730.4 486 1730.4"
    />
    <polyline
      className="cls-1"
      points="906.3 1113.4 882.8 1115.2 860.17 1120.4 837.56 1128.2 817.53 1139.5 798.4 1154.3 781.86 1170.9 767.93 1189.1 756.62 1210 748.79 1231.8 743.57 1254.4 741.83 1277.9"
    />
    <path
      className="cls-2"
      transform="translate(-134.1 -135.11)"
      d="m875.93 1413h164.47m-164.47-8.7h164.47"
    />
    <line className="cls-4" x1="930.67" x2="930.67" y1="1350.1" y2="1288.3" />
    <path
      className="cls-8"
      transform="translate(-134.1 -135.11)"
      d="m768 1989.1-123.54-206.23m123.57 0-123.57 206.23"
    />
    <line className="cls-3" x1="688.75" x2="688.75" y1="1627.7" y2="1647.7" />
    <rect
      className="cls-2"
      x="678.31"
      y="1627.7"
      width="10.44"
      height="20.02"
    />
    <path
      className="cls-3"
      transform="translate(-134.1 -135.11)"
      d="M822.86,1762.82v20m-10.45,0H768m0-20h44.38"
    />
    <polyline
      className="cls-1"
      points="688.75 1647.7 689.63 1671.2 694.85 1694.7 703.54 1716.5 714.86 1737.4 728.77 1755.6 745.32 1772.2 763.59 1786.1 784.48 1797.4 806.22 1806.1 829.72 1811.3 853.22 1812.2"
    />
    <path
      className="cls-2"
      transform="translate(-134.1 -135.11)"
      d="m977.75 1947.3v-164.47m9.57 164.47v-164.47"
    />
    <line className="cls-1" x1="1092.5" x2="1091.6" y1="1323.2" y2="1336.2" />
    <polyline
      className="cls-1"
      points="1105.6 1337.9 1107.3 1340.6 1109.9 1341.4 1112.5 1339.7 1113.4 1337.1 1112.5 1334.5 1109.9 1333.6 1107.3 1334.5 1105.6 1336.2"
    />
    <polyline
      className="cls-1"
      points="1096.9 1324.9 1098.6 1322.3 1097.7 1318.8 1094.3 1317.9 1090.8 1319.7 1089.9 1323.2 1092.5 1325.8"
    />
    <polyline
      className="cls-1"
      points="1092.5 1323.2 1096 1323.2 1097.7 1336.2"
    />
    <line className="cls-1" x1="1092.5" x2="1072.5" y1="1329.2" y2="1329.2" />
    <polyline
      className="cls-1"
      points="1091.6 1336.2 1092.5 1338.8 1094.3 1339.7 1096.9 1338.8 1097.7 1336.2"
    />
    <polyline
      className="cls-1"
      points="1076 1337.9 1077.7 1340.6 1080.3 1340.6 1083 1338.8 1083 1335.3 1080.3 1333.6 1077.7 1334.5 1076 1336.2"
    />
    <polyline
      className="cls-1"
      points="1061.2 1320.5 1063.8 1324.9 1067.3 1328.4 1072.5 1329.2"
    />
    <path
      className="cls-1"
      transform="translate(-134.1 -135.11)"
      d="m1268.4 1426.1-10.46 47m-7-8.7h-20"
    />
    <polyline
      className="cls-1"
      points="1116.9 1329.2 1122.1 1328.4 1125.6 1324.9 1128.2 1319.7"
    />
    <line className="cls-1" x1="1060.3" x2="1128.2" y1="1283.1" y2="1283.1" />
    <polyline
      className="cls-1"
      points="1134.3 1291 1132.6 1285.7 1128.2 1283.1"
    />
    <line className="cls-1" x1="1116.9" x2="1072.5" y1="1344" y2="1344" />
    <polyline
      className="cls-1"
      points="1064.7 1338.8 1066.4 1341.4 1069 1343.2 1072.5 1344"
    />
    <line className="cls-1" x1="1137.8" x2="1140.4" y1="1282.3" y2="1344" />
    <polyline
      className="cls-1"
      points="1116.9 1344 1121.2 1342.3 1123.8 1337.9"
    />
    <polyline
      className="cls-1"
      points="1137.8 1282.3 1135.2 1277.9 1130.8 1276.2"
    />
    <polyline
      className="cls-1"
      points="1134.3 1350.1 1138.6 1348.4 1140.4 1344"
    />
    <path
      className="cls-9"
      transform="translate(-134.1 -135.11)"
      d="m1257.9 1485.2z"
    />
    <line className="cls-1" x1="1049" x2="1050.8" y1="1344.9" y2="1282.3" />
    <polyline
      className="cls-1"
      points="1057.7 1276.2 1053.4 1277.9 1050.8 1282.3"
    />
    <polyline
      className="cls-1"
      points="1049 1344.9 1050.8 1348.4 1055.1 1350.1"
    />
    <line className="cls-1" x1="1064.7" x2="1054.2" y1="1338.8" y2="1291" />
    <polyline
      className="cls-1"
      points="1060.3 1283.1 1056 1285.7 1054.2 1291"
    />
    <path
      className="cls-1"
      transform="translate(-134.1 -135.11)"
      d="m1268.4 1485.2h-79.2m2.61-74h73.1"
    />
    <rect className="cls-2" x="609.56" y="1438.9" width="20.02" height="9.57" />
    <rect
      className="cls-2"
      x="609.56"
      y="1612.9"
      width="20.02"
      height="10.44"
    />
    <polyline
      className="cls-1"
      points="609.56 1448.5 586.07 1450.2 562.58 1455.4 540.82 1463.3 519.94 1474.6 501.66 1489.4 485.13 1505.9 471.21 1524.2 459.9 1545.1 451.19 1566.8 446.84 1589.4 445.11 1612.9"
    />
    <path
      className="cls-2"
      transform="translate(-134.1 -135.11)"
      d="m579.21 1748h164.45m-164.45-8.7h164.45"
    />
    <line className="cls-4" x1="328.5" x2="328.5" y1="1854" y2="1905.3" />
    <rect className="cls-4" x="486" y="1051.6" width="147.93" height="52.21" />
    <polyline
      className="cls-3"
      points="3.92 1872.2 20.45 1872.2 20.45 1888.8 3.92 1888.8 3.92 1872.2 20.45 1872.2"
    />
    <polygon
      className="cls-3"
      points="3.92 1872.2 3.92 1888.8 20.45 1888.8 20.45 1872.2 3.92 1872.2 3.92 1888.8 20.45 1888.8 20.45 1872.2"
    />
    <polyline
      className="cls-3"
      points="20.45 1872.2 20.45 1888.8 3.92 1888.8 3.92 1872.2"
    />
    <polyline
      className="cls-3"
      points="36.98 1888.8 20.45 1888.8 20.45 1905.3 36.98 1905.3 36.98 1888.8 20.45 1888.8 20.45 1905.3 36.98 1905.3 36.98 1888.8 20.45 1888.8"
    />
    <polyline
      className="cls-3"
      points="36.98 1888.8 36.98 1905.3 20.45 1905.3 20.45 1888.8"
    />
    <polygon
      className="cls-3"
      points="328.5 1888.8 311.96 1888.8 311.96 1905.3 328.5 1905.3 328.5 1888.8 311.96 1888.8 311.96 1905.3 328.5 1905.3"
    />
    <line className="cls-4" x1="434.65" x2="328.5" y1="1905.3" y2="1905.3" />
    <line className="cls-4" x1="519.1" x2="412.95" y1="1905.3" y2="1905.3" />
    <polygon
      className="cls-3"
      points="20.45 1535.5 20.45 1518.9 3.92 1518.9 3.92 1535.5 20.45 1535.5 20.45 1518.9 3.92 1518.9 3.92 1535.5"
    />
    <path
      className="cls-3"
      transform="translate(-134.1 -135.11)"
      d="m138 2023.9v-369.83m16.53 369.83v-369.83m-10.44 16.53v336.77m4.35-336.77v336.77"
    />
    <path
      className="cls-4"
      transform="translate(-134.1 -135.11)"
      d="m620.1 1989.1v-230.6m-55.69 282h4.35"
    />
    <rect className="cls-3" x="3.92" y="1888.8" width="16.54" height="16.53" />
    <polyline
      className="cls-4"
      points="55.26 1518.9 3.92 1518.9 55.26 1518.9 3.92 1518.9"
    />
    <rect
      className="cls-3"
      x="311.96"
      y="1888.8"
      width="16.53"
      height="16.53"
    />
    <rect className="cls-3" x="20.45" y="1888.8" width="16.53" height="16.53" />
    <path
      className="cls-3"
      transform="translate(-134.1 -135.11)"
      d="m446.07 2034.3h-275m275-4.34h-275m-16.54-6.1h308.07m-308 16.54h308"
    />
    <line className="cls-4" x1="1219.6" x2="1331.8" y1="1905.3" y2="1905.3" />
    <line className="cls-4" x1="1299.6" x2="1411.9" y1="1905.3" y2="1905.3" />
    <line className="cls-2" x1="55.26" x2="3.92" y1="1103.9" y2="1103.9" />
    <line className="cls-4" x1="1219.6" x2="1360.5" y1="1854" y2="1854" />
    <line className="cls-4" x1="1219.6" x2="1219.6" y1="1854" y2="1905.3" />
    <polygon
      className="cls-3"
      points="1071.6 1888.8 1055.1 1888.8 1055.1 1905.3 1071.6 1905.3 1071.6 1888.8 1055.1 1888.8 1055.1 1905.3 1071.6 1905.3"
    />
    <polyline
      className="cls-4"
      points="1055.1 1854 1055.1 1905.3 1055.1 1854 1055.1 1905.3"
    />
    <polygon
      className="cls-3"
      points="1219.6 1888.8 1203 1888.8 1203 1905.3 1219.6 1905.3 1219.6 1888.8 1203 1888.8 1203 1905.3 1219.6 1905.3"
    />
    <path
      className="cls-3"
      transform="translate(-134.1 -135.11)"
      d="m1183.4 2040.4h164.46m-164.46-16.54h164.46m-142.09 10.44h131.39m-131.39-4.32h131.39"
    />
    <line className="cls-4" x1="862.79" x2="930.67" y1="1905.3" y2="1905.3" />
    <polyline
      className="cls-4"
      points="906.3 1854 906.3 1623.4 930.67 1623.4 930.67 1854"
    />
    <path
      className="cls-4"
      transform="translate(-134.1 -135.11)"
      d="M743.66,1574V1239M768,1574V1186.77"
    />
    <polyline
      className="cls-4"
      points="633.93 1647.7 510.36 1647.7 510.36 1854"
    />
    <path
      className="cls-4"
      transform="translate(-134.1 -135.11)"
      d="M768,1574H743.66M768,1758.47H620.1"
    />
    <polyline
      className="cls-4"
      points="906.3 1103.9 906.3 1051.7 1360.5 1051.7"
    />
    <path
      className="cls-4"
      transform="translate(-134.1 -135.11)"
      d="m1064.8 1574v-64.4m-24.37 64.4v-150.55"
    />
    <line className="cls-1" x1="1290.9" x2="1292.7" y1="1820" y2="1836.6" />
    <polyline
      className="cls-1"
      points="1274.4 1836.6 1272.6 1834 1269.2 1833.1 1266.6 1834 1264.8 1837.4 1266.6 1840.9 1269.2 1842.6 1272.6 1841.8 1274.4 1838.3"
    />
    <polyline
      className="cls-1"
      points="1291.8 1822.6 1293.5 1819.2 1292.7 1815.7 1289.2 1813.1 1284.8 1814.8 1283.1 1819.2 1285.7 1822.6"
    />
    <polyline
      className="cls-1"
      points="1290.9 1820 1286.6 1820 1284.8 1836.6"
    />
    <line className="cls-1" x1="1291.8" x2="1316.2" y1="1827.9" y2="1827.9" />
    <polyline
      className="cls-1"
      points="1284.8 1836.6 1285.7 1840 1288.3 1840.9 1291.8 1840 1292.7 1836.6"
    />
    <polyline
      className="cls-1"
      points="1311.8 1836.6 1310.1 1834 1306.6 1833.1 1303.1 1835.7 1303.1 1839.2 1306.6 1841.8 1310.1 1841.8 1311.8 1838.3"
    />
    <polyline
      className="cls-1"
      points="1316.2 1827.9 1322.2 1826.1 1327.5 1822.6 1330.1 1816.6"
    />
    <path
      className="cls-1"
      transform="translate(-134.1 -135.11)"
      d="m1372.8 1914.2 13 59.17m8.71-10.44h25.23"
    />
    <polyline
      className="cls-1"
      points="1246.5 1815.7 1249.1 1821.8 1254.4 1826.1 1260.5 1827.9"
    />
    <polyline
      className="cls-1"
      points="1330.9 1770.4 1246.5 1770.4 1242.2 1772.2 1239.6 1774.8 1238.7 1779.1"
    />
    <polyline
      className="cls-1"
      points="1260.5 1846.1 1316.2 1846.1 1320.5 1845.3 1323.1 1842.6 1325.7 1839.2"
    />
    <line className="cls-1" x1="1234.4" x2="1230.9" y1="1768.7" y2="1846.1" />
    <polyline
      className="cls-1"
      points="1251.8 1838.3 1253.5 1842.6 1256.1 1845.3 1260.5 1846.1"
    />
    <polyline
      className="cls-1"
      points="1243.1 1761.7 1238.7 1761.7 1236.1 1764.3 1234.4 1768.7"
    />
    <polyline
      className="cls-1"
      points="1230.9 1846.1 1232.6 1849.6 1235.2 1852.2 1238.7 1854"
    />
    <polyline
      className="cls-1"
      points="1345.7 1847 1343.1 1769.6 1340.5 1764.3 1335.3 1761.7"
    />
    <polyline
      className="cls-1"
      points="1325.7 1839.2 1338.8 1780 1337.9 1775.6 1335.3 1772.2 1330.9 1770.4"
    />
    <path
      className="cls-1"
      transform="translate(-134.1 -135.11)"
      d="m1372.8 1989.1h109.64m-13-92.24h-92.23"
    />
    <rect className="cls-2" x="906.3" y="1612.9" width="20.89" height="10.44" />
    <line className="cls-1" x1="906.3" x2="906.3" y1="1448.5" y2="1438.9" />
    <rect className="cls-2" x="906.3" y="1438.9" width="20.89" height="9.57" />
    <path
      className="cls-4"
      transform="translate(-134.1 -135.11)"
      d="M1064.77,1509.6h429.87m-429.87-24.37h429.87M1064.77,1239h.86m-25.23,335h24.37"
    />
    <polyline
      className="cls-1"
      points="1340.5 1163 1327.5 1163.9 1314.4 1167.4"
    />
    <polyline
      className="cls-1"
      points="1277 1177 1271.8 1176.1 1259.6 1174.4"
    />
    <line className="cls-1" x1="1271.8" x2="1277" y1="1232.7" y2="1230.9" />
    <polyline
      className="cls-1"
      points="1259.6 1174.4 1243.9 1177 1231.8 1183.1 1222.2 1192.6 1219.6 1203.9 1222.2 1215.2 1231.8 1225.7 1243.9 1231.8 1259.6 1233.5 1271.8 1232.7"
    />
    <path
      className="cls-1"
      transform="translate(-134.1 -135.11)"
      d="m1427.6 1332.1v13.92m7.84-34.81 9.56 1.74"
    />
    <polygon
      className="cls-1"
      points="1332.7 1203.9 1331.8 1201.3 1328.3 1200.5 1325.7 1201.3 1324.9 1203.9 1325.7 1207.4 1328.3 1208.3 1331.8 1207.4"
    />
    <polyline
      className="cls-1"
      points="1310.9 1235.3 1311.8 1238.7 1314.4 1240.5"
    />
    <line className="cls-1" x1="1310.9" x2="1301.4" y1="1230.9" y2="1231.8" />
    <polyline
      className="cls-1"
      points="1340.5 1245.7 1344.9 1244 1346.6 1239.6"
    />
    <polyline
      className="cls-1"
      points="1314.4 1240.5 1327.5 1244 1340.5 1245.7"
    />
    <path
      className="cls-1"
      transform="translate(-134.1 -135.11)"
      d="m1445 1370.4v-61.78m35.69-4.36v70.49"
    />
    <polyline
      className="cls-1"
      points="1293.5 1197 1290.9 1188.3 1285.7 1181.3 1277 1177"
    />
    <line className="cls-1" x1="1273.5" x2="1301.4" y1="1238.7" y2="1231.8" />
    <polyline
      className="cls-1"
      points="1277 1230.9 1285.7 1227.4 1290.9 1220.5 1293.5 1210.9"
    />
    <polyline
      className="cls-1"
      points="1291.8 1190 1282.2 1190.9 1274.4 1194.4 1268.3 1199.6 1266.6 1203.9 1268.3 1209.2 1274.4 1214.4 1282.2 1217 1291.8 1217.9"
    />
    <line className="cls-1" x1="1273.5" x2="1301.4" y1="1170" y2="1176.1" />
    <polyline
      className="cls-1"
      points="1259.6 1168.3 1242.2 1170.9 1228.3 1177.8 1217 1190 1214.4 1197 1212.6 1203.9 1214.4 1211.8 1217 1218.7 1228.3 1230.9 1242.2 1237.9 1259.6 1240.5 1273.5 1238.7"
    />
    <polyline
      className="cls-1"
      points="1346.6 1169.1 1344.9 1164.8 1340.5 1163"
    />
    <polyline
      className="cls-1"
      points="1314.4 1167.4 1311.8 1170 1310.9 1173.5"
    />
    <line className="cls-1" x1="1273.5" x2="1259.6" y1="1170" y2="1168.2" />
    <line className="cls-4" x1="906.3" x2="930.67" y1="1103.9" y2="1103.9" />
    <line className="cls-1" x1="906.3" x2="906.3" y1="1113.4" y2="1103.9" />
    <rect className="cls-2" x="906.3" y="1103.9" width="20.89" height="9.57" />
    <line className="cls-4" x1="930.67" x2="906.3" y1="1288.3" y2="1288.3" />
    <rect className="cls-2" x="906.3" y="1277.9" width="20.89" height="10.44" />
    <path
      className="cls-1"
      transform="translate(-134.1 -135.11)"
      d="m1475.5 1239h7m-18.27 0h8.7"
    />
    <polyline
      className="cls-1"
      points="1351.8 1103.9 1360.5 1103.9 1360.5 1112.6"
    />
    <line className="cls-1" x1="1360.5" x2="1360.5" y1="1115.2" y2="1122.1" />
    <line className="cls-1" x1="1359.7" x2="1357.9" y1="1118.7" y2="1111.7" />
    <line className="cls-1" x1="1344.9" x2="1337.9" y1="1104.7" y2="1106.5" />
    <polygon
      className="cls-1"
      points="1342.3 1443.2 1340.5 1429.3 1336.2 1416.3 1328.3 1404.1 1318.8 1394.5 1307.4 1387.5 1294.4 1383.2 1280.5 1381.5 1266.6 1383.2 1253.5 1387.5 1242.2 1394.5 1231.8 1404.1 1224.8 1416.3 1220.4 1429.3 1218.7 1443.2 1220.4 1456.3 1224.8 1469.3 1231.8 1481.5 1242.2 1491.1 1253.5 1498.1 1266.6 1503.3 1280.5 1504.2 1294.4 1503.3 1307.4 1498.1 1318.8 1491.1 1328.3 1481.5 1336.2 1469.3 1340.5 1456.3"
    />
    <polygon
      className="cls-1"
      points="1027.2 1834.8 1025.5 1830.5 1021.2 1828.7 1016.8 1830.5 1015.1 1834.8 1016.8 1839.2 1021.2 1841.8 1025.5 1839.2"
    />
    <path
      className="cls-1"
      transform="translate(-134.1 -135.11)"
      d="m1188.3 1957.8h-119.21m119.21 25.25h-123.56m78.31-13h-69.61m69.61-3.49h-69.61"
    />
    <polygon
      className="cls-1"
      points="1043.8 1834.8 1042 1830.5 1037.7 1828.7 1033.4 1830.5 1031.6 1834.8 1033.4 1839.2 1037.7 1841.8 1042 1839.2"
    />
    <rect
      className="cls-1"
      x="930.67"
      y="1728.7"
      width="123.57"
      height="125.3"
    />
    <path
      className="cls-4"
      transform="translate(-134.1 -135.11)"
      d="m1494.6 1403.4v585.63m-497.75 0h67.88"
    />
    <line className="cls-1" x1="75.27" x2="167.51" y1="1041.2" y2="1041.2" />
    <rect
      className="cls-1"
      x="55.26"
      y="1051.7"
      width="246.26"
      height="164.46"
    />
    <path
      className="cls-1"
      transform="translate(-134.1 -135.11)"
      d="M418.22,1362.54H326m92.24-186.22H326"
    />
    <polyline
      className="cls-1"
      points="202.32 1304.9 219.73 1311 237.99 1312.7 256.27 1311 273.68 1304.9"
    />
    <polyline
      className="cls-1"
      points="198.83 1311 217.98 1317.1 237.99 1319.7 258.01 1317.1 277.15 1311"
    />
    <line className="cls-1" x1="191.88" x2="198.83" y1="1227.4" y2="1311" />
    <polyline
      className="cls-1"
      points="75.27 1227.4 167.51 1227.4 160.54 1311"
    />
    <polyline
      className="cls-1"
      points="82.24 1311 101.37 1317.1 121.38 1319.7 141.41 1317.1 160.54 1311"
    />
    <polyline
      className="cls-1"
      points="85.71 1304.9 103.12 1311 121.38 1312.7 139.66 1311 157.07 1304.9"
    />
    <path
      className="cls-1"
      transform="translate(-134.1 -135.11)"
      d="m418.22 1362.5-7 83.53m-201.89-83.53 7 83.53"
    />
    <line className="cls-1" x1="1359.7" x2="1357.9" y1="1118.7" y2="1111.7" />
    <line className="cls-1" x1="1344.9" x2="1337.9" y1="1104.7" y2="1106.5" />
    <path
      className="cls-1"
      transform="translate(-134.1 -135.11)"
      d="m1482.4 1239h-7m19.14 0h-8.7"
    />
    <line className="cls-1" x1="1360.5" x2="1360.5" y1="1122.1" y2="1115.2" />
    <line className="cls-1" x1="1360.5" x2="1360.5" y1="1112.6" y2="1103.9" />
    <path
      className="cls-4"
      transform="translate(-134.1 -135.11)"
      d="M791.53,1989.07H462.6m328.93,51.35H620.1"
    />
    <path
      className="cls-4"
      transform="translate(-134.1 -135.11)"
      d="m138 1186.8v467.28m51.34-467.28v467.28"
    />
    <line className="cls-4" x1="657.43" x2="651.34" y1="1905.3" y2="1905.3" />
    <path
      className="cls-4"
      transform="translate(-134.1 -135.11)"
      d="m189.36 1186.8z"
    />
    <polygon
      className="cls-3"
      points="20.45 1051.7 20.45 1036 3.92 1036 3.92 1051.7 20.45 1051.7 20.45 1036 3.92 1036 3.92 1051.7"
    />
    <line className="cls-4" x1="55.26" x2="3.92" y1="1051.7" y2="1051.7" />
    <rect
      className="cls-2"
      x="657.43"
      y="1884.4"
      width="10.44"
      height="20.88"
    />
    <rect className="cls-2" x="853.22" y="1884.4" width="9.57" height="20.88" />
    <line className="cls-4" x1="657.43" x2="657.43" y1="1854" y2="1905.3" />
    <line className="cls-1" x1="657.43" x2="862.79" y1="1905.3" y2="1905.3" />
    <line className="cls-4" x1="633.93" x2="633.93" y1="1647.7" y2="1623.4" />
    <line className="cls-4" x1="1360.5" x2="924.57" y1="1103.9" y2="1103.9" />
    <line className="cls-1" x1="1277.9" x2="1277.9" y1="861.95" y2="882.84" />
    <path
      className="cls-2"
      transform="translate(-134.1 -135.11)"
      d="m1459.8 1082.3 13.93 7.83m-35.68-20 14.8 7.83m-36.55-19.15 14.8 7.83m-36.55-20 14.79 7.84m-36.55-19.15 14.8 7.83m-36.56-19.2 14.8 7.84m-35.68-19.15 13.93 7"
    />
    <line className="cls-2" x1="1347.5" x2="1360.5" y1="958.54" y2="966.37" />
    <line className="cls-3" x1="798.4" x2="399.85" y1="3.92" y2="3.92" />
    <polygon
      className="cls-3"
      points="814.93 20.53 798.4 20.53 798.4 37.03 814.93 37.03 814.93 20.53 798.4 20.53 798.4 37.03 814.93 37.03"
    />
    <path
      className="cls-3"
      transform="translate(-134.1 -135.11)"
      d="M949,166h184.48M949,161.65h184.48"
    />
    <polygon
      className="cls-3"
      points="1015.9 20.53 999.41 20.53 999.41 37.03 1015.9 37.03 1015.9 20.53 999.41 20.53 999.41 37.03 1015.9 37.03"
    />
    <rect
      className="cls-1"
      x="1257.8"
      y="260.62"
      width="102.68"
      height="205.36"
    />
    <path
      className="cls-1"
      transform="translate(-134.1 -135.11)"
      d="M1494.64,190.4,1392,395.81m102.68,0L1392,601.1m0-205.29L1494.64,601.1"
    />
    <polyline
      className="cls-1"
      points="1257.8 55.3 1360.5 260.7 1257.8 260.7"
    />
    <polyline
      className="cls-1"
      points="1360.5 260.7 1360.5 55.3 1257.8 55.3 1257.8 260.7"
    />
    <path
      className="cls-2"
      transform="translate(-134.1 -135.11)"
      d="M1459.82,932.67l13.93-7.84M1438.07,944l14.8-7.84m-36.55,20,14.8-7.84m-36.55,19.15,14.79-7.84m-36.55,20,14.8-7.82M1351.05,991l14.8-7.84m-35.68,19.14,13.93-7.83M1040.4,827.36H1318"
    />
    <line className="cls-2" x1="1347.5" x2="1360.5" y1="786.25" y2="778.42" />
    <path
      className="cls-2"
      transform="translate(-134.1 -135.11)"
      d="M1407.62,858.71l7.82-13.91m-20.87,34.79,8.7-13.93m-20.88,34.81,7.82-13.92m-20.88,35.68,8.7-14.79m-21.76,35.67,8.71-13.92M1344.1,964l8.7-13.93M1331,985.74l8.71-14.79m-18.28,31.33,5.22-9.57"
    />
    <line className="cls-2" x1="1285.7" x2="1291.8" y1="701.8" y2="692.25" />
    <path
      className="cls-2"
      transform="translate(-134.1 -135.11)"
      d="m1331 863.07 4.35-15.68m-10.44 40 4.35-16.53m-10.44 40 4.35-15.66m-10.43 39.16 4.34-15.66m-10.45 40 4.36-15.66m-10.44 39.16 4.35-15.66m-9.57 35.67 3.48-12.18"
    />
    <line className="cls-2" x1="1203.9" x2="1206.5" y1="704.51" y2="692.25" />
    <line className="cls-4" x1="1072.5" x2="1183.9" y1="867.17" y2="692.25" />
    <path
      className="cls-2"
      transform="translate(-134.1 -135.11)"
      d="M1040.4,1002.28V827.36m55.69,174.92V827.36m110.52,0v174.92m-55.7-174.92v174.92"
    />
    <line className="cls-1" x1="1277.9" x2="1277" y1="861.95" y2="850.64" />
    <polyline
      className="cls-1"
      points="1276.1 842.81 1271.8 830.63 1270 827.14"
    />
    <polyline
      className="cls-1"
      points="1266.6 820.18 1264.8 816.71 1257 807.13"
    />
    <polyline
      className="cls-1"
      points="1250.9 801.03 1241.3 793.21 1237.8 791.47"
    />
    <polyline
      className="cls-1"
      points="1230.9 787.99 1227.4 786.25 1215.2 781.9"
    />
    <line className="cls-1" x1="1207.4" x2="1196.1" y1="781.03" y2="780.15" />
    <path
      className="cls-2"
      transform="translate(-134.1 -135.11)"
      d="m1262.3 962.25v16.54m0-40.9v16.53m0-39.16v14.79"
    />
    <line className="cls-2" x1="1128.2" x2="1128.2" y1="852.38" y2="867.17" />
    <path
      className="cls-2"
      transform="translate(-134.1 -135.11)"
      d="m1262.3 915.26v-87.9m-221.9 10.49h271.5m-271.5 154h173.17"
    />
    <path
      className="cls-1"
      transform="translate(-134.1 -135.11)"
      d="m1288.4 915.26h15.66m-41.77 0h17.4"
    />
    <line className="cls-1" x1="1178.7" x2="1196.1" y1="780.15" y2="780.15" />
    <path
      className="cls-2"
      transform="translate(-134.1 -135.11)"
      d="m1297.1 1002.3h16.54m-40.9 0h16.54m-40.91 0h15.63m-40.89 0h16.54m-33.07 0h8.7m114.86 10.44v-10.44"
    />
    <line className="cls-2" x1="1188.2" x2="1196.1" y1="867.17" y2="867.17" />
    <path
      className="cls-2"
      transform="translate(-134.1 -135.11)"
      d="m1288.4 1012.7h15.66m-40.9 0h16.53m-40.9 0h16.54m-40.91 0h15.67m-40.9 0h16.54m-40.91 0h16.55m-40.91 0h15.67m-40.91 0h16.55m-40.91 0h16.54m-41.78 0h16.55m-41.78 0h17.4"
    />
    <line className="cls-2" x1="1178.7" x2="1196.1" y1="877.61" y2="877.61" />
    <path
      className="cls-2"
      transform="translate(-134.1 -135.11)"
      d="m1453.7 1007.5h16.54m-41.78 0h16.5m-40.89 0h16.54m-40.91 0h16.53m-41.76 0h16.53m-40.9 0h16.53m-306.3-5.22h166.21"
    />
    <line className="cls-2" x1="1344" x2="1360.5" y1="872.39" y2="872.39" />
    <path
      className="cls-1"
      transform="translate(-134.1 -135.11)"
      d="m1288.4 1099.8h15.66m-40.9 0h16.53m-40.9 0h16.54m-40.91 0h15.67m-40.9 0h16.54m-40.91 0h16.55m-40.91 0h15.67m-40.91 0h16.55m-40.91 0h16.54m-41.78 0h16.55m-41.78 0h17.4"
    />
    <line className="cls-1" x1="1178.7" x2="1196.1" y1="964.64" y2="964.64" />
    <polyline
      className="cls-1"
      points="278.9 712.29 278.9 778.42 402.46 778.42 402.46 712.29"
    />
    <polyline
      className="cls-1"
      points="258.89 712.29 258.89 799.3 423.34 799.3 423.34 712.29"
    />
    <rect
      className="cls-1"
      x="326.75"
      y="446.83"
      width="247.14"
      height="123.57"
    />
    <path
      className="cls-1"
      transform="translate(-134.1 -135.11)"
      d="M653.17,367.88V512.36M515.68,367.88V512.36"
    />
    <rect
      className="cls-1"
      x="268.46"
      y="634.84"
      width="144.45"
      height="154.02"
    />
    <polyline
      className="cls-1"
      points="641.76 712.29 641.76 799.3 477.3 799.3 477.3 712.29"
    />
    <polyline
      className="cls-1"
      points="621.75 712.29 621.75 778.42 498.18 778.42 498.18 712.29"
    />
    <polyline
      className="cls-1"
      points="666.13 299.83 666.13 212.74 234.52 212.74 234.52 299.83"
    />
    <polyline
      className="cls-1"
      points="645.24 299.83 645.24 232.77 255.4 232.77 255.4 299.83"
    />
    <rect
      className="cls-1"
      x="244.96"
      y="223.23"
      width="410.73"
      height="154.02"
    />
    <rect
      className="cls-1"
      x="487.73"
      y="634.84"
      width="143.58"
      height="154.02"
    />
    <polyline className="cls-4" points="1015.9 3.92 1015.9 55.3 1360.5 55.3" />
    <polygon
      className="cls-3"
      points="814.93 3.92 798.4 3.92 798.4 20.53 814.93 20.53 814.93 3.92 798.4 3.92 798.4 20.53 814.93 20.53"
    />
    <line className="cls-4" x1="1411.9" x2="1015.9" y1="3.92" y2="3.92" />
    <path
      className="cls-1"
      transform="translate(-134.1 -135.11)"
      d="m1150 162.59 23.5-23.57m-23.5 8.72 8.71-8.72"
    />
    <polyline
      className="cls-1"
      points="1015.9 42.22 1028.1 29.13 1054.2 3.92"
    />
    <polyline className="cls-1" points="1016.8 55.3 1042.9 29.13 1064.7 7.45" />
    <polyline
      className="cls-1"
      points="1031.6 55.3 1057.7 29.13 1064.7 21.36"
    />
    <path
      className="cls-1"
      transform="translate(-134.1 -135.11)"
      d="M1195.3,182.63l3.47-3.54m-11.31,11.31,3.49-3.53m4.35-18.27,3.47-3.42m-11.31,10.38,3.49-3.42m-10.44,10.49,3.47-3.54m-11.31,11.31,4.35-3.53m18.28-33,3.47-3.53m-11.31,10.48,3.49-3.53m-10.44,11.31,3.47-3.42m-11.31,10.38,4.35-3.42m-11.32,10.49,3.49-3.54m-10.44,11.31,3.48-3.53M1195.3,139h.87m-8.71,7.9,3.49-3.54m-10.44,10.49,3.47-3.53m-11.31,10.48,4.35-3.53M1165.7,168.6l3.49-3.42m-10.44,10.38,3.48-3.42m-11.32,10.49,4.35-3.54M1180.51,139h.87m-8.71,7.9,4.35-3.54m-11.32,10.49,3.49-3.53m-10.44,10.48,3.48-3.53m-11.32,11.31,4.35-3.42M1165.7,139h.88m-7.83,7.9,3.48-3.54m-11.32,10.49,4.35-3.53M1150.91,139h.88m42.63,51.38,4.35-4.36m-18.26,4.36,18.26-19.2"
    />
    <line className="cls-2" x1="1064.7" x2="1064.7" y1="3.92" y2="55.3" />
    <path
      className="cls-3"
      transform="translate(-134.1 -135.11)"
      d="m144.11 648.94v353.34m4.35-353.34v353.34"
    />
    <polygon
      className="cls-3"
      points="20.45 867.17 20.45 883.7 3.92 883.7 3.92 867.17 20.45 867.17 20.45 883.7 3.92 883.7 3.92 867.17"
    />
    <line className="cls-4" x1="55.26" x2="3.92" y1="497.33" y2="497.33" />
    <polygon
      className="cls-3"
      points="20.45 513.83 20.45 497.33 3.92 497.33 3.92 513.83 20.45 513.83 20.45 497.33 3.92 497.33 3.92 513.83"
    />
    <path
      className="cls-4"
      transform="translate(-134.1 -135.11)"
      d="m189.36 632.44z"
    />
    <polyline
      className="cls-1"
      points="160.54 957.68 141.41 950.71 121.38 948.97 101.37 950.71 82.24 957.68"
    />
    <polyline
      className="cls-1"
      points="157.07 963.77 139.66 957.68 121.38 955.93 103.12 957.68 85.71 963.77"
    />
    <polyline
      className="cls-1"
      points="273.68 963.77 256.27 957.68 237.99 955.93 219.73 957.68 202.32 963.77"
    />
    <polyline
      className="cls-1"
      points="277.15 957.68 258.01 950.71 237.99 948.97 217.98 950.71 198.83 957.68"
    />
    <line className="cls-4" x1="399.85" x2="3.92" y1="3.92" y2="3.92" />
    <polyline className="cls-4" points="55.26 55.3 399.85 55.3 399.85 3.92" />
    <path
      className="cls-4"
      transform="translate(-134.1 -135.11)"
      d="m138 139v493.44m51.34-442v442"
    />
    <polygon
      className="cls-3"
      points="416.39 3.92 399.85 3.92 399.85 20.53 416.39 20.53 416.39 3.92 399.85 3.92 399.85 20.53 416.39 20.53"
    />
    <polygon
      className="cls-3"
      points="615.65 3.92 599.12 3.92 599.12 20.53 615.65 20.53 615.65 3.92 599.12 3.92 599.12 20.53 615.65 20.53"
    />
    <path
      className="cls-3"
      transform="translate(-134.1 -135.11)"
      d="m550.49 149.51h182.73m-182.73-4.36h182.73"
    />
    <line className="cls-2" x1="349.38" x2="349.38" y1="3.92" y2="55.3" />
    <path
      className="cls-1"
      transform="translate(-134.1 -135.11)"
      d="M483.48,162.59,507.84,139m-24.36,8.72,9.57-8.72"
    />
    <polyline
      className="cls-1"
      points="349.38 42.22 362.44 29.13 387.67 3.92"
    />
    <polyline className="cls-1" points="351.13 55.3 377.23 29.13 398.98 7.45" />
    <polyline
      className="cls-1"
      points="365.91 55.3 391.14 29.13 398.98 21.36"
    />
    <path
      className="cls-1"
      transform="translate(-134.1 -135.11)"
      d="M528.74,182.63l3.47-3.54M521.77,190.4l3.48-3.53m3.49-18.27,3.47-3.42m-10.44,10.38,3.48-3.42m-10.44,10.49,3.47-3.54M507,190.4l3.48-3.53m18.28-33,3.47-3.53m-10.44,10.48,3.48-3.53M514.81,168.6l3.47-3.42M507,175.56l3.48-3.42M500,182.63l3.47-3.54M492.18,190.4l4.36-3.53M528.74,139h.87m-7.84,7.9,3.48-3.54m-10.44,10.49,3.47-3.53M507,160.82l3.48-3.53M500,168.6l3.47-3.42m-11.31,10.38,4.36-3.42m-11.31,10.49,3.47-3.54M514.81,139h.87m-8.7,7.9,3.48-3.54M500,153.87l3.47-3.53m-11.31,10.48,4.36-3.53M485.23,168.6l3.47-3.42M500,139h.87m-8.71,7.9,4.36-3.54m-11.31,10.49,3.47-3.53M485.23,139h.86m42.65,51.38,4.35-4.36m-19.15,4.36,19.15-19.2"
    />
    <path
      className="cls-2"
      transform="translate(-134.1 -135.11)"
      d="M1459.82,827.36h16.55m-40.9,0H1452m-40.91,0h16.54m-41.77,0h16.54m-40.91,0H1378m-40.9,0h15.67m-34.8,0h10.43"
    />
    <line className="cls-2" x1="1351" x2="1360.5" y1="692.25" y2="692.25" />
    <path
      className="cls-3"
      transform="translate(-134.1 -135.11)"
      d="M749.76,149.51H932.5m-182.74-4.36H932.5m201,10.49H534"
    />
    <line className="cls-4" x1="1411.9" x2="1411.9" y1="3.92" y2="1905.3" />
    <polyline
      className="cls-1"
      points="1337.9 1854 1342.3 1852.2 1344.9 1849.6 1345.7 1846.1"
    />
    <path
      className="cls-4"
      transform="translate(-134.1 -135.11)"
      d="m1093.5 2040.4h95.72m-95.72-51.35h94.85m-191.44 51.35v-51.35"
    />
    <rect
      className="cls-2"
      x="678.31"
      y="1627.7"
      width="10.44"
      height="20.02"
    />
    <polygon
      className="cls-1"
      points="1193.5 1443.2 1191.7 1429.3 1187.4 1416.3 1179.5 1404.1 1170 1394.5 1157.8 1387.5 1144.7 1383.2 1131.7 1381.5 1117.8 1383.2 1104.7 1387.5 1093.4 1394.5 1083 1404.1 1076 1416.3 1071.6 1429.3 1069.9 1443.2 1071.6 1456.3 1076 1469.3 1083 1481.5 1093.4 1491.1 1104.7 1498.1 1117.8 1503.3 1131.7 1504.2 1144.7 1503.3 1157.8 1498.1 1170 1491.1 1179.5 1481.5 1187.4 1469.3 1191.7 1456.3"
    />
    <polygon
      className="cls-1"
      points="105.72 1441.5 104.86 1434.5 100.5 1429.3 95.28 1425 88.33 1424.1 81.36 1425 76.14 1429.3 72.67 1434.5 70.92 1441.5 72.67 1448.5 76.14 1453.7 81.36 1458 88.33 1458.9 95.28 1458 100.5 1453.7 104.86 1448.5"
    />
    <polygon
      className="cls-1"
      points="102.25 1441.5 99.64 1434.5 95.28 1430.2 88.33 1428.4 81.36 1430.2 77.02 1434.5 75.27 1441.5 77.02 1448.5 81.36 1452.8 88.33 1454.6 95.28 1452.8 99.64 1448.5"
    />
    <polygon
      className="cls-1"
      points="97.9 1441.5 96.15 1437.1 92.68 1433.7 88.33 1431.9 83.97 1433.7 80.49 1437.1 79.62 1441.5 80.49 1445.8 83.97 1449.3 88.33 1451.1 92.68 1449.3 96.15 1445.8"
    />
    <polygon
      className="cls-1"
      points="163.16 1445 161.42 1438 157.94 1432.8 152.72 1428.4 145.75 1427.6 138.79 1428.4 133.57 1432.8 129.22 1438 128.35 1445 129.22 1451.9 133.57 1457.2 138.79 1460.6 145.75 1462.4 152.72 1460.6 157.94 1457.2 161.42 1451.9"
    />
    <polygon
      className="cls-1"
      points="167.51 1445 165.76 1436.3 160.54 1429.3 153.59 1425 145.75 1423.2 137.06 1425 130.09 1429.3 125.75 1436.3 124 1445 125.75 1452.8 130.09 1459.8 137.06 1465 145.75 1466.7 153.59 1465 160.54 1459.8 165.76 1452.8"
    />
    <polygon
      className="cls-1"
      points="158.81 1445 157.07 1438 151.85 1433.7 145.75 1431.9 138.79 1433.7 134.44 1438 131.84 1445 134.44 1451.9 138.79 1456.3 145.75 1458 151.85 1456.3 157.07 1451.9"
    />
    <path
      className="cls-1"
      transform="translate(-134.1 -135.11)"
      d="M309.44,1554v96.59m-112.25,0V1554"
    />
    <polyline
      className="cls-1"
      points="178.82 1518.9 178.82 1416.3 55.26 1416.3 178.82 1416.3"
    />
    <path
      className="cls-1"
      transform="translate(-134.1 -135.11)"
      d="m189.36 1654h123.57m-3.49-100.05h-112.25"
    />
    <line className="cls-1" x1="55.26" x2="55.26" y1="1464.1" y2="1470.2" />
    <line className="cls-1" x1="56.12" x2="57.87" y1="1467.6" y2="1474.6" />
    <polygon
      className="cls-1"
      points="118.78 1487.6 117.04 1478 110.94 1470.2 103.12 1465 93.54 1463.3 84.84 1465 76.14 1470.2 70.92 1478 69.18 1487.6 70.92 1497.2 76.14 1505 84.84 1510.2 93.54 1512 103.12 1510.2 110.94 1505 117.04 1497.2"
    />
    <polygon
      className="cls-1"
      points="114.43 1487.6 112.69 1479.8 108.34 1472.8 101.37 1468.5 93.54 1466.7 85.71 1468.5 79.62 1472.8 75.27 1479.8 73.53 1487.6 75.27 1495.4 79.62 1502.4 85.71 1506.8 93.54 1508.5 101.37 1506.8 108.34 1502.4 112.69 1495.4"
    />
    <polygon
      className="cls-1"
      points="110.08 1487.6 109.21 1481.5 105.72 1476.3 100.5 1472.8 93.54 1471.1 87.46 1472.8 82.24 1476.3 78.75 1481.5 77.02 1487.6 78.75 1493.7 82.24 1498.9 87.46 1502.4 93.54 1504.2 100.5 1502.4 105.72 1498.9 109.21 1493.7"
    />
    <polygon
      className="cls-1"
      points="159.68 1491.1 157.94 1484.1 153.59 1478.9 146.63 1477.2 139.66 1478.9 135.31 1484.1 133.57 1491.1 135.31 1497.2 139.66 1502.4 146.63 1504.2 153.59 1502.4 157.94 1497.2"
    />
    <path
      className="cls-1"
      transform="translate(-134.1 -135.11)"
      d="M309.44,1650.57H197.19m115.74,3.48H189.36"
    />
    <polygon
      className="cls-1"
      points="164.03 1491.1 163.16 1484.1 158.81 1478 153.59 1474.6 146.63 1472.8 139.66 1474.6 134.44 1478 130.09 1484.1 129.22 1491.1 130.09 1497.2 134.44 1503.3 139.66 1506.8 146.63 1508.5 153.59 1506.8 158.81 1503.3 163.16 1497.2"
    />
    <polygon
      className="cls-1"
      points="156.2 1491.1 154.46 1485.9 150.98 1482.4 146.63 1481.5 142.28 1482.4 138.79 1485.9 137.06 1491.1 138.79 1495.4 142.28 1498.9 146.63 1499.8 150.98 1498.9 154.46 1495.4"
    />
    <line className="cls-1" x1="58.74" x2="55.26" y1="1455.4" y2="1451.9" />
    <path
      className="cls-1"
      transform="translate(-134.1 -135.11)"
      d="M463.46,1965.58v-86.15m-7.82,81.79h-58.3m58.3-3.47h-58.3m58.3-4.35h-58.3m58.3-4.36h-58.3m58.3-3.48h-58.3m58.3-4.35h-58.3m58.3-4.36h-58.3m58.3-4.34h-58.3m58.3-24.36h-58.3m58.3-4.35h-58.3m58.3-3.49h-58.3m58.3-4.35h-58.3m58.3-4.35h-58.3m58.3-7.83h-58.3m58.3,3.48h-58.3m58.3,41.77h-58.3m58.3-4.35h-58.3m58.3-4.35h-58.3m58.3-4.35h-58.3m58.3-3.49h-58.3m60-39.15H319m0,98.32H457.38"
    />
    <polyline
      className="cls-1"
      points="321.53 1760.8 323.28 1760 323.28 1757.4 321.53 1756.5"
    />
    <polyline
      className="cls-1"
      points="329.36 1744.3 327.63 1740 323.28 1738.2"
    />
    <polyline
      className="cls-1"
      points="321.53 1752.2 323.28 1752.2 323.28 1748.7 321.53 1748.7"
    />
    <polyline
      className="cls-1"
      points="321.53 1785.2 323.28 1785.2 323.28 1781.7 321.53 1780.9"
    />
    <polyline
      className="cls-1"
      points="321.53 1793.9 323.28 1793 323.28 1790.4 321.53 1789.6"
    />
    <polyline
      className="cls-1"
      points="321.53 1768.7 323.28 1768.7 323.28 1765.2 321.53 1765.2"
    />
    <polyline
      className="cls-1"
      points="321.53 1777.4 323.28 1776.5 323.28 1773.9 321.53 1773"
    />
    <polyline
      className="cls-1"
      points="323.28 1836.6 327.63 1834.8 329.36 1830.5"
    />
    <polyline
      className="cls-1"
      points="321.53 1818.3 323.28 1817.4 323.28 1814.8 321.53 1813.9"
    />
    <polyline
      className="cls-1"
      points="321.53 1826.1 323.28 1826.1 323.28 1822.6 321.53 1822.6"
    />
    <polyline
      className="cls-1"
      points="321.53 1801.8 323.28 1800.9 323.28 1798.3 321.53 1797.4"
    />
    <polyline
      className="cls-1"
      points="321.53 1810.4 323.28 1809.6 323.28 1807 321.53 1806.1"
    />
    <polyline
      className="cls-1"
      points="184.91 1823.5 185.79 1826.1 189.26 1827.9"
    />
    <polyline
      className="cls-1"
      points="178.82 1830.5 180.57 1834.8 184.91 1836.6"
    />
    <polyline
      className="cls-1"
      points="184.91 1738.2 180.57 1740 178.82 1744.3"
    />
    <polyline
      className="cls-1"
      points="189.26 1746.9 185.79 1748.7 184.91 1751.3"
    />
    <path
      className="cls-1"
      transform="translate(-134.1 -135.11)"
      d="m388.63 1958.6v-72.23m-4.35-4.36h-60.91m0 80.94h60.91m-65.28-76.58v72.23m-6.09-79.19v86.15"
    />
    <polyline
      className="cls-1"
      points="263.24 1756.5 261.49 1757.4 260.62 1758.2 261.49 1760 263.24 1760.8"
    />
    <polyline
      className="cls-1"
      points="254.53 1751.3 253.65 1748.7 250.18 1746.9"
    />
    <polyline
      className="cls-1"
      points="263.24 1748.7 261.49 1748.7 260.62 1750.4 261.49 1752.2 263.24 1752.2"
    />
    <polyline
      className="cls-1"
      points="263.24 1780.9 261.49 1781.7 260.62 1783.5 261.49 1785.2 263.24 1785.2"
    />
    <polyline
      className="cls-1"
      points="263.24 1789.6 261.49 1790.4 260.62 1791.3 261.49 1793 263.24 1793.9"
    />
    <polyline
      className="cls-1"
      points="263.24 1765.2 261.49 1765.2 260.62 1767 261.49 1768.7 263.24 1768.7"
    />
    <polyline
      className="cls-1"
      points="263.24 1773 261.49 1773.9 260.62 1774.8 261.49 1776.5 263.24 1777.4"
    />
    <polyline
      className="cls-1"
      points="250.18 1827.9 253.65 1826.1 254.53 1823.5"
    />
    <polyline
      className="cls-1"
      points="263.24 1813.9 261.49 1814.8 260.62 1816.6 261.49 1817.4 263.24 1818.3"
    />
    <polyline
      className="cls-1"
      points="263.24 1822.6 261.49 1822.6 260.62 1824.4 261.49 1826.1 263.24 1826.1"
    />
    <polyline
      className="cls-1"
      points="263.24 1797.4 261.49 1798.3 260.62 1800 261.49 1800.9 263.24 1801.8"
    />
    <polyline
      className="cls-1"
      points="263.24 1806.1 261.49 1807 260.62 1807.8 261.49 1809.6 263.24 1810.4"
    />
    <line className="cls-1" x1="184.92" x2="184.92" y1="1751.3" y2="1823.5" />
    <polyline
      className="cls-1"
      points="189.26 1746.9 185.79 1748.7 184.91 1751.3"
    />
    <polyline
      className="cls-1"
      points="184.91 1823.5 185.79 1826.1 189.26 1827.9"
    />
    <polygon
      className="cls-1"
      points="242.34 1808.7 241.48 1804.4 238.87 1801.8 235.39 1800.9 231.9 1801.8 229.3 1804.4 228.42 1808.7 229.3 1812.2 231.9 1814.8 235.39 1815.7 238.87 1814.8 241.48 1812.2"
    />
    <polygon
      className="cls-1"
      points="239.74 1808.7 238.87 1805.2 235.39 1803.5 231.9 1805.2 231.04 1808.7 231.9 1811.3 235.39 1813.1 238.87 1811.3"
    />
    <polygon
      className="cls-1"
      points="247.56 1808.7 245.83 1801.8 241.48 1797.4 235.39 1795.7 229.3 1797.4 224.94 1801.8 223.2 1808.7 224.94 1814.8 229.3 1819.2 235.39 1820.9 241.48 1819.2 245.83 1814.8"
    />
    <line className="cls-1" x1="55.26" x2="178.82" y1="1380.6" y2="1380.6" />
    <line className="cls-1" x1="633.93" x2="633.93" y1="1647.7" y2="1854" />
    <path
      className="cls-2"
      transform="translate(-134.1 -135.11)"
      d="m977.75 2245.8v-205.35m9.57 205.35v-205.35"
    />
    <polyline
      className="cls-1"
      points="667.87 1905.3 667 1928.8 669.6 1952.3 674.82 1975.8 683.53 1998.4 693.97 2019.3 707.9 2039.3 723.55 2056.7 741.83 2072.4 761.85 2085.4 782.74 2095.9 805.35 2103.7 828.85 2108.9 853.22 2110.7"
    />
    <rect className="cls-2" x="853.22" y="1627.7" width="9.57" height="20.02" />
    <polyline
      className="cls-1"
      points="741.83 1448.5 743.57 1472 748.79 1495.4 756.62 1517.2 767.93 1538.1 781.86 1556.4 798.4 1572.9 817.53 1586.8 837.56 1598.1 860.17 1606.8 882.8 1611.2 906.3 1612.9"
    />
    <path
      className="cls-2"
      transform="translate(-134.1 -135.11)"
      d="m875.93 1583.6h164.47m-164.47 9.57h164.47"
    />
    <path
      className="cls-1"
      transform="translate(-134.1 -135.11)"
      d="M996.89,1775H1030m-33.07-4.34H1030"
    />
    <rect
      className="cls-2"
      x="895.85"
      y="1627.7"
      width="10.44"
      height="20.02"
    />
    <path
      className="cls-3"
      transform="translate(-134.1 -135.11)"
      d="M996.89,1782.84H1030m-33.07-20H1030"
    />
    <line className="cls-1" x1="1360.5" x2="1257.8" y1="465.99" y2="672.22" />
    <polyline
      className="cls-1"
      points="1257.8 465.99 1360.5 672.22 1257.8 672.22"
    />
    <polyline
      className="cls-1"
      points="1360.5 672.22 1360.5 465.99 1257.8 465.99 1257.8 672.22"
    />
    <rect x="3.92" y="1051.7" width="51.35" height="467.28" />
    <rect x="3.92" y="3.92" width="51.35" height="493.42" />
    <rect x="42.43" y="3.12" width="357.42" height="52.18" />
    <rect x="1014.5" y="3.92" width="397.38" height="52.21" />
    <rect x="906.3" y="1051.7" width="505.58" height="52.2" />
    <rect x="906.3" y="1350.1" width="505.58" height="24.37" />
    <rect x="1222.6" y="1857.7" width="184.91" height="45.25" />
    <rect x="1360.5" y="3.92" width="51.35" height="1901.4" />
    <rect x="906.3" y="1105.3" width="21.6" height="8.14" />
    <rect x="906.3" y="1291" width="24.37" height="147.93" />
    <rect x="906.3" y="1277.9" width="20.89" height="10.44" />
    <rect x="906.3" y="1438.9" width="20.89" height="9.57" />
    <rect x="906.3" y="1613.4" width="20.89" height="240.61" />
    <rect x="487.74" y="1623.4" width="20.89" height="267.36" />
    <line className="cls-4" x1="913.73" x2="981.61" y1="1905.3" y2="1905.3" />
    <line className="cls-4" x1="913.73" x2="981.61" y1="1854" y2="1854" />
    <rect x="863.76" y="1854" width="191.34" height="51.35" />
    <rect x="329.4" y="1854" width="327.13" height="51.35" />
    <rect x="853.22" y="1884.4" width="12.07" height="20.88" />
    <rect x="653.44" y="1884.4" width="14.43" height="20.88" />
    <rect x="490.55" y="1625.9" width="143.38" height="19.24" />
    <rect x="609.56" y="1612.9" width="20.94" height="8.71" />
    <rect x="634.95" y="1627.7" width="54.13" height="20.66" />
    <rect x="853.22" y="1627.7" width="54.13" height="20.66" />
    <rect x="486" y="1051.6" width="147.93" height="53.64" />
    <rect x="609.56" y="1085.8" width="24.37" height="353.1" />
    <rect x="610.23" y="1437.9" width="19.34" height="10.53" />
    <rect x="312.41" y="1888.9" width="15.26" height="15.26" />
    <rect x="3.92" y="1888.8" width="33.07" height="15.42" />
    <rect x="3.92" y="1872.2" width="16.7" height="16.7" />
    <rect x="3.92" y="1521.2" width="16.53" height="14.27" />
    <rect x="3.92" y="1036" width="16.53" height="16.53" />
    <rect x="3.92" y="867.17" width="16.53" height="16.53" />
    <rect x="3.92" y="499.54" width="16.53" height="14.29" />
    <rect x="399.85" y="3.92" width="16.53" height="16.53" />
    <rect x="599.12" y="3.92" width="16.53" height="16.53" />
    <rect x="798.4" y="3.92" width="16.53" height="33.11" />
    <rect x="999.41" y="20.53" width="15.1" height="16.5" />
    <rect x="1058.2" y="1888.8" width="13.49" height="16.53" />
    <rect x="1203" y="1888.8" width="16.53" height="16.53" />
    <circle className="cls-10" cx="726.6" cy="1828.7" r="36.26" />
    <path
      transform="translate(-134.1 -135.11)"
      d="M852.86,1958.07V1954a25.42,25.42,0,0,0,4-.38,5.57,5.57,0,0,0,2.93-1.59,6.16,6.16,0,0,0,1.4-3.4h5v30.42H860v-21Z"
    />
    <circle className="cls-10" cx="772.99" cy="1360.8" r="36.26" />
    <circle className="cls-10" cx="1150.5" cy="1683.1" r="36.26" />
    <path
      transform="translate(-134.1 -135.11)"
      d="m1288.4 1810.6a3 3 0 0 0-0.54-1.56 3.2 3.2 0 0 0-2.82-1.37c-1.88 0-3.21 1-4 3.16a19.55 19.55 0 0 0-0.89 5.14 6.38 6.38 0 0 1 2.49-1.86 9.11 9.11 0 0 1 9.65 2.06 9.5 9.5 0 0 1 2.5 6.79 11.41 11.41 0 0 1-2.45 7.25c-1.63 2.1-4.17 3.14-7.61 3.14a8.77 8.77 0 0 1-8.19-4.64 18.93 18.93 0 0 1-2-9.36 42.29 42.29 0 0 1 0.28-5.46 16.8 16.8 0 0 1 2-6.24 10.13 10.13 0 0 1 3.3-3.43 8.89 8.89 0 0 1 4.87-1.29 9.67 9.67 0 0 1 6.52 2.09 8 8 0 0 1 2.74 5.58zm-6.85 16.55a4.1 4.1 0 0 0 3.25 1.5 3.57 3.57 0 0 0 3-1.45 6 6 0 0 0 1.11-3.75 5.6 5.6 0 0 0-1.26-3.94 4 4 0 0 0-3.08-1.37 4.14 4.14 0 0 0-2.61 0.89 5.06 5.06 0 0 0-1.7 4.25 5.77 5.77 0 0 0 1.29 3.87z"
    />
    <path
      transform="translate(-134.1 -135.11)"
      d="m898.12 1505.2q1.27-3 6-6.42a43.33 43.33 0 0 0 5.32-4.21 6.25 6.25 0 0 0 1.86-4.35 4.8 4.8 0 0 0-1.06-3.19 4.34 4.34 0 0 0-6.75 0.75 10.18 10.18 0 0 0-0.67 3.72h-5.78a14.62 14.62 0 0 1 1.4-6.25q2.38-4.53 8.47-4.53a10.78 10.78 0 0 1 7.65 2.66 9.24 9.24 0 0 1 2.84 7.05 9.52 9.52 0 0 1-2 6 19.87 19.87 0 0 1-4.33 3.87l-2.38 1.69c-1.5 1.06-2.52 1.82-3.07 2.3a6.7 6.7 0 0 0-1.39 1.65h13.22v5.24h-20.72a14.22 14.22 0 0 1 1.39-5.98z"
    />
    <circle className="cls-10" cx="333.11" cy="1412.5" r="36.26" />
    <path
      transform="translate(-134.1 -135.11)"
      d="M462.71,1553a6.64,6.64,0,0,0,.56,2.94,3.91,3.91,0,0,0,3.85,2.14,4.26,4.26,0,0,0,3-1.17,4.4,4.4,0,0,0,1.27-3.38,3.8,3.8,0,0,0-2.36-3.89,12.08,12.08,0,0,0-4.23-.55v-4.15a10.88,10.88,0,0,0,3.94-.56,3.39,3.39,0,0,0,1.93-3.46,3.91,3.91,0,0,0-1-2.75,3.61,3.61,0,0,0-2.78-1.06,3.51,3.51,0,0,0-3,1.3,5.57,5.57,0,0,0-.92,3.49h-5.42a14.38,14.38,0,0,1,.75-4.18,9.51,9.51,0,0,1,2.24-3.2,7.86,7.86,0,0,1,2.71-1.59,11.88,11.88,0,0,1,3.85-.55,10.24,10.24,0,0,1,6.83,2.19,7.3,7.3,0,0,1,2.59,5.88,6.5,6.5,0,0,1-1.55,4.4,5.21,5.21,0,0,1-2,1.53,4.22,4.22,0,0,1,2.29,1.37,7.28,7.28,0,0,1,2.22,5.62,9.47,9.47,0,0,1-2.6,6.59q-2.6,2.85-7.68,2.85-6.27,0-8.72-4.1A12.14,12.14,0,0,1,457,1553Z"
    />
    <circle className="cls-10" cx="783.36" cy="513.83" r="36.26" />
    <path
      transform="translate(-134.1 -135.11)"
      d="M928.2,657.57h-3.46v6.58h-5.9v-6.58H906.72v-5.26L918,633.73h6.76v19.13h3.46Zm-9.36-4.71V639.67l-7.64,13.19Z"
    />
    <circle className="cls-10" cx="1066.2" cy="1197" r="36.26" />
    <path
      transform="translate(-134.1 -135.11)"
      d="M1195.8,1338.47a5.7,5.7,0,0,0,1.35,3,3.78,3.78,0,0,0,2.89,1,3.93,3.93,0,0,0,3.34-1.54,6.38,6.38,0,0,0,1.14-3.89,6.73,6.73,0,0,0-1.07-3.88,3.76,3.76,0,0,0-3.35-1.59,6.08,6.08,0,0,0-1.86.27,3.7,3.7,0,0,0-2.08,1.85l-5.3-.25,2.11-16.59h16.52v5h-12.26l-1.07,6.57a11.36,11.36,0,0,1,2.13-1.18,8.9,8.9,0,0,1,3.12-.48,9.31,9.31,0,0,1,6.49,2.51q2.77,2.51,2.77,7.29A11.43,11.43,0,0,1,1208,1344c-1.78,2.18-4.45,3.27-8,3.27a10.63,10.63,0,0,1-7-2.29,9,9,0,0,1-3.06-6.53Z"
    />
    <path
      d="m20.45 1051.7v-554.33l34.82 0.01-0.03-442.01 344.61-0.03-0.87001-33.94 399.42-0.83v16.5l16.53-1e-6 -0.03005-6.14h184.48l0.03 6.14 16.53 1e-6 0.87 18.27h343.72v996.36h-1356.6"
      fill="none"
      stroke="#000"
      strokeWidth="1px"
    />
    <path
      d="m510.36 1647.7 396.99 0.64-1.05 205.59h-395.94v-206.23"
      fill="none"
      stroke="#000"
      strokeWidth="1px"
    />
  </StyledSvg>
);

export default FirstFloor;
