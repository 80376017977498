import { css } from "styled-components";

export const pathStyles = css`
  fill: #7bb933;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  &.is-active {
    opacity: 0.4;
  }
`;
